import React, { useContext, useEffect, useState } from 'react';
import { NavigateFunction, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DinamicaType } from '~/interfaces';
import { CookiesContext } from './cookiesContext';

export type DinamicasContextType = {
	dinamicas: DinamicaType[], 
	setdinamicas: React.Dispatch<React.SetStateAction<DinamicaType[] | undefined>>,
	dinamicasFetched: boolean,
	navigate: NavigateFunction
	searchFolio: string
	clearSearchFolio: () => void
}

const DinamicasProvider: React.FC = () => {
	const [dinamicas, setdinamicas] = useState<DinamicaType[]>();
	const [dinamicasFetched, setdinamicasFetched] = useState<boolean>(false);
	const { headersApi } = useContext(CookiesContext);
	const [searchFolio, setSearchFolio] = useState('');
	const apiUrl = import.meta.env.VITE_API_URL;
	const navigate = useNavigate();

	const { search } = useLocation();
	useEffect(() => {
		const searchObj = new URLSearchParams(search);
		setSearchFolio(searchObj.get('folio') || '');
	}, []);

	useEffect(() => {
		const handleGetDinamicas = async () => {
			try {
				const dinamicasRespuesta = await fetch(`${apiUrl}/APPWEB/Dinamicas`, {
					method: 'GET',
					headers: headersApi,
				}).then(res => res.json());
				const dinamicas: DinamicaType[] | undefined = dinamicasRespuesta?.Result?.data;
				setdinamicas(dinamicas);
				setdinamicasFetched(true);
			} catch (error) {
				setdinamicasFetched(true);
			}
		};

		handleGetDinamicas();
	}, []);

	const clearSearchFolio = () => {
		setSearchFolio('');
	};

	return (
		<Outlet 
			context={{ 
				dinamicas, 
				setdinamicas, 
				dinamicasFetched, 
				navigate,
				searchFolio,
				clearSearchFolio
			}} 
		/>
	);
};

export default DinamicasProvider;