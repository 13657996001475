import React from 'react';
import { CarouselProps } from '~/interfaces';
import {
	Wrapper,
	CarouselContainer,
	CarouselSlot,
} from './styled';
import { getOrder } from './helpers';
// https://codesandbox.io/s/lrk6955l79?file=/src/index.js:1084-1367


const Carousel = (props : CarouselProps) => {
	return (
		<Wrapper>
			<CarouselContainer dir={props.state.dir} sliding={props.state.sliding}>
				{React.Children.map(props.children, (child, index) =>{
					return <CarouselSlot
						key={index + 1}
						order={getOrder({ index: index, pos: props.state.pos, numItems: props.numItems })}
					>
						{child}
					</CarouselSlot>;
				})}
			</CarouselContainer>
		</Wrapper>
	);
};


export default Carousel;
