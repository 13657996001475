import { useContext, useEffect, useState } from 'react';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';

const useOTP = (onSuccess?: () => void) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	const { showSuccess, showError } = useContext(AlertToastContext);
	const { headersApi, handleUserInfo, token, macAddress, userInfo } = useContext(CookiesContext);

	// Timer OTP
	const [progress, setProgress] = useState(userInfo?.nTiempoRestante ?? 0);
	useEffect(() => {
		if(progress > 0) {
			setTimeout(function() {
				setProgress(progress - 1);
			},1000);
		}
	}, [progress]);

	useEffect(() => {
		if (userInfo?.nEstatus === 8) {
			setProgress(userInfo?.nTiempoRestante ?? 15);
		}
	}, [userInfo?.nEstatus, userInfo?.nTiempoRestante]);

	const [reenviandoOTP, setReenviandoOTP] = useState(false);
	const handleReenviarOTP = async () => {
		setReenviandoOTP(true);

		if (token && macAddress) {
			fetch(`${apiUrl}/APPWEB/ObtenerOTPmovil`, {
				headers: headersApi,
				method: 'GET'
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.Result?.done) {
						showSuccess('Código reenviado exitosamente.');
					} else {
						showError(response.Result.error);
						handleUserInfo(token, macAddress, { nEstatus: parseInt(response.Result.nEstatus), nTiempoRestante: response.Result.tiempoRestante });
						setProgress(response.Result.tiempoRestante);
					}
				})
				.finally(() => {
					setReenviandoOTP(false);
				});
		}
	};

	const [validandoOTP, setValidandoOTP] = useState(false);
	const handleValidarOTP = (cOTP: string) => {
		setValidandoOTP(true);

		if (token && macAddress) {
			fetch(`${apiUrl}/APPWEB/ValidarOTPmovil`, {
				headers: headersApi,
				method: 'POST',
				body: JSON.stringify({
					cOTP
				}),
			})
				.then((res) => res.json())
				.then(async (response) => {
					if (response.Result?.done) {
						handleUserInfo(response.Result?.token, macAddress, { 
							nEstatus: response.Result?.nEstatus,
							cCorreo: response.Result?.cCorreo,
							cNumero_Celular: response.Result?.cNumero_Celular,
						});
	
						onSuccess?.();
					}
				})
				.finally(() => {
					setValidandoOTP(false);
				});
		}
	};

	return {
		handleValidarOTP,
		handleReenviarOTP,
		reenviandoOTP,
		validandoOTP,
		progress,
		setProgress
	};
};

export default useOTP;