import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { FechasDisponiblesTypes } from '~/interfaces';
import { DatePickerSteps } from './helpers';
import { findEarliestDate } from '~/utils';

export enum LIMITS {
	BEGIN = '0100-01',
	END = '9900-12'
}

const useDatePicker = (initialStepper: DatePickerSteps, dFechasAPartirDe?: string, dFechasDisponibles?: FechasDisponiblesTypes[]) => {
	const [stepper, setStepper] = useState<DatePickerSteps>(initialStepper);
	const [currentMonth, setCurrentMonth] = useState(moment().format('MM'));
	const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

	const isAfterLimit = (time: moment.Moment) => time.isBefore(LIMITS.END);
	const isBeforeLimit = (time: moment.Moment) => time.isAfter(LIMITS.BEGIN);

	useEffect(() => {
		if(dFechasAPartirDe){
			setCurrentMonth(moment(dFechasAPartirDe).format('MM'));
			setSelectedYear(moment(dFechasAPartirDe).format('YYYY'));
		}
		if(Array.isArray(dFechasDisponibles) && dFechasDisponibles.length !== 0){
			const fechaProxima = findEarliestDate(dFechasDisponibles.filter((fecha) => !!fecha.dFecha).map((fecha) => fecha.dFecha));
			fechaProxima && setCurrentMonth(moment(fechaProxima).format('MM'));
			fechaProxima && setSelectedYear(moment(fechaProxima).format('YYYY'));
		}
	}, [dFechasDisponibles]);

	const handleNextMonth = () => {
		const nextMonth = moment(`${selectedYear}-${currentMonth}`).add(1, 'month');

		isAfterLimit(nextMonth) && setCurrentMonth(nextMonth.format('MM'));

		if (currentMonth.padStart(2,'0') === '12') {
			handleNextYear();
		}
	};

	const handleBackMonth = () => {
		const prevMonth = moment(`${selectedYear}-${currentMonth}`).subtract(1, 'month');

		isBeforeLimit(prevMonth) && setCurrentMonth(prevMonth.format('MM'));

		if (currentMonth.padStart(2,'0') === '01') {
			handleBackYear();
		}
	};

	const handleClickMonth = (month: number) => {
		setCurrentMonth(moment().month(month).format('MM'));
		handleNextStepper();
	};



	const handleNextYear = (plus = 1) => {
		const nextYear = moment(`${selectedYear}-${currentMonth}`).add(plus, 'year');

		if (isBeforeLimit(nextYear) && isAfterLimit(nextYear)) {
			setSelectedYear(nextYear.format('YYYY'));
		} else if (!isAfterLimit(nextYear)) {
			setSelectedYear(moment(LIMITS.END).format('YYYY'));
		} else if (!isBeforeLimit(nextYear)) {
			setSelectedYear(moment(LIMITS.BEGIN).format('YYYY'));
		}
	};
	
	const handleBackYear = (minus = 1) => {
		const prevYear = moment(`${selectedYear}-${currentMonth}`).subtract(minus, 'year');

		if (isBeforeLimit(prevYear) && isAfterLimit(prevYear)) {
			setSelectedYear(prevYear.format('YYYY'));
		} else if (!isAfterLimit(prevYear)) {
			setSelectedYear(moment(LIMITS.END).format('YYYY'));
		} else if (!isBeforeLimit(prevYear)) {
			setSelectedYear(moment(LIMITS.BEGIN).format('YYYY'));
		}
	};

	const handleClickYear = (year: number) => {
		setSelectedYear(moment().year(year).format('YYYY'));
		handleNextStepper();
	};



	const handleBackDecade = () => {
		const year = parseInt(selectedYear);

		handleBackYear((year % 10) + 10);
	};

	const handleNextDecade = () => {
		const year = parseInt(selectedYear);

		handleNextYear(10 - (year % 10));
	};



	const handleNextStepper = () => {
		if (stepper === DatePickerSteps.DECADE) {
			setStepper(DatePickerSteps.YEARS);
		} else if (stepper === DatePickerSteps.YEARS) {
			setStepper(DatePickerSteps.MONTHS);
		}
	};

	const handleBackStepper = () => {
		if (stepper === DatePickerSteps.MONTHS) {
			setStepper(DatePickerSteps.YEARS);
		} else if (stepper === DatePickerSteps.YEARS) {
			const year = parseInt(selectedYear);
			const decadeBegin = year - (year % 10);

			setSelectedYear(moment().year(decadeBegin).format('YYYY'));
			setStepper(DatePickerSteps.DECADE);
		}
	};

	return {
		currentMonth,
		selectedYear,

		handleNextMonth,
		handleBackMonth,
		handleClickMonth,

		handleNextYear,
		handleBackYear,
		handleClickYear,

		handleBackDecade,
		handleNextDecade,

		handleNextStepper,
		handleBackStepper,
		stepper,
	};
};

export default useDatePicker;
