import { useState } from 'react';

const useCheck = (defaultValue?: boolean) => {
	const [check, setCheck] = useState(defaultValue || false);
	const handleCheck = (value: boolean) => {
		setCheck(value);
	};

	return {
		check,
		handleCheck,
		setCheck,
	};
};

export default useCheck;