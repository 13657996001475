import { useState, useEffect, useRef } from 'react';

const useCardMedia = (src: string, staticImg?: boolean) => {
	const [loadImg, setLoadImg] = useState<boolean | null>(true);
	const [errorImg, setErrorImg] = useState<boolean | null>(false);
	const imgRef = useRef<HTMLImageElement>(null);

	const handleloadImg = () => {
		setLoadImg(false);
	};
	const handleError = () => {
		setErrorImg(true);
		setLoadImg(null);
	}; 
	const handleloadError = () => {
		setErrorImg(false);
	};

	useEffect(() => {
		if(!staticImg){
			/* if(!loadImg){
				setLoadImg(true);
			} */
			setLoadImg(true);
			setLoadImg(false);

			setErrorImg(false);

		}
	}, [src]);

	// useEffect(() => {
	// 	if (imgRef) {
	// 		const height = imgRef.current?.height;
	// 		const width = imgRef.current?.width;

	// 	}
	// }, [imgRef]);

	return {
		loadImg,
		handleloadImg,
		setLoadImg,
		handleError,
		errorImg,
		handleloadError,
		imgRef
	};
};

export default useCardMedia;