import { useEffect, useContext } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import useValidUserRedirect from './useValidUserRedirect';
import { useOutletContext } from 'react-router-dom';
import { DinamicasContextType } from '~/context/dinamicasContext';

const useDinamicas = () => {
	const { handleShowBack } = useContext(GeneralLayoutContext);
	const { handleDoneInitialLoading } = useContext(CookiesContext);
	const { dinamicas, navigate, dinamicasFetched }: DinamicasContextType = useOutletContext();

	useEffect(() => {
		handleShowBack('/', 'Volver a la tienda', 'Dinámicas', true, true);
		handleDoneInitialLoading();
	}, []);
	useValidUserRedirect(undefined, true);

	useEffect(() => {
		if (dinamicasFetched && dinamicas) {
			dinamicas.length === 1 && navigate(`/dinamicas/${dinamicas[0].nDinamica}`);
		}
	}, [dinamicasFetched]);

	return {
		dinamicas,
		dinamicasFetched,
		navigate,
	};
};

export default useDinamicas;
