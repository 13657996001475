import React from 'react';
import { MainContainer } from '../../styled';
import { Button, Grid, Title, Typography } from '~/components';

interface props {
	handleRegresarTienda: VoidFunction
}

const AvisoSolicitudCreada: React.FC<props> = ({ handleRegresarTienda }) => {
	return (
		<MainContainer>
			<Title variant='h5' margin='0 0 16px 0'>Solicitud creada</Title>

			<Grid container margin='0 0 32px' spacing=''>
				<Typography variant='subtitle2regular'>
					Hemos generado una solicitud de asistencia, y te contactaremos a través de correo electrónico a la brevedad para guiarte en los siguientes pasos.
				</Typography>
				
				<br />
				
				<Typography variant='subtitle2regular'>
					Gracias por tu paciencia y comprensión.
				</Typography>
			</Grid>

			<Button onClick={handleRegresarTienda} variant='outlined'>Regresar a la tienda</Button>
		</MainContainer>
	);
};
export default AvisoSolicitudCreada;