import React, { Fragment } from 'react';
import Title from '../Title';
import Typography from '../Typography';
type Props = {
    subtitleLabel?: string,
    padding?: string,
    titleLabel: string,
    xs?: boolean,
};
const ListadoTitle : React.FC<Props> = ({ xs, subtitleLabel, titleLabel, padding } : Props) => {
	return (
		<Fragment>
			<Title padding={padding || (xs ? '20px 0 8px 0' : '17px 0 0 0')} variant={xs ? 'h8' : 'h5'}>
				{titleLabel}
			</Title>
			{
				subtitleLabel && (xs ? <Title color='grey' padding='0 0 26px 0' variant={xs ? 'h5-semiBold' : 'h5'}>
					{subtitleLabel}
				</Title> : <Typography color='grey' padding='0 0 8px 0' variant={'h5'}>
					{subtitleLabel}
				</Typography>)
			}
		</Fragment>
	);
};
export default (ListadoTitle);