import { useState, useRef, useEffect } from 'react';

const useElementOnScreen = () => {
	const [isVisible, setIsVisible] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerRef.current) {
			const threshold = containerRef.current.offsetHeight > window.innerHeight ? (window.innerHeight * 0.9) / containerRef.current.offsetHeight : 0.9;
	
			const observer = new IntersectionObserver(
				([entry]) => setIsVisible(entry.isIntersecting), {
					root: null,
					rootMargin: '0px',
					threshold,
				});
			
			if (containerRef.current) observer.observe(containerRef.current);
			return () => {
				if (containerRef.current) observer.unobserve(containerRef.current);
			};
		}
	}, []);

	return {
		containerRef,
		isVisible
	};
};

export default useElementOnScreen;