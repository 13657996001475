// responsive
import React, { useContext, useEffect } from 'react';
import { Grid, Tabs, Container, ModalSucursal, Loading } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useSucursales, useTitle } from '~/hooks';
import { ScrollContainer } from './styled';
import ExpansionPanel from './containers/expansionPanel';
import { getCdnUrl } from '~/utils';


const Sucursales: React.FC = () => {
	const { handleShowBack, xsCarrito } = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Sucursales', true);
	}, []);

	const { 
		activeTab,
		handleActiveTab,
		isShowing,
		activeSucursal,
		handleClose,
		handleClickPasteleria,
		handleClickRestaurante,
		tipoSucursal,
		dataSucursales,
	} = useSucursales();

	useTitle(activeTab?.cMunicipio ? 'Sucursales ' + activeTab?.cMunicipio : 'Sucursales');

	if(!dataSucursales){
		return <Loading />; 
	}

	return (
		<>
			<Container padding='20px 0 0 0'>
				<Grid container padding='0 16px 20px 16px'>
					<Tabs
						handleActiveTab={handleActiveTab}
						activeTab={activeTab?.cMunicipio}
						itemTabs={dataSucursales}
					></Tabs>
				</Grid>

				<ScrollContainer>
					{activeTab?.sucursales?.Restaurantes && 
					<ExpansionPanel handleClick={handleClickRestaurante} sucursal={activeTab?.sucursales?.Restaurantes} type='Restaurantes'></ExpansionPanel>}

					{activeTab?.sucursales?.Pastelerias && 
					<ExpansionPanel handleClick={handleClickPasteleria} sucursal={activeTab?.sucursales?.Pastelerias} type='Pastelerías'></ExpansionPanel>}
				</ScrollContainer>
			</Container>

			<ModalSucursal 
				cDireccionMaps={activeSucursal?.cDireccionMaps || ''} 
				xsCarrito={xsCarrito} 
				open={isShowing} 
				handleClose={handleClose} 
				nombreSucursal={activeSucursal?.cSucursal || ''} 
				direccion = {activeSucursal?.cDireccion || ''} 
				telefono={activeSucursal?.cTelefono || ''} 
				horario={`${activeSucursal?.cHoraInicio?.substring(0, 5) || ''} - ${activeSucursal?.cHorarioFin?.substring(0, 5) || ''}`} 
				imgRestaurante={activeSucursal?.cImagenUrl ? getCdnUrl(activeSucursal?.cImagenUrl) : ''}
				tipoSucursal={tipoSucursal}
			/>
		</>

	);
};
export default Sucursales;
