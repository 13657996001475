import React from 'react';

import { Button, CardMedia, Grid, Title, Typography } from '~/components';
import { MainContainer } from '../../styled';
import { SupportIcon } from '~/assets/icons';
import EscudoUsuarioIcon from '~/assets/icons/escudoUsuario.png';


interface Props {
	handleClickServicioCliente: VoidFunction
	enviandoSolicitud: boolean
}

const AvisoCambiarNumero : React.FC<Props> = ({
	handleClickServicioCliente,
	enviandoSolicitud,
}) => {
	return (
		<MainContainer>
			<Grid direction='row' container flexWrap='nowrap' spacing='16px' margin='0 0 24px 0' justifyContent='space-between'>
				<Grid container direction='column' spacing='6px'>
					<Title variant='h5'>Aviso</Title>

					<Typography variant='caption'>
						Por seguridad y para proteger tu cuenta, si has cambiado tu número de teléfono, ponte en contacto con servicio al cliente para iniciar el proceso de restablecimiento.<br /><br />Se te solicitará un comprobante de identidad para confirmar tu titularidad.
					</Typography>
				</Grid>

				<CardMedia staticImg maxwidth='76px' src={EscudoUsuarioIcon} />
			</Grid>

			<Grid margin='64px 0 0 0' container spacing='8px' direction='row' flexWrap='nowrap'>
				<Button onClick={handleClickServicioCliente} loading={enviandoSolicitud} fullWidth variant='contained-round' leftIcon={<SupportIcon color='white' />}>
					Servicio al cliente
				</Button>
			</Grid>
		</MainContainer>
	);
};

export default (AvisoCambiarNumero);