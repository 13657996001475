import styled from 'styled-components';

export interface ProductoContainerProps {
padding?: string;
}

export const RedencionContaier = styled.div<ProductoContainerProps>`
	border-bottom: ${props => `1px solid ${props.theme.colors.borderLightGrey}`};
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`;

export const RedencionTitle = styled.div`
	font-weight: ${props => props.theme.text.weight.bold};
	font-size: ${props => props.theme.text.size.subtitle1};
	font-family: ${props =>props.theme.text.font.primary};
	padding: 6px 0 6px 0;
	text-align: center;
	display: block
`;