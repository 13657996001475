import { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { InivelTiendaDetallesType, ISubCategorias } from '~/interfaces';

const useTiendaCategorias = () => {

	const {
		nivelesTiendaId,
		handleResetShowBack,
		handleNivel
	} = useContext(GeneralLayoutContext);
	
	const params = useParams();
	const nNivelTienda = params.nNivelTienda;
	const apiUrl: string = import.meta.env.VITE_API_URL;

	const [nivelTiendaDetalles, setNivelTiendaDetalles] = useState<InivelTiendaDetallesType | undefined>(undefined);
	const {
		headersApi,
		handleDoneInitialLoading
	} = useContext(CookiesContext);

	const [loading, setLoading] = useState(false);
	// Obtiene las categorías y los tipos de pedido 

	useEffect(() => {
		setLoading(true);

		if(nivelesTiendaId){
			const body = {
				nNivelTienda: nivelesTiendaId
			};
			fetch(`${apiUrl}/APPWEB/ObtenerNivelTiendaDetalles`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: headersApi
			}).then((res) => res.json()).then((responseCat) => {
	
				if (responseCat && responseCat.Result) {
					setNivelTiendaDetalles(responseCat.Result);
					setLoading(false);
					handleDoneInitialLoading();
				}
	
			}).catch((error) => {
				console.error(error, 'error');
			});
	
		}
		
	
	}, [headersApi.Authorization, nivelesTiendaId]);

	const navigate = useNavigate();
	
	const handleGotoCategorias  = (value: ISubCategorias) => {
		navigate(`/tienda/${nNivelTienda}/categoria/${value.nCategoria}`);
		
	};
	useEffect(() => {
		handleResetShowBack();
	}, []);
	return {
		nivelesTiendaId,
		handleNivel,
		nivelTiendaDetalles,
		loading,
		apiUrl,
		handleGotoCategorias,
	};
};

export default useTiendaCategorias;