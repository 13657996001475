import React, {useEffect} from 'react';
import { Grid, IconButton, SucursalCard, Title } from '~/components';
import { ChevronUpStyled, GridStyled } from '../../styled';
import { ExpandLessIcon } from '~/assets/icons';
import { ISucursal } from '~/interfaces';
import { useDialog } from '~/hooks';
import { filter, isEmpty } from 'lodash';
type Props = {
    sucursal: ISucursal[],
    type: string,
    handleClick: (item: ISucursal)=> void,
};
const ExpansionPanel : React.FC<Props> = ({ sucursal, type, handleClick } : Props) => {
	const sucursales = filter(sucursal, item => !isEmpty(item));
	const {
		open,
		handleOpen,
		handleClose
	} = useDialog();

	useEffect(() => {
		handleClose();
	}, [sucursal]);
    

	if(sucursales.length === 0) {
		return <></>;
	}
	return (
		<>
			<Grid
				textAlign="center"
				container
				justifyContent="center"
				flex
				margin="14px 0 14px 0"
			>
				<Title variant="h5">
					<Grid className='pointer' container item onClick={handleOpen}>
						{type}
						<Grid container item>
							<IconButton
								onClick={handleOpen}
								size='25px'
							>
								<ChevronUpStyled isShowing={!open}>
									<ExpandLessIcon />
								</ChevronUpStyled>
							</IconButton>
						</Grid>
					</Grid>
				</Title>
			</Grid>
	
			<GridStyled isShowing={open}>
				{
					sucursales?.map((item, i) => {
						return (
							<SucursalCard
								key={i}
								handleOpen={()=>handleClick(item)}
								name={item.cSucursal}
								phoneNumber={item.cTelefono}
							></SucursalCard>
						);
					})
				}
			</GridStyled>
		</>
	);
};
export default (ExpansionPanel);