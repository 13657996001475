import React from 'react';
import { IconCircleAlert } from '~/assets/icons';
import { DoneContainer } from '../Done/styled';
import Title from '../Title';
import Typography from '../Typography';
type Props = {
  title?: string,
  subtitle?: string,
};
const ErrorWarning : React.FC<Props> = ({ title, subtitle } : Props) => {
	return (
		<DoneContainer>
			<IconCircleAlert width='80px' height='80px' color="red" />

			<Title color='darkGrey' padding="15px 0 10px 0" variant='h5'>{title || 'Ha ocurrido un error.'}</Title>
			<Typography padding="0 0 25px 0" color='disabledFontGrey' variant='subtitle2'>{subtitle || 'Por favor, inténtenlo más tarde.'}</Typography>
		</DoneContainer>
	);
};
export default (ErrorWarning);