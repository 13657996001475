import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '~/assets/icons';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { RipplesButtons } from '..';
import Divider from '../Divider';
import Grid from '../Grid';
import Typography from '../Typography';
import { ActiveDivider, NivelesAppBarContainer, NivelTab, ScrollContainer } from './styled';

type Props = {
    activeTab?: number;
    handleActiveTab: (value: number) => void,
	xs: boolean
};
const NivelesAppBar : React.FC<Props> = ({ activeTab, handleActiveTab, xs } : Props) => {
	// Te lleva al menu principal
	const navigate = useNavigate();

	const handleGoToMain = () => {
		navigate('/');
	};
	const handleGoTo = (nNivelTienda: number) => {
		navigate(`/tienda/${nNivelTienda}`);
		handleActiveTab(nNivelTienda);
	};
	const {
		nivelesTienda
	} = useContext(GeneralLayoutContext);
    
	return (
		<NivelesAppBarContainer>
			<Grid item padding='0 0 0 1.5rem'>
				<RipplesButtons  onClick={handleGoToMain}>
					<Grid container alignItems='center' padding='4px 0 0 0'>
						<HomeIcon color='primary'/>
						{
							xs && <Grid item flex padding='0 0 0 5px'>
								<Typography className='noselect' pointer variant='subtitle1bold'>
								Regresar
								</Typography>
							</Grid>
						}
						
					</Grid>
				</RipplesButtons>
			</Grid>
			
			<Grid item flex container spacing='1rem'>
				<Grid width='1rem' item container justifyContent='flex-end'>
					<Divider vertical/>
				</Grid>
				<ScrollContainer flex item>

					<div className='container'>
						{
							nivelesTienda?.map((item, i)=> (
								<NivelTab key={i} active={activeTab === item.nNivelTienda} onClick={()=>handleGoTo(item.nNivelTienda)} >
									{item.cDescripcion}
									<ActiveDivider active={activeTab === item.nNivelTienda} />
								</NivelTab>
							))
						}
					</div>

				</ScrollContainer>

			</Grid>

		</NivelesAppBarContainer>
	);
};
export default (NivelesAppBar);