import Grid from '../Grid';
import Typography from '../Typography';
import { useTheme } from 'styled-components';

// New design system
type ColorPallete = 'gray' | 'blue' | 'teal' | 'green' | 'red' | 'purple' | 'pink' | 'orange';
type Variant = 'solid' | 'subtle' | 'outlined';

const getBackgroundColor = (color: ColorPallete, variant: Variant) => {
	switch (variant) {
	case 'solid':
		return `${color}_500`;
	case 'subtle':
		return `${color}_50`;
	case 'outlined':
		return 'white';
	}
};

const getColor = (color: ColorPallete, variant: Variant) => {
	switch (variant) {
	case 'solid':
		return 'white';
	case 'subtle':
		return `${color}_800`;
	case 'outlined':
		return `${color}_600`;
	}
};

const getBorderColor = (color: ColorPallete, variant: Variant) => {
	switch (variant) {
	case 'outlined':
		return `${color}_500`;
	default:
		return undefined;
	}
};

interface BadgeProps {
	icon?: JSX.Element;
	description: string;
	colorPalette?: ColorPallete;
	variant?: Variant;
}

export const Badge = ({ 
	icon, 
	description, 
	colorPalette = 'gray', 
	variant = 'solid', 
} : BadgeProps) => {
	const backgroundColor = getBackgroundColor(colorPalette, variant);
	const color = getColor(colorPalette, variant);
	const borderColor = getBorderColor(colorPalette, variant);

	const theme = useTheme();

	return (
		<Grid container bgcolor={backgroundColor} borderRadius='2px' border={borderColor ? `1px solid ${theme.colors[borderColor]}` : undefined} spacing='2px' padding='0px 4px'>
			{icon ? <Typography color={color}>
				{icon}
			</Typography> : <></>}

			<Typography lineHeight='16px' variant='captionBold' color={color}>
				{description}
			</Typography>
		</Grid>
	);
};