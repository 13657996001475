import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Loading } from '~/components';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useTitle } from '~/hooks';

type CatalogosType = {
	cDescripcion: string;
	cUrlCatalogo: string;
	nCatalogo: number;
};

const apiUrl = import.meta.env.VITE_API_URL;
const Catalogos: React.FC = () => {
	const { handleShowBack } = useContext(GeneralLayoutContext);
	const { handleDoneInitialLoading, headersApi } = useContext(CookiesContext);
	const [catalogo, setCatalogo] = useState<CatalogosType | null>(null);
	const { id } = useParams();

	useEffect(() => {
		handleDoneInitialLoading();

		fetch(`${apiUrl}/APPWEB/ObtenerCatalogo/${id}`, {
			method: 'GET',
			headers: headersApi,
		})
			.then((res) => res.json())
			.then((response) => {
				if (response?.Result?.catalogo?.cDescripcion && response?.Result?.catalogo?.cUrlCatalogo) {
					handleShowBack('/dinamicas', 'Volver a la tienda', response.Result.catalogo.cDescripcion, true, true);
					setCatalogo(response.Result.catalogo);
				}
			})
			.catch((error) => {
				console.error(error, 'error');
			});
	}, []);

	useTitle(catalogo?.cDescripcion);

	return catalogo ? (
		<Container padding="2rem 2.5% 0 2.5%" margin='0'>
			<iframe
				style={{ width: '100%', minHeight: '80vh', border: 0, borderRadius: '5px' }}
				src={'https://docs.google.com/viewer?url='+catalogo.cUrlCatalogo+'&embedded=true'}
				allowFullScreen
			/>
		</Container>
	) : (
		<Loading />
	);
};

export default Catalogos;
