import React from 'react';
import { RipplesButtons } from '..';
import { MenuItemContainer } from './styled';

type Props = {
	children: JSX.Element | JSX.Element[],
	onClick?: () => void,
};

const MenuItem : React.FC<Props> = ({ onClick, children } : Props) => {
	return (
		<RipplesButtons onClick={onClick}>
			<MenuItemContainer>
				{children}
			</MenuItemContainer>
		</RipplesButtons>
	);
};
export default (MenuItem);