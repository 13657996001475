import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReciptIcon } from '~/assets/icons';
import { Container, Grid, Loading, LoadingRing, PedidosCard, Typography } from '~/components';
import { useHistorialOrdenes, useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const HistorialDePedidos : React.FC = () => {
	const {
		ordenesHistorial,
		handleOrdenHistorial,
		handleFetchData,
		nTotal
	} = useHistorialOrdenes();

	useTitle(PAGINAS.PEDIDOS);

	if(!ordenesHistorial){
		return <Loading />;
	}

	return (
		<Container maxWidth="343px" padding='31px 0 32px 0' center>
			<div className='responsiveContainer'>
				{
					ordenesHistorial.length === 0 ? <Grid container direction='column' textAlign='center'>
						<Typography>¡Ups! Aún no has realizado <br></br> ningún pedido</Typography>
						<Grid height='119px' container justifyContent='center' alignItems='center' item margin='48px 0 48px 0'>
							<ReciptIcon color='primary' style={{transform:'scale(5)'}} />
						</Grid>
						<Typography variant='subtitle1bold'>
						Aquí veras los detalles <br></br> de tus compras
						</Typography>
					</Grid> : <>
						<InfiniteScroll
							dataLength={nTotal} //This is important field to render the next data
							next={()=>handleFetchData(true)}
							hasMore={ordenesHistorial.length < nTotal}
							loader={<Grid container justifyContent='center'>
								<LoadingRing color='greyCard' />
							</Grid>}
						>
							{
								ordenesHistorial.map((item, i) => {
									return (
										<PedidosCard
											onClick={() => handleOrdenHistorial(item.nFolio)}
											item={item} key={i}
										></PedidosCard>
									);
								})
							}
						</InfiniteScroll>
						
					</>
				}
			</div>
		</Container>
	);
};
export default (HistorialDePedidos);