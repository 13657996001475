import React from 'react';
import { MercadoPagoFormBricks } from '~/components';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const MercadoPagoCheckout : React.FC = () => {

	useTitle(PAGINAS.TERMINAR_COMPRA);

	return (
		<>
			<MercadoPagoFormBricks />
		</>
	);
};
export default (MercadoPagoCheckout);