import React from 'react';
import { MainContainer } from '../../styled';
import { Button, Title, Typography } from '~/components';

interface props {
	handleNextStep: VoidFunction
}

const AvisoConfirmaNumero: React.FC<props> = ({
	handleNextStep
}) => {
	return (
		<MainContainer>
			<Title variant='h5' margin='0 0 24px 0'>¡Te damos la bienvenida!</Title>

			<Typography variant='subtitle2'>Confirma tu número de teléfono.</Typography>
			<Typography variant='subtitle2regular'>Para proteger tu cuenta, necesitamos validar tu número de teléfono. Este paso nos ayuda a garantizar la seguridad de tu información.</Typography>

			<Button margin='64px 0 0 0' onClick={handleNextStep} fullWidth variant='contained-round'>Entiendo</Button>
		</MainContainer>
	);
};

export default (AvisoConfirmaNumero);