import React, { Fragment, useContext } from 'react';
import { Container, DetalleProductoModal, Empty, ErrorWarning, ListadoProductos, ListadoTitle, Loading } from '~/components';
import { EMPTY_TITLES } from '~/constants/contents';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useBuscador, useDetalleProductoModal, useTitle } from '~/hooks';

const Buscador : React.FC = () => {
	const {
		nivelTiendaDetalles,
		loading,
		errorWarning,
		mainValue,
	} = useBuscador();

	const {
		xs,
	} = useContext(GeneralLayoutContext);

	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleLike,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	useTitle(`Resultados para: ${mainValue}`);

	return (
		<Container margin="15px 0 70px 0">
			{
				errorWarning ? <ErrorWarning /> : (loading || !nivelTiendaDetalles) ? <Loading></Loading> : <>

					{
						nivelTiendaDetalles?.dataProductosCategoria.length === 0 ? <Empty emptySubtitle={EMPTY_TITLES.subtitle3} buttonLabel='Ver más'/> : <>
							{
								nivelTiendaDetalles.dataProductosCategoria.map((seccionInteres, i) => (
									<Fragment key={i}>
										<ListadoTitle
											titleLabel={seccionInteres.cCategoria}
											subtitleLabel={seccionInteres.cNivelTienda}
											xs={xs}
										/>
										<ListadoProductos 
											key={i}
											handleLike={handleLike}
											handleClick={(id)=>handleClick(id, seccionInteres.nNivelTienda)}
											productos={seccionInteres.VProductos} 
											item_list={seccionInteres.cCategoria}
										/>
									</Fragment>
								))
							}
						</>
					}
			
					<DetalleProductoModal 
						openDetalleProducto={openDetalleProducto}
						productoId={productoId}
						handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
						handleProductoId={handleProductoId}
						handleClick={handleClick}
						handleDataCarrito={handleDataCarrito}
						handleModificaCarrito={handleModificaCarrito}
						carritoLoading={carritoLoading}
						nivelMainId={nivelMainId}
						handleNivelMainId={handleNivelMainId}
						handleOrdenar={handleOrdenar}
						detalleEnvio={detalleEnvio}
						loadingCheckoutCarrito={loadingCheckoutCarrito}
						itemList={'Resultado de Busqueda'}
					/>  
				</>
			}
		</Container>
	);
};
export default (Buscador);