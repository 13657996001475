import { useContext, useEffect, useState } from 'react';
import errors from '~/constants/errors';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { DataCiudadesTypes, SelectItemsTypes } from '~/interfaces';
import { errorNombresRegex, handleHelperValidRegex, handleValidPhone } from '~/utils/validations';

const useTrabajo = () => {

	const apiUrl = import.meta.env.VITE_API_URL;

	const {showError} = useContext(AlertToastContext);

	const { handleShowBack } = useContext(GeneralLayoutContext);

	const {
		headersApi,
		isUserActive
	} = useContext(CookiesContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Trabajo', true, true);
	}, []);

	// nombres
	const [nombres, setNombres] = useState('');
	const handleNombres = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setNombres(e.target.value);
	};
	const nombresCheck = handleHelperValidRegex(nombres, errorNombresRegex, errors.nombresError);

	// edad
	const [edad, setEdad] = useState('');
	const handleEdad = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setEdad(e.target.value);
	};
	const checkEdad = parseInt(edad) < 100 && parseInt(edad) > 14;
	const edadCheck = {
		check: edad !== '' && checkEdad,
		error: edad !== '' && !checkEdad,
		helperText: edad !== '' && !checkEdad ? 'Edad inválida' : ''
	};

	// numeroCelular
	const [numeroCelular, setNumeroCelular] = useState('');
	const handleNumeroCelular = (e: any) => {
		setNumeroCelular(e);
	};

	const numeroCelularCheck = handleValidPhone(numeroCelular);

	// genero
	const [genero, setGenero] = useState<SelectItemsTypes>({
		nValor: 0,
		cValor: 'Selecciona tu género'
	});
	const handleGenero = (e: SelectItemsTypes) => {
		setGenero(e);
	};

	const generoCheck = {
		check: genero.nValor !== 0,
	};
	
	const [dataGeneros, setDataGeneros] = useState<SelectItemsTypes[]>([]);
	useEffect(() => {
		// para obtener el catálogo de géneros
		if (dataGeneros.length === 0) {
    
			fetch(`${apiUrl}/APPWEB/ObtenerCATGeneros`, {
				method: 'GET',
			}).then((res) => res.json()).then((response) => {
    
				if (response?.Result?.[0]) {
					setDataGeneros(response.Result[0]);
				}
    
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, []);

    
	const [dataCiudades, setDataCiudades] = useState<SelectItemsTypes[] | undefined>(undefined);

	const [done, setDone] = useState(false);

	const [ciudad, setCiudad] = useState<null | SelectItemsTypes>(null);
	const ciudadCheck = {
		check: !!(ciudad?.nValor),
	};
	const handleCiudad = (value: SelectItemsTypes) => {
		setCiudad(value);
	};

	const [loading, setLoading] = useState(false);

	// Llena el combo de municipios
	useEffect(() => {
		if((!dataCiudades || dataCiudades?.length === 0) && isUserActive){
			fetch(`${apiUrl}/APPWEB/ConsultaMunicipios?nEstado=${1}`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
	
				if (response?.Result?.[0]) {
					const helper = response.Result[0];
					const helperData = helper.map((item: DataCiudadesTypes) => ({
						nValor: item.nMunicipio,
						cValor: item.cMunicipio
					}));
					setDataCiudades(helperData);
					if(helperData[0]){
						setCiudad(helperData[0]);
					}
				}
	
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [isUserActive]);

	const handleEnviar = () => {
		setLoading(true);
		const body = {
			cNombre: nombres,
			nEdad: edad,
			cNumeroCelular: numeroCelular,
			nGenero: genero.nValor,
			nCiudad: ciudad?.nValor
		};
		fetch(`${apiUrl}/APPWEB/RegistroTrabajo`, {
			body: JSON.stringify(body),
			method: 'POST',
			headers: headersApi
		}).then((res) => res.json()).then((res) => {

			if (res?.Result?.done) {
				setLoading(false);
				setDone(true);
			} else {
				showError(errors.errorForm);
				setLoading(false);

			}

		}).catch((error) => {
			console.error(error, 'error');
		});

	};

	const disabledEnviar = nombres === '' || edad === '' || numeroCelular === '' || nombresCheck.error || !generoCheck.check || genero.nValor === 0 || !ciudad?.nValor;

	return {
		nombres,
		handleNombres,
		nombresCheck,
		edad,
		setEdad,
		handleEdad,
		numeroCelular,
		handleNumeroCelular,
		numeroCelularCheck,
		edadCheck,
		ciudadCheck,
		genero,
		dataGeneros,
		handleGenero,
		dataCiudades,
		disabledEnviar,
		ciudad,
		handleCiudad,
		handleEnviar,
		loading,
		generoCheck,
		done,
	};
};

export default useTrabajo;