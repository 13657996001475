import React from 'react';
import WarningModal from '../WarningModal';
import Typography from '../Typography';
import Grid from '../Grid';
type Props = {
    handleClickItem: () => void, open: boolean,
	handleClose: () => void,
	nDiasAnticipacion?: number,
	cDiasDisponibles?: string,
	descripcion?: JSX.Element | JSX.Element[] | string,
};
const WarningSobrePedido : React.FC<Props> = ({ 
	handleClickItem, 
	open,
	handleClose, 
	nDiasAnticipacion, 
	cDiasDisponibles, 
	descripcion 
} : Props) => {
	return (
		<WarningModal 
			width='341px'
			height={descripcion ?  undefined : '310px'}
			cancelLabel={descripcion ? 'Cancelar' : 'Mmm...Mejor no'}
			aceptLabel={descripcion ? 'Aceptar' : 'Sí, entiendo'}
			warningLabel={
				<>¡Ojo! 👀</>
			}
			handleAcept={() => {
				handleClose();
				handleClickItem();
			}}
			open={open} 
			handleClose={handleClose}
		>
			<Grid padding='20px 15px 0 15px' justifyContent='center' container textAlign='center'>
				<Typography variant='subtitle1regular'>
					{descripcion || (
						<>
							Este producto es sobre pedido, <br />{
								cDiasDisponibles 
									? `está disponible para las siguientes fechas ${cDiasDisponibles}${nDiasAnticipacion ? ` y tarda ${nDiasAnticipacion} en estar listo.` : ''}` 
									: `tarda ${nDiasAnticipacion} días en estar listo.`
							}
						</>
					)}
				</Typography>
			</Grid>
		</WarningModal>
	);
};
export default (WarningSobrePedido);