import { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import errors from '~/constants/errors';
import { PATHS } from '~/constants/paths';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';

const useConfirmaCorreo = () => {
	const apiUrl = import.meta.env.VITE_API_URL;

	const { headersApi, handleUserInfo, token, macAddress } = useContext(CookiesContext);
	const { showError, showSuccess } = useContext(AlertToastContext);
	
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const handleValidateEmail = () => {
		const cToken = searchParams.get('cToken');

		fetch(`${apiUrl}/APPWEB/VerificarCorreoAccept`,{
			headers: headersApi,
			method: 'POST',
			body: JSON.stringify({
				cToken
			})
		}).then((res) => res.json()).then((response) => {
			if (response?.Result?.done) {
				token && macAddress && handleUserInfo(token, macAddress, { bCorreoConfirmado: true });
				
				showSuccess('Correo verificado correctamente');
			} else {
				showError(errors.errorForm);
			}
		}).catch(() => {
			showError(errors.errorForm);
		}).finally(() => {
			navigate(PATHS.main);
		});
	};

	return {
		handleValidateEmail,
	};
};

export default useConfirmaCorreo;