import React from 'react';
import { Call } from '~/assets/icons';
import { GridStyled } from './styled';
import { Grid, Typography, Title, ChevronRightIconButton } from '~/components';
import { formatPhoneNumber } from '~/utils';

type SucursalCardProps = {
	name: string,
	phoneNumber: string,
	handleOpen: VoidFunction
};



const SucursalCard: React.FC<SucursalCardProps> = ({ name, phoneNumber, handleOpen }: SucursalCardProps) => {
	

	return (
		<Grid container className='noselect' justifyContent='center' margin='0 0 8px 0'>
			<GridStyled onClick={handleOpen} container item direction='column' width='463px' padding='16px'>
				<Grid container item alignItems='center' justifyContent='space-between'>
					<Title variant='subtitle1'>
						<strong>{name}</strong>
					</Title>
					<ChevronRightIconButton onClick={handleOpen} />
				</Grid>
				{
					phoneNumber && <Grid spacing='8px' container item flex alignItems='center' >
						<Call color='primary' />
						<a onClick={(e)=>e.stopPropagation()} href={`tel:${phoneNumber}`}>
							<Typography textDecoration='underline' variant='body2' color='primary'>
								{formatPhoneNumber(phoneNumber)}
							</Typography>
						</a>
					
					</Grid>

				}

			</GridStyled>
		</Grid>
	);
};

export default (SucursalCard);