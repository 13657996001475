import React, { Fragment } from 'react';
import { IconPlus } from '~/assets/icons';
import { DireccionCard, Grid, IconButton, Typography } from '~/components';
import { DirectionCardContainer } from '~/components/DireccionCard/styled';
import { DireccionesTypes, VoidFunctionType } from '~/interfaces';

type Props = {
    dataDirecciones: DireccionesTypes[],
    handleDomicilioId: (e: DireccionesTypes) => void,
    domicilioSelected: DireccionesTypes | null,
    handleNext: VoidFunctionType,
    hadleOpenDeleteDireccion?: (nDireccion: number) => void,
	handleOpenEditarDireccion?: (item: DireccionesTypes) => void,
	xs?: boolean
};

const ListadoDirecciones : React.FC<Props> = ({ 
	dataDirecciones,
	handleDomicilioId,
	domicilioSelected,
	handleNext,
	hadleOpenDeleteDireccion,
	handleOpenEditarDireccion,
	xs
} : Props) => {
	return (
		<Grid container direction='column' maxwidth='342px' width='100%' padding={xs ? '20px 5px 30px 5px' : '20px 0 30px 0'}>
			{
				dataDirecciones.length < 15 && <DirectionCardContainer onClick={handleNext}>
					<Grid padding='0 10px 0 0'>
						<Typography variant='body1'>Añadir dirección</Typography>
					</Grid>
					<Grid item >
						<IconButton  onClick={handleNext}><IconPlus /></IconButton>
					</Grid>
				</DirectionCardContainer>
			}

			<Typography padding='6px 0 8px 0' variant='subtitle1bold' color='titleGrey'>Mis direcciones</Typography>
			
			{
				dataDirecciones.map((item, key) => (
					<Fragment key={key}>
						{/* 	{
							(item.bDireccionPredeterminada) && key === 0 && <Typography variant='subtitle1regular' color='titleDarkGrey' padding='0 0 7px 0'>
								Direccion de entrega predeterminada
							</Typography>
						} */}
						<DireccionCard
							handleOpenEditarDireccion={handleOpenEditarDireccion}
							predeterminada={item.bDireccionPredeterminada && key === 0}
							hadleOpenDeleteDireccion={hadleOpenDeleteDireccion}
							onClick={() => handleDomicilioId(item)}
							checked={domicilioSelected?.nDireccion === item.nDireccion}
							item={item} key={key}
						/>
					</Fragment>
				))
			}
		</Grid>
	);
};

export default (ListadoDirecciones);