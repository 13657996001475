import React, { useContext } from 'react';
import { IBannerData } from '~/interfaces';
import CarrouselImg from '../CarrouselImg';
import { getBanners, getCdnUrl } from '~/utils';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import CardMedia from '../CardMedia';
import { ImgContainer } from '../CarrouselImg/styled';
import Grid from '../Grid';


type Props = {
	bannersData: IBannerData[]
};
const Banners : React.FC<Props> = ({ bannersData } : Props) => {
	const {
		xs,
	} = useContext(GeneralLayoutContext);

	if (bannersData?.length === 0) {
		return <></>;
	}
	
	const handleClick = (cUrlClick: string, bUrlClickPestañaNueva: number) => {
		if(!cUrlClick) {
			return;
		}

		if(bUrlClickPestañaNueva) {
			window.open(cUrlClick);
		} else {
			window.location.href = cUrlClick;
		}
	};

	return (
		<>
			{
				bannersData?.length === 1 || (bannersData?.length < 3 && xs) ? <Grid container justifyContent='center'>
					<Grid item>
						<ImgContainer>
							{
								bannersData?.map((imagen, i) => (
									<CardMedia 
										key={i} 
										height='100%' 
										minHeight='200px' 
										pointer={!!(imagen.cUrlClick)} 
										src={getCdnUrl(imagen.cImagenUrl)} 
										alt={imagen?.cDescripcion && 'Banner de ' + imagen.cDescripcion} 
										onClick={() => handleClick(imagen.cUrlClick, imagen.bUrlClickPestañaNueva)} 
									/>
								))
							}
						</ImgContainer>
					</Grid>
				</Grid> : (
					<CarrouselImg
						handleClick={handleClick}
						images={getBanners(bannersData, xs)}
						maxwidth="100%"
					/>
				)
			} 
			
			{/* <Grid padding={padding} container spacing='15px'>
				{
					bannersData.map((item, i) => (
						<BannerContainer key={i} item flex>
							<CardMedia height='100%' src={item.cImagenUrl} />
						</BannerContainer>
					))
				}
		
			</Grid> */}
		</>
	);
};
export default (Banners);