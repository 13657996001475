import React from 'react';
import { Grid, Typography } from '~/components';
import { RedencionContaier } from './styled';

type Props = {
    item: { cSucursal_Descripcion: string, cIDTicket: string },
	cDescripcion: string
};

const RedencionTicket : React.FC<Props> = ({ item, cDescripcion } : Props) => {
	return (
		<RedencionContaier>
			<Typography className='noselect' style={{margin: '0.5rem 0'}} pointer variant='subtitle2'>
				{`${cDescripcion}`}
			</Typography>
			<Typography variant='regular'>
				{item.cSucursal_Descripcion}
			</Typography>
			<Grid justifyContent='flex-end' alignItems='center' container padding='5px 0 10px 0'>
				<Typography variant='body3' >
					{item.cIDTicket}
				</Typography>
			</Grid>
		</RedencionContaier>
	);
};

export default RedencionTicket;