import { useContext, useState } from 'react';
import errors from '~/constants/errors';
import { AlertToastContext } from '~/context/alertToastContext';
import { CheckProps } from '~/views/auth/containers/AuthForm';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { errorPasswordRegex, handlePasswordConfirmValidRegex, handleValidRegex } from '~/utils/validations';
import { CookiesContext } from '~/context/cookiesContext';

const useRestaurarContrasenia = (onFinish: (newToken: string) => void) => {
	const [searchParams] = useSearchParams();
	const cToken = searchParams.get('cToken');
	const { macAddress, userInfo } = useContext(CookiesContext);
	const apiUrl = import.meta.env.VITE_API_URL;

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [errorTextField, setErrorTextField] = useState<CheckProps | null>(null);
	const { showError } = useContext(AlertToastContext);

	// password
	const [newPassword, setNewPassword] = useState('');
	const handlenewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewPassword(e.target.value);
	};

	// passwordConfirm
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const handlePasswordConfirm = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setPasswordConfirm(e.target.value);
	};

	const passwordCheck = handleValidRegex(newPassword, errorPasswordRegex, errors.passwordHelper);
	const passwordConfirmCheck = handlePasswordConfirmValidRegex(newPassword, passwordConfirm);

	const disabled = newPassword === '' || passwordCheck.error || passwordConfirmCheck.disabled || loading;
	const params = useParams();
	const token = params.token;

	const handleRestablecerContrasenia = () => {
		if(newPassword) {
			setLoading(true);
			if(macAddress){
				const body = {
					cContrasenia: newPassword,
					cToken: cToken || userInfo?.cToken,
				};
		
				fetch(`${apiUrl}/APPWEB/ModificaContra`, {
					body: JSON.stringify(body),
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
						'macAddress': macAddress,
					}
				}).then((res) => res.json()).then((responseContrasenia) => {
					if(responseContrasenia.Result?.done && !responseContrasenia.Result?.error){
						setLoading(false);
						onFinish(responseContrasenia.Result.newToken);
					} else {
						setErrorTextField({
							helperText:'',
							check: false,
							error: true
						});
						showError(responseContrasenia?.Result?.error ? `${responseContrasenia?.Result?.error} ${responseContrasenia?.Result?.error_sub ? `- ${responseContrasenia?.Result?.error_sub}` || '' : ''}` : '');
						setLoading(false);
					}
				}).catch((error) => {
					console.error(error, 'error');
					showError(errors.errorChangePassword);
					setLoading(false);
				});
			}
		}
	};

	const handleGoToMain = () => {
		navigate('/');
	};

	return {
		loading,
		setLoading,
		handlenewPassword,
		newPassword,
		handleRestablecerContrasenia,
		disabled,
		errorTextField,
		handleGoToMain,
		passwordConfirm,
		handlePasswordConfirm,
		passwordCheck,
		passwordConfirmCheck,
	};
};

export default useRestaurarContrasenia;