import React from 'react';
import useWarningTimeOutModal from '~/hooks/useWarningTimeOutModal';
import { InivelesTienda } from '~/interfaces';
import { Button, Grid, Modal, Typography } from '..';

type Props = {
    nivelTienda: InivelesTienda,
};

const WarningTimeOutModal : React.FC<Props> = ({ nivelTienda } : Props) => {
	const {
		handleClose,
		open,
		horaFin, 
		handleGoBack,
		horaInicio
	} = useWarningTimeOutModal(nivelTienda);

	return (
		<Modal maxWidth='341px' open={open} handleClose={handleClose} close>
			<Grid container direction='column' alignItems='center' textAlign='center' padding='0 22px 0 22px'>
				<Typography padding='0 0 15px 0' variant='body3'>
                Los {nivelTienda?.cDescripcion?.toLowerCase()} están disponibles <br /> en un horario entre {horaInicio} y {horaFin}
				</Typography>
				<Typography variant='subtitle2'>
                Aun así, puedes programar una orden <br /> para otro momento.
				</Typography>
			</Grid>

			<Grid container direction='column' spacing='5px' padding='25px 15px 25px 15px'>
				<Grid item >
					<Button onClick={handleClose} fullWidth variant='contained-round' >
                    Sí, quiero programar una orden
					</Button>
				</Grid>
				<Grid item >
					<Button onClick={handleGoBack} fullWidth variant='outlined-round' >
						Volver al menú principal
					</Button>
				</Grid>     
			</Grid>
		</Modal>
	);
};
export default (WarningTimeOutModal);