import React from 'react';
import ChevronRightIconButton from '../ChevronRightIconButton';
import Grid from '../Grid';
import Typography from '../Typography';
import { MenuItemChevronContainer } from './styled';

type Props = {
	children: JSX.Element | JSX.Element[] | string,
	onClick?: VoidFunction,
	padding?: string,
	titleVariant?: string,
	disabled?: boolean
};

const MenuItemChevron : React.FC<Props> = ({ children, onClick, padding, titleVariant, disabled } : Props) => {
	return (
		<MenuItemChevronContainer padding={padding} onClick={onClick}>
			<Grid item flex>
				<Typography className='noselect' pointer variant={titleVariant || 'subtitle1bold'}>
					{children}
				</Typography>
			</Grid>
			<Grid>
				{
					!disabled && <ChevronRightIconButton onClick={onClick} />
				}
				
			</Grid>
		</MenuItemChevronContainer>
	);
};
export default (MenuItemChevron);
