import React from 'react';
import Grid from '../Grid';
import Typography from '../Typography';
import { ItemTab } from './styled';
import { IDataSucursales } from '~/interfaces';

type Props = {
    itemTabs: IDataSucursales[],
	handleActiveTab: (value: IDataSucursales) => void,
	activeTab?: string
};
const Tabs : React.FC<Props> = ({itemTabs, activeTab, handleActiveTab} : Props) => {
	return (
		<Grid item container flex justifyContent='center' margin='10px 0 0 0'>
			<Grid container item flex maxwidth='756px'>
				{
					itemTabs.map((item,i) => (
						<ItemTab onClick={() => handleActiveTab(item)} active={activeTab === item.cMunicipio} textAlign='center' key={i} borderbottom={true} item flex> 
							<Typography> {item.cMunicipio}</Typography>
						</ItemTab>
					))
				}
				
			</Grid>
		</Grid>
	);
};
export default (Tabs);

