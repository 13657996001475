import React from 'react';

import { Button, Grid, TokenAuth, Typography } from '~/components';
import { maskPhoneNumber } from '~/utils';
import { MainContainer } from '../../styled';

interface Props {
	numeroCelular: string,
	tiempoRestante?: number
	handlePreviousStep: VoidFunction
	handleReenviarOTP: () => void
	handleValidarOTP: (cOTP: string) => void
	handleClickCambiarNumero?: VoidFunction
	reenviandoOTP: boolean
	validandoOTP: boolean
}

const OTPCelular : React.FC<Props> = ({
	handlePreviousStep,
	numeroCelular,
	handleReenviarOTP,
	handleValidarOTP,
	handleClickCambiarNumero,
	reenviandoOTP,
	tiempoRestante = 0,
	validandoOTP
}) => {
	return (
		<MainContainer>
			<Typography variant={'subtitle3regular'} margin='0 0 24px 0'>Ingrese el código de 4 dígitos que enviamos al <strong>{maskPhoneNumber(numeroCelular)}</strong></Typography>

			{handleClickCambiarNumero && <Button onClick={handleClickCambiarNumero} fullWidth variant='underline-subtitle2' color='black'>
				¿Cambiaste el número de tu teléfono móvil?
			</Button>}

			<TokenAuth 
				onComplete={(value: string) => handleValidarOTP(value)}
				length={4}
				secret={true}
			/>

			<Typography variant='captionBold' margin='24px 0 0 0'>¿No recibiste el código?</Typography>
			<Typography variant='caption' margin='0'>
				Sí el código no llega en unos minutos, puedes solicitar un nuevo código.
			</Typography>

			<Grid container spacing='16px' direction='column' flexWrap='nowrap' margin='32px 0 0 0'>
				<Button loading={reenviandoOTP || validandoOTP} onClick={handleReenviarOTP} disabled={reenviandoOTP || tiempoRestante > 0 || validandoOTP} fullWidth variant='contained-round'>
					{`Reenviar código por WhatsApp ${tiempoRestante === 0 ? '' : `en (${tiempoRestante}s)`}`}
				</Button>

				<Button onClick={handlePreviousStep} fullWidth variant='outlined-round'>Cambiar Número</Button>
			</Grid>
		</MainContainer>
	);
};

export default (OTPCelular);