import React from 'react';
import { Button, CardMedia, Modal, Tag, Title, Typography } from '~/components';
import Grid from '~/components/Grid';
import { TIPO_SUCURSALES } from '~/interfaces';

type Props = {
    open: boolean,
    handleClose: () => void,
    nombreSucursal: string,
	direccion: string,
	horario: string,
	telefono: string,
	imgRestaurante: string,
	xsCarrito?: boolean,
	cDireccionMaps: string,
	tipoSucursal: TIPO_SUCURSALES
};

const ModalSucursal: React.FC<Props> = ({
	open,
	handleClose,
	nombreSucursal,
	direccion,
	cDireccionMaps,
	horario,
	telefono,
	imgRestaurante,
	xsCarrito,
	tipoSucursal
}: Props) => {
	const encodedAddress = encodeURIComponent(direccion);
	const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
	
	const handleComoLlegar = () => {
		window.open(cDireccionMaps || googleMapsUrl);
	};
	return (
		<>
			<Modal
				height="90vh"
				responsiveFullSize
				action={
					<Grid container spacing="10px" padding={xsCarrito ? '20px 40px 25px 40px' : '20px 32px 25px 40px'}>
						<Grid item flex>
							{
								telefono ? (
									<a onClick={(e)=>e.stopPropagation()} href={`tel:${telefono}`}>
										<Button
											variant="outlined-round"
											fullWidth
										>
											Llamar
										</Button>
									</a>
								) : (
									<Button
										variant="outlined-round"
										fullWidth
										disabled={true}
									>
										Llamar
									</Button>
								)
							}
							
						</Grid>
						<Grid item flex>
							<Button
								onClick={handleComoLlegar}
								variant="contained-round"
								fullWidth
							>
                                ¿Cómo llegar?
							</Button>
						</Grid>
					</Grid>
				}
				title={
					<Grid
						container
						direction='column'
						padding={xsCarrito ? '0 40px 0 40px' : '0 16px 0 16px'}
					>
						{
							imgRestaurante && (
								<Grid container justifyContent='center'>
									<CardMedia alt={'Sucursal ' + nombreSucursal} maxwidth='364px' borderRadius='14px' height='192px' src={imgRestaurante} />
								</Grid>
							)
						}
						<Typography padding='0 0 5px 0' variant='subtitle1' color='grey112'>{tipoSucursal} Panamá</Typography>
						<Title variant="h8" margin='0 0 16px 0'>{nombreSucursal}</Title>
					</Grid>
				}
				padding={xsCarrito ? '0 40px 0 40px' : '0 16px 0 16px'}
				width="100%"
				maxWidth="438px"
				close
				handleClose={handleClose}
				open={open}
			>
				<Grid container flex direction='column'>
					<Typography variant='subtitle2'>Dirección</Typography>
					<Typography variant='regular' margin=' 0 0 24px 0'>{direccion}</Typography>
					<Grid container alignItems='center'>
						<Typography padding='0 5px 0 0' variant='subtitle2'>Horario de servicio</Typography>
						<div >
							<Tag>Abierto Todos los días</Tag>
						</div>
					</Grid>
					<Typography variant='regular' margin=' 0 0 24px 0'>{horario}</Typography>
					{
						telefono ? <>
							<Typography variant='subtitle2'>Teléfono</Typography>
							<Typography variant='regular' margin=' 0 0 24px 0'>{telefono}</Typography>
						</> : null
					}
					
				</Grid>
			</Modal>
		</>
	);
};

export default (ModalSucursal);