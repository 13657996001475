import { ArrowBackIcon, ArrowFowardIcon } from '~/assets/icons';
import Grid from '~/components/Grid';
import moment from 'moment';
import 'moment/locale/es';
import IconButton from '~/components/IconButton';
import Typography from '~/components/Typography';
import { ButtonDay, RowList } from './styled';
import { LIMITS } from './hooks';

interface props {
	handleBackDecade: () => void
	handleNextDecade: () => void
	selectedYear: string
	handleClickYear: (month: number) => void
	value: string | null
}

export const DecadePicker = ({
	selectedYear,
	handleBackDecade,
	handleNextDecade,
	handleClickYear,
	value
} : props) => {
	const decadeStart = parseInt(selectedYear) - (parseInt(selectedYear) % 10);

	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid container alignItems="center">
					<Typography variant="subtitle1bold" transform="capitalize" cursor='default' userSelect='none'>
						<strong>{decadeStart} - {decadeStart + 9}</strong>
					</Typography>
				</Grid>

				<Grid container>
					<IconButton onClick={() => handleBackDecade()}>
						<ArrowBackIcon />
					</IconButton>

					<IconButton onClick={() => handleNextDecade()}>
						<ArrowFowardIcon />
					</IconButton>
				</Grid>
			</Grid>

			<Grid className="short_animated zoomIn" margin='15px 0px 0px 0px' spacing='12px' container direction="column">
				<>
					{[...Array(3)].map((_,row) => (
						<RowList justifyContent="space-between" spacing='5px' columns={4} key={`RowList${row}`}>
							{[...Array(4)].map((_, col) => {
								const itemCol = (row * 4) + col;

								const yearLimitBegin = parseInt(moment(LIMITS.BEGIN).format('YYYY'));
								const yearLimitEnd = parseInt(moment(LIMITS.END).format('YYYY'));
								const year = decadeStart + itemCol - 1;

								const outOfBoundsYear = year < yearLimitBegin || year > yearLimitEnd;

								const isSelected = year === parseInt(moment(value).format('YYYY'));

								return (
									itemCol === 0 || itemCol === 11 ? (
										<ButtonDay key={`Year${itemCol}${Math.random()}`} disabled />
									) : outOfBoundsYear ? (
										<ButtonDay key={`Year${itemCol}${Math.random()}`} disabled>
											{year}
										</ButtonDay>
									) : (
										<ButtonDay 
											key={`Year${itemCol}${Math.random()}`}
											isSelected={isSelected}
											onClick={(e) => {
												e.stopPropagation();
												handleClickYear(year);
											}}
										>
											{year}
										</ButtonDay>
									)
								);
							})}
						</RowList>
					))}
				</>
			</Grid>
		</>
	);
};
