import React, { useContext } from 'react';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { MainContainer } from '../../styled';
import { Button, Grid, Title, Typography } from '~/components';
import { PATHS } from '~/constants/paths';

interface props {
	email: string
}

const AvisoRestablecerCuenta: React.FC<props> = ({
	email
}) => {
	const {
		navigate
	} = useContext(GeneralLayoutContext);

	return (
		<MainContainer>
			<Title variant='h5' margin='0 0 16px 0'>Restablecer cuenta</Title>

			<Grid container margin='0 0 32px' spacing=''>
				<Typography variant='subtitle2regular'>
					Se ha enviado un correo electrónico de restablecimiento de contraseña a: <Typography variant='subtitle2'>{email}</Typography>, pero puede llevar varios minutos que aparezca en tu bandeja de entrada.
				</Typography>
				<br />
				<Typography variant='subtitle2regular'>
					Por favor, espera al menos 10 minutos antes de intentar otro restablecimiento
				</Typography>
			</Grid>

			<Button onClick={() => navigate(PATHS.main)} variant='outlined'>Volver al inicio</Button>
		</MainContainer>
	);
};
export default AvisoRestablecerCuenta;