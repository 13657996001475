//colors.ts

interface IDictionary<TValue> {
  [id: string]: TValue;
}
const isDev = import.meta.env.VITE_APP_IS_DEV;

const colors: IDictionary<string> = {
	primaryHover: 'rgb(168, 19, 16)',
	subtitle: 'rgb(227, 29, 26)',
	primary80: 'rgb(204, 53, 40, 0.8)',
	primaryLigth: 'rgb(196, 78, 76)',
	primaryDark: 'rgb(170, 23, 20)',
	backgroundColor: 'rgb(255, 246, 226)',
	backgroundColorSecondary: 'rgb(253, 200, 188)',
	/* Greys  */
	// grey: 'rgb(186, 186, 186)',

	borderGrey: '#C6C6C6',
	textFieldLabel: '#000000DE',
	titleGrey: 'rgba(118, 118, 118, 1)',

	lightGrey: 'rgb(186, 186, 186)',
	borderLightGrey: '#C6C6C6',
	disabledGreyColor: 'rgb(116, 135, 153, 0.4)',
	darkGrey: 'rgb(2, 2, 2, 0.8)',
	titleDarkGrey: 'rgb(70, 58, 63)',
	darkGrey65: 'rgb(2, 2, 2, 0.65)',
	bodyGrey: 'rgb(102, 102, 102)',
	darkGrey61: 'rgb(61, 61, 61)',
	inputGrey: 'rgb(247, 247, 247)',
	menuItemBg: 'rgb(247, 247, 247)',
	disabledGrey: 'rgb(224, 224, 224)',
	disabledFontGrey: 'rgb(137, 137, 137)',
	neutralBackgroundGrey: 'rgb(244, 244, 244)',
	ratGrey: 'rgb(108, 117, 125)',
	ratGrey80: 'rgb(108, 117, 125, 0.40)',
	ratGreyHover: 'rgb(183, 183, 183)',
	disabledGreyBorder: 'rgba(121, 116, 126, 1)',

	variantHeaderGrey: 'rgb(230, 230, 230)',
	toolTipGrey: 'rgb(51, 51, 51)',
	backgroundCardGrey: '#F5F5F5',
	fontGrey: 'hsl(0, 0%, 40%)',
	circularContainerGrey: 'rgb(184, 184, 184)',

	greyCard: '#DEDEDE',
	//--

	// black: 'rgb(0, 0, 0)',
	blackT: 'rgb(0, 0, 0, 0.2)',
	blackS: 'rgb(0, 0, 0, 0.4)',
	titleBlack: 'rgb(31, 31, 31)',

	black1B: '#1B1C1D',
	
	// white: 'rgb(255, 255, 255, 1)',
	white80: 'rgb(255, 255, 255, 0.7)',
	white251: 'rgb(251, 251, 251)',
	green: 'rgb(122, 201, 124)',
	// red: 'rgb(230, 64, 59)',
	ablue: 'rgb(61, 164, 211)',
	secondary: 'rgb(255, 127, 40)',
	checkGreen: '#43B02A',
	green176:'rgb(67, 176, 42)',
	blue:'#007FFF',
	blueLink: '#3366CC',
	bgColor: isDev === '1' ? 'rgb(216, 229, 255)' : 'rgb(255, 255, 255)',

	support1: 'rgba(190, 233, 251, 1)',
	support12: 'rgba(32, 146, 195, 1)',

	orange: 'rgba(255, 135, 66, 1)',
	blackForm: 'rgb(27, 28, 29)',
	yellowCard: 'rgb(255, 181, 0)',

	// colores oficiales
	primary: isDev === '1' ? 'rgb(14, 14, 114)' : 'rgba(218, 41, 28, 1)',
	red: 'rgba(218, 41, 28, 1)',
	yellow: 'rgba(255, 218, 127, 1)',
	primaryYellow: '#FFDA00',
	ocher: 'rgba(200, 142, 0, 1)',
	grey: 'rgba(118, 118, 118, 1)',
	black: 'rgba(27, 28, 29, 1)',
	grey240: 'rgba(240, 240, 240, 1)',
	grey198: 'rgba(198, 198, 198, 1)',
	grey112: 'rgba(112, 112, 112, 1)',
	greyF5: 'rgba(245, 245, 245, 1)',
	white: 'rgba(255, 255, 255, 1)',
	grey247: 'rgba(247, 247, 247, 1)',
	grey70: '#707070',
	disabledTextFieldGrey: '#8D8D8D',
	disabledCardBg: '#F5F5F5',
	yellowCupon: '#FFFAEC',

	green80: 'rgb(67, 176, 42, 0.75)',
	yellow80: 'rgb(250, 228, 169, 0.75)',
	warningYellowBg: '#FEF0DF',
	warningYellowColor: '#8B6F44',

	// New colors - Design System
	// black: '#000',
	// white: '#fff',

	whiteAlpha_50: 'rgba(255, 255, 255, 0.04)',
	whiteAlpha_100: 'rgba(255, 255, 255, 0.06)',
	whiteAlpha_200: 'rgba(255, 255, 255, 0.08)',
	whiteAlpha_300: 'rgba(255, 255, 255, 0.16)',
	whiteAlpha_400: 'rgba(255, 255, 255, 0.24)',
	whiteAlpha_500: 'rgba(255, 255, 255, 0.36)',
	whiteAlpha_600: 'rgba(255, 255, 255, 0.48)',
	whiteAlpha_700: 'rgba(255, 255, 255, 0.64)',
	whiteAlpha_800: 'rgba(255, 255, 255, 0.80)',
	whiteAlpha_900: 'rgba(255, 255, 255, 0.92)',

	blackAlpha_50: 'rgba(0, 0, 0, 0.04)',
	blackAlpha_100: 'rgba(0, 0, 0, 0.06)',
	blackAlpha_200: 'rgba(0, 0, 0, 0.08)',
	blackAlpha_300: 'rgba(0, 0, 0, 0.16)',
	blackAlpha_400: 'rgba(0, 0, 0, 0.24)',
	blackAlpha_500: 'rgba(0, 0, 0, 0.36)',
	blackAlpha_600: 'rgba(0, 0, 0, 0.48)',
	blackAlpha_700: 'rgba(0, 0, 0, 0.64)',
	blackAlpha_800: 'rgba(0, 0, 0, 0.80)',
	blackAlpha_900: 'rgba(0, 0, 0, 0.92)',

	gray_50: 'rgba(247, 250, 252, 1)',
	gray_100: 'rgba(237, 242, 247, 1)',
	gray_200: 'rgba(226, 232, 240, 1)',
	gray_300: 'rgba(203, 213, 224, 1)',
	gray_400: 'rgba(160, 174, 192, 1)',
	gray_500: 'rgba(113, 128, 150, 1)',
	gray_600: 'rgba(74, 85, 104, 1)',
	gray_700: 'rgba(45, 55, 72, 1)',
	gray_800: 'rgba(26, 32, 44, 1)',
	gray_900: 'rgba(23, 25, 35, 1)',

	red_50: 'rgba(255, 245, 245, 1)',
	red_100: 'rgba(254, 215, 215, 1)',
	red_200: 'rgba(254, 178, 178, 1)',
	red_300: 'rgba(252, 129, 129, 1)',
	red_400: 'rgba(245, 101, 101, 1)',
	red_500: 'rgba(229, 62, 62, 1)',
	red_600: 'rgba(197, 48, 48, 1)',
	red_700: 'rgba(155, 44, 44, 1)',
	red_800: 'rgba(130, 39, 39, 1)',
	red_900: 'rgba(99, 23, 27, 1)',

	orange_50: 'rgba(255, 250, 240, 1)',
	orange_100: 'rgba(254, 235, 203, 1)',
	orange_200: 'rgba(251, 211, 141, 1)',
	orange_300: 'rgba(246, 173, 85, 1)',
	orange_400: 'rgba(237, 137, 54, 1)',
	orange_500: 'rgba(221, 107, 32, 1)',
	orange_600: 'rgba(192, 86, 33, 1)',
	orange_700: 'rgba(156, 66, 33, 1)',
	orange_800: 'rgba(123, 52, 30, 1)',
	orange_900: 'rgba(101, 43, 25, 1)',

	yellow_50: 'rgba(255, 255, 240, 1)',
	yellow_100: 'rgba(254, 252, 191, 1)',
	yellow_200: 'rgba(250, 240, 137, 1)',
	yellow_300: 'rgba(246, 224, 94, 1)',
	yellow_400: 'rgba(236, 201, 75, 1)',
	yellow_500: 'rgba(214, 158, 46, 1)',
	yellow_600: 'rgba(183, 121, 31, 1)',
	yellow_700: 'rgba(151, 90, 22, 1)',
	yellow_800: 'rgba(116, 66, 16, 1)',
	yellow_900: 'rgba(95, 55, 14, 1)',

	green_50: 'rgba(240, 255, 244, 1)',
	green_100: 'rgba(198, 246, 213, 1)',
	green_200: 'rgba(154, 230, 180, 1)',
	green_300: 'rgba(104, 211, 145, 1)',
	green_400: 'rgba(72, 187, 120, 1)',
	green_500: 'rgba(56, 161, 105, 1)',
	green_600: 'rgba(37, 133, 90, 1)',
	green_700: 'rgba(39, 103, 73, 1)',
	green_800: 'rgba(34, 84, 61, 1)',
	green_900: 'rgba(28, 69, 50, 1)',

	teal_50: 'rgba(230, 255, 250, 1)',
	teal_100: 'rgba(178, 245, 234, 1)',
	teal_200: 'rgba(129, 230, 217, 1)',
	teal_300: 'rgba(79, 209, 197, 1)',
	teal_400: 'rgba(56, 178, 172, 1)',
	teal_500: 'rgba(49, 151, 149, 1)',
	teal_600: 'rgba(44, 122, 123, 1)',
	teal_700: 'rgba(40, 94, 97, 1)',
	teal_800: 'rgba(35, 78, 82, 1)',
	teal_900: 'rgba(29, 64, 68, 1)',

	blue_50: 'rgba(235, 248, 255, 1)',
	blue_100: 'rgba(190, 227, 248, 1)',
	blue_200: 'rgba(144, 205, 244, 1)',
	blue_300: 'rgba(99, 179, 237, 1)',
	blue_400: 'rgba(66, 153, 225, 1)',
	blue_500: 'rgba(49, 130, 206, 1)',
	blue_600: 'rgba(43, 108, 176, 1)',
	blue_700: 'rgba(44, 82, 130, 1)',
	blue_800: 'rgba(42, 67, 101, 1)',
	blue_900: 'rgba(26, 54, 93, 1)',

	cyan_50: 'rgba(237, 253, 253, 1)',
	cyan_100: 'rgba(196, 241, 249, 1)',
	cyan_200: 'rgba(157, 236, 249, 1)',
	cyan_300: 'rgba(118, 228, 247, 1)',
	cyan_400: 'rgba(11, 197, 234, 1)',
	cyan_500: 'rgba(0, 181, 216, 1)',
	cyan_600: 'rgba(0, 163, 196, 1)',
	cyan_700: 'rgba(9, 135, 160, 1)',
	cyan_800: 'rgba(8, 111, 131, 1)',
	cyan_900: 'rgba(6, 86, 102, 1)',

	purple_50: 'rgba(250, 245, 255, 1)',
	purple_100: 'rgba(233, 216, 253, 1)',
	purple_200: 'rgba(214, 188, 250, 1)',
	purple_300: 'rgba(183, 148, 244, 1)',
	purple_400: 'rgba(159, 122, 234, 1)',
	purple_500: 'rgba(128, 90, 213, 1)',
	purple_600: 'rgba(107, 70, 193, 1)',
	purple_700: 'rgba(85, 60, 154, 1)',
	purple_800: 'rgba(68, 51, 122, 1)',
	purple_900: 'rgba(50, 38, 89, 1)',

	pink_50: 'rgba(255, 245, 247, 1)',
	pink_100: 'rgba(254, 215, 226, 1)',
	pink_200: 'rgba(251, 182, 206, 1)',
	pink_300: 'rgba(246, 135, 179, 1)',
	pink_400: 'rgba(237, 100, 166, 1)',
	pink_500: 'rgba(213, 63, 140, 1)',
	pink_600: 'rgba(184, 50, 128, 1)',
	pink_700: 'rgba(151, 38, 109, 1)',
	pink_800: 'rgba(112, 36, 89, 1)',
	pink_900: 'rgba(82, 27, 65, 1)',
};

export default colors;