import styled, { css } from 'styled-components';

export interface ProfileCardsContainerProps {
  padding?: string;
  disabled?: boolean;
  alignItems?: string;
}

export const ProfileCardsContainer = styled.div<ProfileCardsContainerProps>`
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.grey198};
    padding: ${props => props.padding ? props.padding : '1rem'};
    display: flex;
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    flex-direction: column;
    text-align: center;
    flex: 1;
    border-radius: 8px;
    cursor: pointer;
    ${props => props.disabled ? css`
      cursor: auto;
      border: none;
    ` : null}
`;