import React from 'react';

import 'react-phone-number-input/style.css';
import { Button, CheckBox, DateInput, Grid, TextField, TextFieldSelect, Title, Typography, WarningModal } from '~/components';
import { SelectItemsTypes } from '~/interfaces';
import { MainContainer } from '../../styled';
import { useDialog } from '~/hooks';
import { validateDateFechaNacimiento } from '~/utils/validations';
import moment from 'moment';
import 'moment/locale/es';
import { CheckProps } from '../AuthForm';

type CheckTypes = {
    error?: boolean,
    check: boolean,
    helperText?: string,
}
type Props = {
	nombres: string,
	handleNombres: (e: React.ChangeEvent < HTMLInputElement >) => void,
	apellidoPaterno: string,
	handleApellidoPaterno: (e: React.ChangeEvent < HTMLInputElement >) => void,
	apellidoMaterno: string,
	handleApellidoMaterno: (e: React.ChangeEvent < HTMLInputElement >) => void,
	fechaDeNacimiento: string,
	handleFechaDeNacimiento: (e: string) => void,
	genero: SelectItemsTypes,
	handleGenero: (value: SelectItemsTypes) => void,
	dataGeneros: SelectItemsTypes[],
	nombresCheck: CheckTypes,
	apellidoPaternoCheck: CheckTypes,
	apellidoMaternoCheck: CheckTypes,
	fechaDeNacimientoCheck: CheckTypes,
	generoCheck: CheckTypes,
	disabledRegistro: boolean,
	loading: boolean,
	handleRegistro: ()=>void,
	notificacionesCheck?: boolean,
	xs: boolean,
	handleModificarInfoUsuario: (eliminaCuenta?: boolean | undefined) => void
	handleNotificacionesCheck: ()=>void

	email: string
	handleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
	emailCheck: CheckProps
};

const Registro : React.FC<Props> = ({ 		
	nombres,
	handleNombres,
	apellidoPaterno,
	loading,
	handleApellidoPaterno,
	apellidoMaterno,
	handleApellidoMaterno,
	fechaDeNacimiento,
	handleFechaDeNacimiento,
	genero,
	handleGenero,
	dataGeneros,
	nombresCheck,
	apellidoPaternoCheck,
	apellidoMaternoCheck,
	fechaDeNacimientoCheck,
	generoCheck,
	disabledRegistro,
	handleRegistro, 
	notificacionesCheck,
	handleNotificacionesCheck,
	handleModificarInfoUsuario,
	xs,

	email,
	emailCheck,
	handleEmail,
} : Props) => {
	const {
		open: openCancelarRegistro,
		handleOpen: handleOpenCancelarRegistro,
		handleClose: handleCloseCancelarRegistro
	} = useDialog();

	return (
		<>
			<WarningModal 
				width='341px'
				loading={loading}
				warningLabel={
					<>¿Seguro que deseas <br/> cancelar el registro?</>
				}
				handleAcept={()=>handleModificarInfoUsuario(true)}
				open={openCancelarRegistro} 
				handleClose={handleCloseCancelarRegistro} 
			/>

			<MainContainer>
				<Grid item padding={'0 0 8px 0' } container justifyContent={'center'}>
					<Title variant={'h4'} >Datos del Usuario</Title>
				</Grid>

				<Grid item padding='0 0 18px 0' container justifyContent='center'>
					<Title variant='h5'>Información de contacto</Title>
				</Grid>

				<TextField 
					margin="0 0 3px 0" 
					disabled={loading} 
					fullWidth
					{...nombresCheck} 
					onChange={handleNombres} 
					value={nombres} 
					label='Nombre(s)'
				/>

				<TextField 
					margin="0 0 3px 0" 
					fullWidth
					disabled={loading} 
					{...apellidoPaternoCheck} 
					onChange={handleApellidoPaterno} 
					value={apellidoPaterno} 
					label='Apellido Paterno'
				/>

				<TextField 
					margin="0 0 3px 0" 
					fullWidth
					disabled={loading} 
					{...apellidoMaternoCheck}  
					value={apellidoMaterno} 
					label='Apellido Materno' 
					onChange={handleApellidoMaterno}
				/>

				<DateInput 
					value={fechaDeNacimiento}
					handleValue={handleFechaDeNacimiento}
					label='Fecha de nacimiento'
					validateDate={validateDateFechaNacimiento}
					dFechasAPartirDe={moment().subtract(13, 'year').format('YYYY-MM-DD')}
					{...fechaDeNacimientoCheck}
					disabled={loading} 
					padding='3px 0 0 0'
				/>

				<TextFieldSelect 
					padding='3px 0 5px 0'
					fullWidth
					{...generoCheck}
					disabled={loading} 
					value={genero}
					onChange={handleGenero}
					id="genero"
					items={dataGeneros}
					label='Género'
				/>

				<TextField 
					{...emailCheck}
					id="correo"
					placeholder="Ingresa tu correo" 
					onChange={handleEmail} 
					value={email} 
					disabled={loading} 
					padding="0 0 5px 0" 
					label="Email" 
				/>

				<Grid container padding='16px 0 0 0' alignItems='center'>
					<CheckBox checked={notificacionesCheck} onClick={handleNotificacionesCheck}/> 
					<Grid item flex container textAlign='center' padding='0 24px 0 0' justifyContent='center'>
						<Typography variant='caption'>
							Acepto recibir contenido publicitario y <br /> promociones de Grupo Panamá al teléfono  <br /> y correo electrónico proporcionados
						</Typography>
					</Grid>
				</Grid>

				<Button 
					loading={loading} 
					onClick={handleRegistro} 
					disabled={disabledRegistro} 
					variant="contained-round" 
					margin={xs ? '24px 0 5px 0' : '25px 0 25px 0'}>Continuar</Button>

				<Button margin='0 0 16px 0' onClick={handleOpenCancelarRegistro} variant='underline'>
					Cancelar Registro
				</Button>
			</MainContainer>
		</>
	);
};

export default (Registro);