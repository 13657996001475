import styled from 'styled-components';

export interface TrabajoContainerProps {
  padding?: string;
}

export const TrabajoContainer = styled.div<TrabajoContainerProps>`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .imgEmpleado {
        width: 100%;
        max-width: 396px;
        height: 330px;
        @media (max-width: 650px) {
            max-width: 244px;
            height: 204px;
        }
    }

    .title {
        background-color: ${props => props.theme.colors.black};
        opacity: 0.6;
        padding: 9px 90px 9px 90px;

        @media (max-width: 650px) {
            span {
                font-size: ${props => props.theme.text.size.subtitle1} !important;
            }
            padding: 3px 35px 3px 35px;

        }
        margin-bottom: 25px;
    }
`;