import React, { Fragment } from 'react';
import Grid from '../Grid';
import Title from '../Title';
import { useDialog } from '~/hooks';
import IconButton from '../IconButton';
import { ChevronUpStyled, GridStyled } from './styled';
import { ExpandLessIcon } from '~/assets/icons';
type Props = {
  children: JSX.Element | JSX.Element[],
  expansionTitle?: string,
};
const ExpansionPanel : React.FC<Props> = ({ expansionTitle, children } : Props) => {
	const {
		handleOpen,
		open
	} = useDialog();
	return (
		<Fragment >
			<Grid
				container
				margin="14px 0 14px 0"
			>
				<Grid container item>
					<Title onClick={handleOpen} pointer variant="h8">
						{expansionTitle}
					</Title>

					<Grid container item>
						<IconButton
							onClick={handleOpen}
						>
							<ChevronUpStyled isShowing={!open}>
								<ExpandLessIcon />
							</ChevronUpStyled>
						</IconButton>
					</Grid>
				</Grid>
			</Grid>

			<GridStyled isShowing={open}>
				{children}
			</GridStyled>
					
		</Fragment>
	);
};
export default (ExpansionPanel);