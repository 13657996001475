import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteIconChiquito, EditIconChiquito, HeartIcon, RadioButtonCheckedIconChiquito } from '~/assets/icons';
import { Container, Grid, Paragraph, Typography, LinkSpanBlue } from '~/components';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const TemasDeAyudaSubtema : React.FC = () => {

	const { handleShowBack } = useContext(GeneralLayoutContext);


	const {handleDoneInitialLoading, userInfo} = useContext(CookiesContext);

	const params = useParams();
	const nTema = params.nTema;
	const nSubtema = params.nSubtema;

	useEffect(() => {
		handleShowBack(`/temas-de-ayuda/${nTema}`, 'Atrás', 'Ayuda', true);

		if(nTema){
			handleDoneInitialLoading();
		}
	}, [nTema]);

	const TitleContent = ({children}: {children: string}) => {
		return <Typography variant='regular' color='grey70'>{children}</Typography>;
	};

	const getContent = () => {
		switch (nTema) {
		case '1':
			switch (nSubtema) {
			case '1':
				return (
					<Grid container justifyContent='center' >
						<Grid width='342px'>
							<Grid container direction='column' spacing='22px' margin='12px 0 0 0'>
								<TitleContent> Olvidé mi contraseña </TitleContent>
								<Typography variant='regular' lineHeight='1.25'>1. Toca el enlace de abajo para acceder al apartado de iniciar sesión.</Typography>
								<Typography variant='regular' lineHeight='1.25'>2. Escribe el correo electrónico que usaste para crear tu cuenta.</Typography>
								<Typography variant='regular' lineHeight='1.25'>3. Da clic en <Typography textDecoration='underline' variant='regular'>¿Olvidaste tu contraseña? </Typography> para restablecerla.</Typography>
								<Typography variant='regular' lineHeight='1.25'>4. Recibirás un correo electrónico con un código único que puedes usar para crear una nueva contraseña.</Typography>
								<Typography variant='regular' lineHeight='1.25'>Ten cuidado de no compartir tu contraseña con otras personas. El equipo de soporte de Panamá nunca te pedirá tu contraseña.</Typography>
								{
									userInfo?.invitado && <LinkSpanBlue goto="/recuperar-contrasenia" margin='20px 0 0 0'>
								Restablecer mi contraseña
									</LinkSpanBlue>
								}
							</Grid>
						</Grid>
					</Grid>
				);	

			case '2':
				return (
					<Grid container justifyContent='center' >
						<Grid width='342px'>
							<Grid container direction='column' spacing='22px' margin='12px 0 0 0'>
								<Typography variant='regular' color='grey70'>¿Como actualizar la información de mi cuenta?</Typography>
								<Typography variant='regular' lineHeight='1.25'>Para actualizar tu nombre, número de teléfono, fecha de nacimiento y sexo o avatar en la app de Panamá, sigue los pasos a continuación:</Typography>
								<Typography variant='regular' lineHeight='1.25'>1. Entra a Mi cuenta.</Typography>
								<Typography variant='regular' lineHeight='1.25'>2. Da clic en tu avatar en el apartado de editar.</Typography>
								<Typography variant='regular' lineHeight='1.25'>3. Toca el ícono del lápiz <EditIconChiquito/> en la casilla de información que deseas editar.</Typography>
								<Typography variant='regular' lineHeight='1.25'>4. Edita la información que deseas y da clic en el botón actualizar datos</Typography>
							</Grid>
						</Grid>
					</Grid>
				);

			case '3':
				return (
					<Grid container justifyContent='center' >
						<Grid width='342px'>
							<Grid container direction='column' spacing='22px' margin='12px 0 0 0'>
								<Typography variant='regular' color='grey70'>¿Cómo actualizar mis direcciones de entrega?</Typography>
								<Typography variant='body3' lineHeight='1.25'>Para modificar una ubicación guardada, haz lo siguiente:</Typography>
								<Typography variant='regular' lineHeight='1.25'>1. En la pantalla principal, toca Mi cuenta.</Typography>
								<Typography variant='regular' lineHeight='1.25'>2. Selecciona Mis direcciones.</Typography>
								<Typography variant='regular' lineHeight='1.25'>3. Toca el ícono del lápiz <EditIconChiquito/> en la dirección de entrega que deseas editar.</Typography>
								<Typography variant='regular' lineHeight='1.25'>4. Edita el campo de información que deseas y da clic en guardar.</Typography>
								<Typography variant='regular' lineHeight='1.25'>5. Para elegir una dirección como tu predeterminada activa la casilla <br/> <RadioButtonCheckedIconChiquito/> Dirección de envío predeterminada.</Typography>
								<Typography margin='20px 0 0 0' variant='body3'>Para eliminar una ubicación guardada, haz lo siguiente:</Typography>
								<Typography variant='regular' lineHeight='1.25'>1. En la pantalla principal, toca Mi cuenta.</Typography>
								<Typography variant='regular' lineHeight='1.25'>2. Selecciona Mis direcciones.</Typography>
								<Typography variant='regular' lineHeight='1.25'>3. Toca el ícono del bote de basura <DeleteIconChiquito/> en la dirección de entrega que deseas eliminar.
								</Typography><Typography variant='regular' lineHeight='1.25'>4. Te aparecerá una pantalla de confirmación, presiona el botón Sí, Seguro, para eliminar esta dirección.</Typography>
							</Grid>
						</Grid>
					</Grid>
				);

			case '4':
				return (
					<Grid container justifyContent='center' >
						<Grid width='342px'>
							<Grid container direction='column' spacing='22px' margin='12px 0 0 0'>
								<Typography variant='regular' color='grey70'>¿Cómo añadir productos a favoritos?</Typography>
								<Typography variant='regular' lineHeight='1.25'>Para añadir productos a favoritos, presiona el ícono de corazón <HeartIcon width='14px' height='12px'/> que se encuentra en la esquina superior derecha de la foto de cada producto.</Typography>
								<Typography variant='regular' lineHeight='1.25'> Estos productos quedarán marcados en automático como favoritos y podrás verlos accediendo desde Mi cuenta {'>'} Favoritos.</Typography>
								<Typography variant='regular' lineHeight='1.25'> Para eliminar algún producto de favoritos sigue estos pasos.</Typography>
								<Typography variant='regular' lineHeight='1.25'>1. Entra a Mi cuenta.</Typography>
								<Typography variant='regular' lineHeight='1.25'>2. Presiona Favoritos.</Typography>
								<Typography variant='regular' lineHeight='1.25'>3. Presiona el ícono de corazón que se encuentra en la esquina superior derecha de la foto del producto, al deshabilitar este ícono de corazón el producto quedará fuera de tu lista de productos favoritos. </Typography>
							</Grid>
						</Grid>
					</Grid>
				);

			default:		
				return <Typography>Not found</Typography>;
			}
		case '2':
			switch (nSubtema) {
			case '1':
				return (
					<Grid container justifyContent='center' >
						Not found
					</Grid>);	
					
			case '2':
				return (
					<Grid container justifyContent='center' >
						Not found
					</Grid>);		
			case '3':
				return (
					<Grid container justifyContent='center' >
						Not found
					</Grid>);	
			case '4':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Puedo pagar en efectivo? </TitleContent>
							<Paragraph padding='22px 0 0 0'>
							Por el momento no es posible realizar tu pago en efectivo a través de nuestra tienda en línea o app. Solo aceptamos pagos en línea a través de medios electrónicos seguros, como tarjetas de crédito o débito. 
								<br/>								<br/>
							Esta medida se toma para garantizar la seguridad de tus transacciones y proteger tus datos personales y financieros. 
								<br/>								<br/>
							Agradecemos tu comprensión y esperamos poder brindarte un servicio de calidad. Si tienes alguna pregunta o necesitas más información, puedes contactar a nuestro equipo de soporte con toda confianza.
							</Paragraph>
						</div>
					</Container>
				);
		
			default:		
				return <Typography>Not found</Typography>;
		
			}
		case '3':
			switch (nSubtema) {
			case '1':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Cómo canjear un nuevo cupón? </TitleContent>
							<Paragraph padding='22px 0 0 0'>
							1. Entra al menú. 
								<br/>
								<br/>
							2. Selecciona el apartado de “Cupones y promociones”.
								<br/>
								<br/>

							3. Selecciona la opción “Canjear Cupón”. 
								<br/>
								<br/>

							4. Escribe el código del cupón que deseas activar. 
								<br/>
								<br/>

							5. Verifica que el cupón se haya agregado correctamente a tu cuenta. 
								<br/>
								<br/>

							6. Al momento de terminar y pagar tu pedido elige el cupón promocional activado para aplicarlo a tu compra. 
								<br/>
								<br/>
							
							Ten en cuenta que los cupones promocionales tienen términos y condiciones específicos, como fechas de vencimiento y requisitos de compra mínimos. Asegúrate de revisar las condiciones del cupón antes de aplicarlo a tu pedido.
							</Paragraph>
						</div>
					</Container>	
				);	

			case '2':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Dónde veo las promociones y cupones disponibles para mí? </TitleContent>
							<Paragraph padding='22px 0 0 0'>
							Sigue los siguientes pasos: 
								<br/>
								<br/>
						1. Dirígete a la sección de “Cupones y promociones”.
								<br/>
								<br/>
						2. Allí encontrarás una lista de las promociones y cupones disponibles para ti en ese momento. 
								<br/>
								<br/>
						3. Si tienes algún cupón disponible, podrás aplicarlo durante el proceso de pago.
							</Paragraph>
						</div>
					</Container>	
				);
			
			default:		
				return <Typography>Not found</Typography>;
			
			}
		case '4':
			switch (nSubtema) {
			case '1':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Cómo veo los recibos de mis pedidos? </TitleContent>
							<Paragraph padding='22px 0 0 0'>
								1. Abre el menú. 
								<br/>
								<br/>
								2. Dirígete a la sección de “Historial de pedidos”. 
								<br/>
								<br/>
								3. Busca el pedido que necesitas. 
								<br/>
								<br/>
								4. En la página de detalles del pedido, deberías encontrar un botón o enlace que diga “Enviar recibo a mi correo”. 
								<br/>
								<br/>
								5. Revisa el correo electrónico ligado a tu cuenta para ver tu recibo de compra. 
								<br/>
								<br/>
								Recuerda que los recibos pueden tardar unos minutos en estar disponibles después de que se completa el pedido. Si aún no puedes encontrar el recibo que buscas, intenta buscar en el historial de pedidos más adelante.
							</Paragraph>
						</div>
					</Container>	
				);	

			case '2':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Cómo puedo facturar mis compras? </TitleContent>
							<Paragraph padding='22px 0 0 0'>		
								1. Abre el menú. 
								<br/>
								<br/>
								2. Dirígete a la sección de “Portal de facturación”.
								<br/>
								<br/>
								Al hacer clic en el enlace del portal de facturación, serás redirigido a otra página. Esto es completamente normal, la página a la que accederás será el sitio donde podrás generar tus facturas o realizar cualquier otra acción relacionada con la facturación.
								<br/>
								<br/>
								<LinkSpanBlue externalLink="https://portalfacturacion.grupopanama.mx/" margin='25px 0 0 0' >Portal de facturación</LinkSpanBlue>
							</Paragraph>
						</div>
					</Container>	
				);
				
			default:		
				return <Typography>Not found</Typography>;
			}

		case '5':
			switch (nSubtema) {
			case '1':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Cómo hacer pedidos? </TitleContent>
							<Paragraph padding='22px 0 0 0'>
								1. Selecciona entre las opciones disponibles de tienda, (Desayunos, Comidas y cenas, Pastelería, Etc.) navega por la tienda para encontrar los artículos que te gustaría pedir.
								<br/>
								<br/>
								2. Una vez que hayas elegido los artículos, agrégalos al carrito.
								<br/>
								<br/>
								3. Selecciona el carrito correspondiente a tu orden y da clic en el botón “Pagar” Revisa tu pedido para asegurarte de que esté completo y preciso. 
								<br/>
								<br/>
								4. Verifica todos los detalles de tu orden Espera a que el restaurante o pastelería confirme el pedido y te informe cuándo estará listo para ser entregado o recogido.
							</Paragraph>
						</div>
					</Container>	
				);	

			case '2':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Cómo puedo volver a Hacer un pedido anterior? </TitleContent>
							<Paragraph padding='22px 0 0 0'>		
								1. Entra al menú. 
								<br/>
								<br/>
								2. Selecciona “ Historial de pedidos” y busca el pedido que quieres volver a hacer. 
								<br/>
								<br/>
								3. Toca en “Añadir al carrito”.
								<br/>
								<br/>
								4. Se agregará la misma lista de artículos al carrito para que puedas hacer una revisión final antes de hacer el pedido otra vez.
							</Paragraph>
						</div>
					</Container>	
				);

			case '3':
				return (
					<Container paddingTop='21px' center>
						<div className='responsiveContainer'>
							<TitleContent> ¿Puedo pedir artículos de Restaurante y pastelería en el mismo pedido? </TitleContent>
							<Paragraph padding='22px 0 0 0'>		
								¡Hola! Quería informarte que no es posible pedir artículos de Comidas y cenas, Desayunos o Pastelería en el mismo pedido, ya que son tipos de tiendas distintas cada una genera tu propio carrito. 
								<br/>
								<br/>
								Cada tipo de tienda tiene tus propios productos, precios y políticas de envío. Por lo tanto, si deseas hacer un pedido que incluya artículos de diferentes tiendas, deberás realizar un pedido por separado para cada tienda. 
								<br/>
								<br/>
								Es importante tener en cuenta que cada pedido individual puede tener tu propio costo de envío y tiempo de entrega, dependiendo de la ubicación de la tienda y la distancia entre las tiendas y el lugar de entrega.
							</Paragraph>
						</div>
					</Container>	
				);

			default:		
				return <Typography>Not found</Typography>;
			}

		default:
			return <Typography>Not found</Typography>;
		}
	};

	useTitle(PAGINAS.AYUDA);

	return <Container>
		{getContent()}
	</Container>;
};
export default (TemasDeAyudaSubtema);