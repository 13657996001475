import React from 'react';
import _ from 'lodash';
import {
	CardMedia,
	Carousel,
	Grid,
} from 'components';
import {useWizardImg} from 'hooks';
import {ImgContainer, CarouselContainer} from './styled';
// https://codesandbox.io/s/lrk6955l79?file=/src/index.js:1084-1367

import { CircleIcon } from '~/assets/icons';
import { IBannerData } from '~/interfaces';
import { BannerContainer } from '../Banners/styled';
import { getCdnUrl } from '~/utils';


type CarrouselImgProps = {
	images: (IBannerData[])[],
	maxwidth: string,
	handleClick: (cUrlClick: string, bUrlClickPestañaNueva: number) => void 
}
const CarrouselImg : React.FC<CarrouselImgProps> = ({images, maxwidth, handleClick}) => {
	const newImages: (IBannerData[])[] = _.uniqBy([images[images.length - 1], images[0], ...images], o=>o);

	const {
		state,
		numItems,
		handlers,
		handlePosition,
	} = useWizardImg(newImages);

	return (
		<>
			<Grid container justifyContent="center">
				<CarouselContainer maxwidth={maxwidth} {...handlers}>
					<Carousel numItems={numItems} handlers={handlers} state={state}>
						{newImages?.map((item: IBannerData[], index: number) =>{
							return <ImgContainer key={index}>
								{
									item?.map((imag: IBannerData, e) => (
										<BannerContainer key={e} item flex>
											<CardMedia 
												height='100%' 
												minHeight='200px' 
												pointer={!!(imag.cUrlClick)} 
												src={getCdnUrl(imag.cImagenUrl)} 
												alt={imag?.cDescripcion && 'Banner de ' + imag?.cDescripcion} 
												onClick={() => handleClick(imag.cUrlClick, imag.bUrlClickPestañaNueva)} 
											/>
										</BannerContainer>
									))
								}
							</ImgContainer>;
						})}
					</Carousel>
				</CarouselContainer>
			</Grid>

			<Grid padding="5px 0 0 0" container justifyContent="center" item>
				{newImages.map((_child, index: number) => (
					<div key={`img${index}`} onClick={()=>handlePosition(index)}>
						{<CircleIcon color='primary' width="10" height="10" style={{cursor: 'pointer', opacity: state.pos !== index ? 0.3 : 0.9}} ></CircleIcon>}
					</div>
				))}
			</Grid>
		</>
	);
};


export default CarrouselImg;

/* 

import React from 'react';
import _ from 'lodash';
import {
	Carousel,
	Grid,
	RipplesButtons
} from 'components';
import {useWizardImg} from 'hooks';
import {ImgContainer, CarouselContainer, IconButtonContainerLeft, IconButtonStyled, IconButtonContainerRight} from './styled';
// https://codesandbox.io/s/lrk6955l79?file=/src/index.js:1084-1367

import { ChevronLeft, ChevronRight, CircleIcon } from '~/assets/icons';
import { IBannerData } from '~/interfaces';

type CarrouselImgProps = {
	images: IBannerData[],
	maxwidth: string
}
const CarrouselImg : React.FC<CarrouselImgProps> = ({images, maxwidth} : CarrouselImgProps) => {


	const newImages: IBannerData[] = _.uniqBy([images[images.length - 1], images[0], ...images], o=>o);

	
	const {
		state,
		numItems,
		handleNext,
		handleBack,
		handlers,
		handlePosition,
	} = useWizardImg(newImages);

	const styleIcon: React.CSSProperties = {
		borderRadius: '50%', 
		padding: '2px',
		placeItems: 'center',
		backgroundColor: 'transparent',
		border: 'none',
		textAlign: 'center',
	};
  

	return (
		<>
			<Grid container justifyContent="center">
				<CarouselContainer maxwidth={maxwidth} {...handlers}>
					<IconButtonContainerLeft>
						<RipplesButtons style={styleIcon} onClick={handleBack}>
							<IconButtonStyled>
								<ChevronLeft color={'white'} />
							</IconButtonStyled>

						</RipplesButtons>
					</IconButtonContainerLeft>

					<Carousel numItems={numItems} handlers={handlers} state={state}>
						{newImages.map((child: IBannerData, index: number) =>{
							return <ImgContainer key={index}>
								<img src={child.cImagenUrl} ></img>
							</ImgContainer>;
						})}
					</Carousel>
					<IconButtonContainerRight>
						<RipplesButtons style={styleIcon}  onClick={handleNext}>
							<IconButtonStyled>
								<ChevronRight color={'white'} />
							</IconButtonStyled>
						</RipplesButtons>
					</IconButtonContainerRight>
				</CarouselContainer>
       
			</Grid>
			<Grid padding="5px 0 0 0" container justifyContent="center" item>
				{newImages.map((_child: IBannerData, index: number) =>{
					return <div key={`img${index}`} onClick={()=>handlePosition(index)}>
						{<CircleIcon color='primary' width="10" height="10" style={{cursor: 'pointer', opacity: state.pos !== index ? 0.3 : 0.9}} ></CircleIcon>}
					</div>; 
				})}
			</Grid>
		</>
	);
};


export default CarrouselImg;

*/