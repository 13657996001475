import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, HelpCard, Typography } from '~/components';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const Ayuda : React.FC= () => {
	const { handleShowBack } = useContext(GeneralLayoutContext);
	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Ayuda', true);
	}, []);

	const { 
		handleDoneInitialLoading, 
		userInfo
	} = useContext(CookiesContext);

	useEffect(() => {
		handleDoneInitialLoading();
	},[]);

	const navigate = useNavigate();

	const handleGoToTemaDeAyuda = (value: number) => {
		navigate(`/temas-de-ayuda/${value}`);
	};

	useTitle(PAGINAS.AYUDA);

	return (
		<Container padding='31px 0 0 0' center>
			<div className='responsiveContainer'>
				{
					!(userInfo?.invitado) && <Grid container textAlign='left' direction='column' borderbottom={true} margin='0 0 42px 0'>
						<Typography variant='subtitle2regular' color='grey112'>Pedidos</Typography>
						<HelpCard onClick={()=>navigate('/historial-de-pedidos')} margin="16px 0 16px 0" titulo='Ayuda con un pedido'></HelpCard>
					</Grid>
				}
			
				<Grid spacing='5px' container textAlign='left' direction='column' >
					<Typography variant='subtitle2regular' color='grey112'>Temas de ayuda</Typography>
					<HelpCard onClick={()=>handleGoToTemaDeAyuda(1)} titulo='Mi cuenta'></HelpCard>
					<HelpCard onClick={()=>handleGoToTemaDeAyuda(2)} titulo='Pagos'></HelpCard>
					<HelpCard onClick={()=>handleGoToTemaDeAyuda(3)} titulo='Cupones y promociones'></HelpCard>
					<HelpCard onClick={()=>handleGoToTemaDeAyuda(4)} titulo='Recibos y facturas'></HelpCard>
					<HelpCard onClick={()=>handleGoToTemaDeAyuda(5)} titulo={<>Información sobre la <br/> entrega y recolección</>}></HelpCard>
				</Grid>
			</div>
		</Container>
	);
};
export default (Ayuda);