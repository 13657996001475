// Responsive
import React, { useContext, useEffect } from 'react';
import { Button, CardMedia, DateInput, Grid, TextField, TextFieldPhoneNumber, TextFieldSelect, Typography, WarningModal } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useDialog, useRegistro, useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';
import { validateDateFechaNacimiento } from '~/utils/validations';
import moment from 'moment';
import { Badge } from '~/components/Badge';
import { ProfileNotVerified } from './styled';
import EscudoExclamacion from '~/assets/icons/shieldExclamacion.png';

const AjustesDeCuenta : React.FC = () => {

	const { handleShowBack, handleGoBack } = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Mi usuario', true);
	}, []);

	const {
		handleGetUserInfo,
		nombresCheck,
		apellidoPaterno,
		handleNombres,
		loading,
		nombres,

		email,
		handleEmail,
		emailCheck,

		isEmailVerified,
		handleSendVerificationEmail,
		isSendingVerificationEmail,
		hasTouchedEmail,

		apellidoPaternoCheck,
		handleApellidoPaterno,
		apellidoMaterno,
		apellidoMaternoCheck,
		handleApellidoMaterno,
		fechaDeNacimientoCheck,
		handleFechaDeNacimiento,
		fechaDeNacimiento,
		generoCheck,
		genero,
		handleGenero,
		dataGeneros,
		numeroCelularCheck,
		numeroCelular,
		handleNumeroCelular,
		disabledModificar,
		handleModificarInfoUsuario,
	} = useRegistro(true);

	const {
		open: openEliminarCuenta,
		handleOpen: handleOpenEliminarCuenta,
		handleClose: handleCloseEliminarCuenta
	} = useDialog();

	useTitle(PAGINAS.PERFIL);

	useEffect(() => {
		handleGetUserInfo();
	}, [dataGeneros]);

	return (
		<>
			<WarningModal 
				width='341px' 
				loading={loading} 
				warningLabel={ 
					<>¿Seguro que deseas <br/> eliminar tu cuenta?</>
				} 
				handleAcept={()=>handleModificarInfoUsuario(true)} 
				open={openEliminarCuenta} 
				handleClose={handleCloseEliminarCuenta} 
			/>

			<Grid container justifyContent='center'>
				<Grid container direction='column' width='100%' maxwidth="395px">
					<Grid container direction='column' width='100%' padding='16px 24px' spacing='16px' borderbottom>
						<Typography variant='subtitle2regular'>Datos de contacto</Typography>

						<Grid container direction='column'>
							<TextFieldPhoneNumber 
								fullWidth
								padding='10px 0 0 0'
								{...{...numeroCelularCheck, check: false}} 
								value={numeroCelular} 
								countries={['MX']}
								onChange={handleNumeroCelular} 
								disabled
								label='Número celular'
							/>

							<TextField 
								fullWidth 
								edit
								padding='10px 0 0 0' 
								disabled={loading} 
								{...emailCheck} 
								onChange={handleEmail} 
								value={email} 
								label='Correo electrónico'
								customBadge={
									<Grid alignSelf='center'>
										{
											hasTouchedEmail ? <></> : isEmailVerified ? (
												<Badge colorPalette='green' variant='subtle' description='Correo verificado' />
											) : (
												<ProfileNotVerified>
													<CardMedia src={EscudoExclamacion} staticImg maxwidth='10px' />

													<Typography color='gray_600' variant='overline'>Verifica tu correo</Typography>
												</ProfileNotVerified>
											)
										}
									</Grid>
								}
							/>

							{(!isEmailVerified && !hasTouchedEmail) && <Button variant='contained-round' disabled={emailCheck.error || hasTouchedEmail} loading={isSendingVerificationEmail || loading} onClick={handleSendVerificationEmail} margin='24px 0 0 0'>Verificar</Button>}
						</Grid>
					</Grid>

					<Grid container direction='column' width='100%' padding='16px 24px' spacing='16px'>
						<Typography variant='subtitle2regular'>Datos personales</Typography>

						<TextField 
							fullWidth 
							edit
							noIcon
							disabled={loading}  
							{...nombresCheck} 
							onChange={handleNombres} 
							value={nombres} 
							label='Nombre(s)'
						/>

						<TextField 
							fullWidth 
							edit 
							noIcon 
							disabled={loading} 
							{...apellidoPaternoCheck} 
							onChange={handleApellidoPaterno} 
							value={apellidoPaterno} 
							label='Apellido Paterno'
						/>

						<TextField 
							fullWidth 
							edit 
							noIcon 
							disabled={loading} 
							{...apellidoMaternoCheck}  
							value={apellidoMaterno} 
							label='Apellido Materno' 
							onChange={handleApellidoMaterno}
						/>

						<DateInput 
							edit
							value={fechaDeNacimiento}
							handleValue={handleFechaDeNacimiento}
							label='Fecha de nacimiento'
							validateDate={validateDateFechaNacimiento}
							dFechasAPartirDe={moment().subtract(13, 'year').format('YYYY-MM-DD')}
							disabled={loading}
							{...fechaDeNacimientoCheck}
						/>

						<TextFieldSelect 
							fullWidth
							padding='0px'
							{...generoCheck}
							value={genero}
							edit
							onChange={handleGenero}
							id="genero"
							disabled={loading}
							items={dataGeneros}
							label='Género'
						/>
					</Grid>

					<Grid container direction='column' padding='16px 24px' spacing='32px'>
						<Grid container direction='row' maxheight='56px' flexWrap='nowrap' spacing='8px'>
							<Button loading={loading} onClick={()=>handleModificarInfoUsuario()} disabled={disabledModificar} variant='contained-round' fullWidth>
								Actualizar datos
							</Button>

							<Button onClick={handleGoBack} variant='outlined-round' fullWidth>
								Cancelar
							</Button>
						</Grid>

						<Button onClick={handleOpenEliminarCuenta} variant='underline'>
							Eliminar mi cuenta
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
export default (AjustesDeCuenta);