import React from 'react';
import { WarningModal } from '~/components';
type Props = {
    openDeleteDireccion: boolean,
    hadleCloseDeleteDireccion: VoidFunction,
    handleDeleteDireccion: VoidFunction,
	loading?: boolean
};
const DeleteDirecciones : React.FC<Props> = ({ 
	openDeleteDireccion,
	hadleCloseDeleteDireccion,
	handleDeleteDireccion,
	loading
} : Props) => {
	return (
		<WarningModal loading={loading} warningLabel={<>
		¿Estás seguro de querer eliminar <br /> esta dirección de entrega?
		</>} open={openDeleteDireccion} handleAcept={handleDeleteDireccion} handleClose={hadleCloseDeleteDireccion} />
	);
};
export default (DeleteDirecciones);