import { useEffect, useState, useContext } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { JFavoritosSecciones } from '~/interfaces';
import _ from 'lodash';
const useFavoritos = () => {
	const { handleShowBack } = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Favoritos', true);
	}, []);
	const {handleDoneInitialLoading, headersApi} = useContext(CookiesContext);
	useEffect(() => {
		handleDoneInitialLoading();
	}, []);
	
	const apiUrl = import.meta.env.VITE_API_URL;
	
	const [secciones, setSecciones] = useState<JFavoritosSecciones[] | undefined>(undefined);

	// Obtiene las categorías y los tipos de pedido 
	
	useEffect(() => {
		fetch(`${apiUrl}/APPWEB/ObtenerFavoritos`, {
			method: 'GET',
			headers: headersApi
		}).then((res) => res.json()).then((responseCat) => {

			if (responseCat?.Result) {
				handleDoneInitialLoading();
				setSecciones(responseCat.Result.jFavoritosSecciones);
			}

		}).catch((error) => {
			console.error(error, 'error');
		});

	
	}, [headersApi.Authorization]);

	const handleRemove = (nVproducto: number) => {
		if(secciones){
			const copy = [...secciones];
			copy.forEach(element => {
				element.VProductos = _.filter(element.VProductos, o=>o.nVProducto !== nVproducto);
			});
			setSecciones(copy);
		}
	};

	return {
		secciones,
		handleRemove
	};
};

export default useFavoritos;