import { useEffect } from 'react';
import { GeneralLoading } from '~/components';
import { useConfirmaCorreo } from '~/hooks';

const ConfirmaCorreo = () => {
	const {
		handleValidateEmail,
	} = useConfirmaCorreo();

	useEffect(() => {
		handleValidateEmail();
	}, []);

	return (
		<GeneralLoading showGeneralLoading />
	);
};

export default ConfirmaCorreo;