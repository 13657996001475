import React from 'react';
import CardMedia from '../CardMedia';
import Grid from '../Grid';
import Typography from '../Typography';
import { NivelesCardContainer } from './styled';
import { useNavigate } from 'react-router-dom';

type Props = {
    src: string,
    label: string,
    bHorarioDisponible: number,
    nNivelTienda: number,
	xs: boolean,
	last?: boolean,
};
const NivelesCard : React.FC<Props> = ({ src, label, nNivelTienda, xs, last } : Props) => {
	const navigate = useNavigate();

	const handleGotoNivel = () => {
		navigate(`/tienda/${nNivelTienda}`);
	};

	return (
		<NivelesCardContainer className='noselect' last={last} onClick={handleGotoNivel}>
			<Grid item width='60px' container alignItems='center'>
				<CardMedia alt={label} minHeight='60px' src={src}></CardMedia>
			</Grid>
			<Grid padding={`0 0 0 ${xs ? '10px' : '3px'}`} container flex justifyContent='center' direction='column'>
				<Typography>{label}</Typography>
			</Grid>
		</NivelesCardContainer>
	);
};
export default (NivelesCard);