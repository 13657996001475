import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, HelpCard, Typography } from '~/components';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useTitle } from '~/hooks';

const TemasDeAyuda : React.FC = () => {

	const { handleShowBack } = useContext(GeneralLayoutContext);


	const {handleDoneInitialLoading} = useContext(CookiesContext);

	const params = useParams();
	const nTema = params.nTema;

	useEffect(() => {
		handleShowBack('/ayuda', 'Atrás', 'Ayuda', true);

		if(nTema){
			handleDoneInitialLoading();
		}
	}, [nTema]);

	const getTemasDeAyuda = () => {
		switch (nTema) {
		case '1':
			return {
				title: 'Mi cuenta',
				data: [
					{
						cNombre: 'Olvidé mi contraseña',
						nValor: 1
					},
					{
						cNombre: <>¿Cómo actualizar la <br/> información de mi cuenta?</>,
						nValor: 2
					},
					{
						cNombre: <>¿Cómo actualizar mis <br/>direcciones de entrega?</>,
						nValor: 3
					},
					{
						cNombre: <>¿Cómo añadir productos a <br/>Favoritos?</>,
						nValor: 4
					}
				],
			};

		case '2':
			return {
				title: 'Pagos',
				data: [
					/* {
						cNombre: 'Pólitica de reembolsos',
						nValor: 1
					},
					{
						cNombre: <>¿Cómo añadir un método de <br/> Pago?</>,
						nValor: 2
					},
					{
						cNombre: <>¿Cómo eliminar un método <br/> de pago?</>,
						nValor: 3
					}, */
					{
						cNombre:'¿Puedo pagar en efectivo?',
						nValor: 4
					}
				],
			};
		case '3':
			return {
				title: 'Cupones y promociones',
				data: [
					{
						cNombre: '¿Cómo canjear un nuevo cupón?',
						nValor: 1
					},
					{
						cNombre: <>
						¿Dónde veo las promociones y <br/> cupones disponibles para mí?
						</>,
						nValor: 2
					}
				],
			};
		case '4':
			return {
				title: 'Recibos y facturas',
				data: [
					{
						cNombre: <>¿Cómo veo los recibos de mis <br/>pedidos?</>,
						nValor: 1
					},
					{
						cNombre: <>¿Cómo puedo facturar <br/>mis compras?</>,
						nValor: 2
					}
				],
			};
		case '5':
			return {
				title: 'Información sobre la entrega y recolección',
				data: [
					{
						cNombre: '¿Cómo hacer pedidos?',
						nValor: 1
					},
					{
						cNombre: <>¿Cómo puedo volver a <br/> Hacer un pedido anterior?</>,
						nValor: 2
					},
					{
						cNombre: <>¿Puedo pedir artículos de<br/> Restaurante y pastelería <br/> en el mismo pedido?</>,
						nValor: 3
					}
				],
			};
		default:
			return {
				title: 'Not found',
				data: []
			};
            
		}
	};

	const {
		data,
		title
	} = getTemasDeAyuda();

	const navigate = useNavigate();

	const handleGoToTemaDeAyudaSubtema = (value: number) => {
		navigate(`/temas-de-ayuda/${nTema}/${value}`);
	};

	useTitle(title && 'Ayuda ' + title);

	return (
		<Grid padding='31px 0 0 0' container justifyContent='center'>
			<Grid width='342px'>
				<Grid spacing='8px' container textAlign='left' direction='column' >
					<Typography variant='subtitle2regular' color='grey112'>{title}</Typography>
					{data.map((item, i) => (
						<HelpCard onClick={()=>handleGoToTemaDeAyudaSubtema(item.nValor)} key={i} titulo={item.cNombre} margin='0 14px' />
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default (TemasDeAyuda);