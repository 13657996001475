import React from 'react';
import { DeleteIcon, EditIcon } from '~/assets/icons';
import { DireccionesTypes, VoidFunctionType } from '~/interfaces';
import Grid from '../Grid';
import IconButton from '../IconButton';
import RadioButton from '../RadioButton';
import Tag from '../Tag';
import Typography from '../Typography';
import { DirectionCardContainer } from './styled';
type Props = {
	item: DireccionesTypes,
	onClick?: VoidFunctionType,
	checked?: boolean,
	view?: boolean,
	hadleOpenDeleteDireccion?: (nDireccion: number) => void,
	predeterminada?: boolean | null,
	handleOpenEditarDireccion?: (item: DireccionesTypes) => void
};
const DireccionCard : React.FC<Props> = ({ item, onClick, checked, view, hadleOpenDeleteDireccion, predeterminada, handleOpenEditarDireccion } : Props) => {
	return (
		<DirectionCardContainer predeterminada={predeterminada} view={view} onClick={onClick}>

			<Grid justifyContent='center' item flex container direction='column' padding='0 10px 0 0'>
				<Typography padding='0 0 5px 0' variant='body1'>{`${item.cCalle} ${item.cNumeroExterior}`}</Typography>

				<Typography padding='0 0 5px 0' color='darkGrey61' variant='subtitle1regular'>{`${item.cColonia ? 'Col.' : ''} ${item.cColonia} ${item.cCodigo_Postal}`}</Typography>

				<Typography  color='darkGrey61' variant='subtitle1regular'>{`${item.cMunicipio}${item.cMunicipio? ',' : ''} ${item.cEstado}`}</Typography>
                
				<Grid container>
					<Tag margin="5px 0 0 0" disabled>{predeterminada ? 'Predeterminada' : item?.cTipoDomicilio}</Tag>
				</Grid>
			</Grid>
			{
				hadleOpenDeleteDireccion && handleOpenEditarDireccion && <>
					{/* <Grid item margin='0 10px 0 0'>
						<IconButton onClick={() => hadleOpenDireccionPredeterminada(item.nDireccion || 0)}>
							<HeartIcon />
						</IconButton>
					</Grid> */}
					<Grid item margin='0 10px 0 0'>
						<IconButton onClick={() => handleOpenEditarDireccion(item)}>
							<EditIcon />
						</IconButton>
					</Grid>
					<IconButton onClick={() => hadleOpenDeleteDireccion(item.nDireccion || 0)}>
						<DeleteIcon height='18px' width='18px' />
					</IconButton>
				</>
			}
			
			{
				view || hadleOpenDeleteDireccion ? null : <RadioButton onClick={onClick} checked={checked}></RadioButton>
			}
			

		</DirectionCardContainer>
	);
};
export default (DireccionCard);