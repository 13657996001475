import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { InivelTiendaDetallesType } from '~/interfaces';
import { helperSearchEvent } from '~/utils';

const useBuscador = () => {
	const params = useParams();
	const value = params.value;

	const {
		handleShowBack,
		setMainValue,
		mainValue,
		nivelesTienda
	} = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('', undefined, undefined, undefined, '82px');
        
		if(value){
			setMainValue(decodeURIComponent(value));
		} 
	}, []);
	const apiUrl: string = import.meta.env.VITE_API_URL;
	const {
		headersApi,
		handleDoneInitialLoading
	} = useContext(CookiesContext);
	const [loading, setLoading] = useState(false);
	
	const [nivelTiendaDetalles, setNivelTiendaDetalles] = useState<InivelTiendaDetallesType | undefined>(undefined);
	const [errorWarning, setErrorWarning] = useState(false);
	const nNivelTienda = params.nNivelTienda;

	useEffect(() => {
		setLoading(true);

		const body = {
			nNivelTienda: nNivelTienda || 0,
			cBusqueda: decodeURIComponent(value || ''),
		};
		fetch(`${apiUrl}/APPWEB/ObtenerNivelTiendaDetalles`, {
			body: JSON.stringify(body),
			method: 'POST',
			headers: headersApi
		}).then((res) => res.json()).then((responseCat) => {

			
			
			
			if (responseCat?.Result) {
				const nivelTienda = nivelesTienda?.find(({nNivelTienda}) => nNivelTienda == responseCat?.Result?.nNivelTienda)?.cDescripcion || 'General';
				helperSearchEvent(mainValue, nivelTienda);

				setNivelTiendaDetalles(responseCat.Result);
				setLoading(false);
				handleDoneInitialLoading();
			} else {
				setLoading(false);
				setErrorWarning(true);
			}
	
		}).catch((error) => {
			console.error(error, 'error');
			setNivelTiendaDetalles({
				dataSeccionesInteres: [],
				dataProductosCategoria: [],
			});
			setLoading(false);
		});
	
		
	
	}, [headersApi.Authorization, value]);

	return {
		nivelTiendaDetalles,
		errorWarning,
		loading,
		mainValue,
	};
};

export default useBuscador;