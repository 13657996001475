import React, {useContext, useEffect} from 'react';
import { FavoriteIcon, UnfavoriteIcon } from '~/assets/icons';
import { useCheck, useDialog, useFirstVisible } from '~/hooks';
import { ProductosProps } from '~/interfaces';
import { getCdnUrl, helperViewItem, pricePrettify } from '~/utils';
import CardMedia from '../CardMedia';
import Grid from '../Grid';
import IconButton from '../IconButton';
import Paragraph from '../Paragraph';
import Title from '../Title';
import Typography from '../Typography';
import { ProductCardContainer, ProductCardContainerMain, ViewIconContainer, PopularTag, DiscountTag, TagsContainer, YellowTag } from './styled';
import { CookiesContext } from '~/context/cookiesContext';
import WarningSobrePedido from '../WarningSobrePedido';

type Props = {
	item: ProductosProps,
	hide?: boolean,
	handleLike: (value: number, handleDone?: VoidFunction) => void,
	handleRemove?: (value: number) => void,
	handleClickItem: () => void,
	bAnticipacion: boolean
	item_list: string
};
const ProductCard : React.FC<Props> = ({ item, handleClickItem, hide, handleLike, handleRemove, bAnticipacion, item_list } : Props) => {
	const {
		check,
		handleCheck
	} = useCheck(!!(item.bFavorito));

	const {
		handleClose,
		handleOpen,
		open
	} = useDialog();

	const handleFavorite = () => {
		handleLike(item.nVProducto);
		handleCheck(!check);
		if(handleRemove){
			setTimeout(()=>{
				handleRemove(item.nVProducto);
				handleCheck(true);
			}, 500);
		}
	};

	const {
		userInfo,
	} = useContext(CookiesContext);
	
	const isPopular = item.bPopular;
	const disabled = !item.bDisponible;
	const textColor = disabled ? 'disabledFontGrey' : 'black';

	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen && !hide) {
			helperViewItem(item, item_list);
		}
	}, [hasBeenSeen]);

	return (
		<>
			<ProductCardContainerMain ref={containerRef} disabled={disabled} className='noselect' hide={hide ? !check : false}>
				<ProductCardContainer disabled={disabled} onClick={disabled ? undefined : bAnticipacion ? handleOpen : handleClickItem}>
					{
						(!userInfo?.invitado) && <ViewIconContainer checked={check}>
							<IconButton onClick={handleFavorite} size="42px" bgColor="white80">
								<div className='icon'>
									<div className='check'>
										<FavoriteIcon color='primary'></FavoriteIcon>
									</div>
									<div className='uncheck'>
										<UnfavoriteIcon />						
									</div>
								</div>
							</IconButton>
						</ViewIconContainer>
					}

					<Grid container height='176.47px' position='relative'>
						<CardMedia alt={item?.cDescripcion} disabled={disabled} alignItems="flex-start" height="176.47px" src={getCdnUrl(item?.cImagenUrl)} />
						<TagsContainer>
							
							{
								bAnticipacion && !disabled ? <Grid container>
									<YellowTag>Sobre pedido</YellowTag>
								</Grid> : null
							}

							{
								item?.bPrecioEspecial && !disabled ? <Grid container><DiscountTag>Con descuento</DiscountTag></Grid> : null
							}
							
						</TagsContainer>
					</Grid>
				
					<Grid container direction='column' flex height='100%'>
						<Grid padding='8px 16px 5px 16px' item flex direction='column' container>
							<Grid container spacing='6px'>
								
								{
									item?.bNuevo && !disabled ? <PopularTag show={true}>
										<Typography color='white' variant='caption'>
									Nuevo
										</Typography>
									</PopularTag> : null
								}
								{
									<PopularTag disabled={disabled} show={isPopular || disabled}>
										<Typography color='white' variant='caption'>
											{disabled ? 'No disponible' : 'Popular'}
										</Typography>
									</PopularTag>
								}
							</Grid>

							<Title color={textColor} className='hoverItem' variant='h5'>
								<strong>
									{`${item.cDescripcion} `}
								</strong>
							</Title>
						
							<Grid item xs padding='5px 0 0 0'>
								<Paragraph color={textColor} className='paragraphContainer' margin='0px' variant='body1'>
									{item.cDetalle}
								</Paragraph>
							</Grid>

							<Grid item flex container justifyContent='flex-end' direction='column' padding='0 0 16px 0'>
						
								<Grid item textAlign='right'>
									{
										!!(item?.bPrecioEspecial && item.nPrecio_Base_Original && item.nProductoSIP) && <Typography padding='0 16px 0 0' variant='line' color={textColor}>
											{pricePrettify(item.nPrecio_Base_Original)}
										</Typography>
									}
									
									<Typography color={textColor} variant='subtitle1bold'>{item.nPrecio_Base ? (`${pricePrettify(item.nPrecio_Base)} ${item.nProductoSIP || !item.nPrecio_Maximo ? '' : `- ${pricePrettify(item.nPrecio_Maximo)}`}`) : ''}</Typography>
								</Grid>
							</Grid>
						
						
						</Grid>
				
					</Grid>
				</ProductCardContainer>
			</ProductCardContainerMain>

			{
				bAnticipacion && (
					<WarningSobrePedido 
						nDiasAnticipacion={item.nDiasAnticipacion} 
						cDiasDisponibles={item.cDiasDisponibles}
						open={open} 
						handleClose={handleClose} 
						handleClickItem={handleClickItem} 
					/>
				)
			}
		</>

	);
};
export default (ProductCard);