import { useEffect, useContext, useState } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import useValidUserRedirect from './useValidUserRedirect';
import { handleValidDinamicaFolio } from '~/utils/validations';
import { DinamicaType } from '~/interfaces';
import { DinamicaRedencionType } from '../interfaces/index';
import { useOutletContext, useParams } from 'react-router-dom';
import { AlertToastContext } from '~/context/alertToastContext';
import { getCdnUrl } from '../utils/index';
import { DinamicasContextType } from '~/context/dinamicasContext';
import { DINAMICAS_TIPO } from '~/constants';
const apiUrl = import.meta.env.VITE_API_URL;


const useDinamica = () => {
	const { handleShowBack } = useContext(GeneralLayoutContext);
	const { handleDoneInitialLoading, headersApi } = useContext(CookiesContext);
	const { showError, showSuccess } = useContext(AlertToastContext);

	const { dinamicas, setdinamicas, dinamicasFetched, navigate, searchFolio, clearSearchFolio }: DinamicasContextType = useOutletContext();
	
	useEffect(() => {
		if (dinamicas && dinamicasFetched && !dinamica) {
			getDinamicaInfo().then((dinamicaObtenida) => {
				if (!dinamicaObtenida) {
					showError('Dinámica no disponible');
					navigate('/dinamicas');
				} else {
					setdinamica(dinamicaObtenida);

					const redirectTo = dinamicas.length > 1 ? '/dinamicas' : '/';
					const DINAMICA_TIPO = Object.values(DINAMICAS_TIPO).find((dinamicaTipo) => dinamicaObtenida.nTipo === dinamicaTipo.nTipo) || DINAMICAS_TIPO.REGISTRO_WEB;

					handleShowBack(redirectTo, 'Volver a la tienda', DINAMICA_TIPO.titulo, true, true);
				}
			}).catch(() => {
				showError('Inténtelo mas tarde');
				navigate('/dinamicas');
			});
		} else if ((!dinamicas || dinamicas.length === 0) && dinamicasFetched) {
			navigate('/dinamicas');
		}
	}, [dinamicas, dinamicasFetched]);
	
	const [folio, setfolio] = useState<string>(searchFolio);
	const [checked, setchecked] = useState<boolean>(false);
	const [isValidFolio, setisValidFolio] = useState(handleValidDinamicaFolio(searchFolio));
	const [loading, setloading] = useState(false);
	const [dinamica, setdinamica] = useState<DinamicaType>();
	const { nDinamica } = useParams();
	const [isOpenCanjeados, setIsOpenCanjeados] = useState(false);
	
	useEffect(() => {
		handleDoneInitialLoading();

		clearSearchFolio();
	}, []);
	useValidUserRedirect(undefined, true);

	useEffect(() => {
		setisValidFolio(handleValidDinamicaFolio(folio));
	}, [folio]);

	const getDinamicaInfo = async () => {
		const dinamicaActual = dinamicas.find((dinamica) => (dinamica.nDinamica === parseInt(nDinamica ? nDinamica : '')));

		if (dinamicaActual && !dinamicaActual.ticketsRedimidos && dinamicaActual.nTipo === DINAMICAS_TIPO.REGISTRO_WEB.nTipo) {
			const redencionesRespuesta = await fetch(`${apiUrl}/APPWEB/Dinamicas/Canjeados/${nDinamica}`, {
				method: 'GET',
				headers: headersApi,
			}).then(res => res.json());

			const redenciones: DinamicaRedencionType[] = redencionesRespuesta?.Result?.data;
			dinamicaActual.ticketsRedimidos = redenciones;
		}
		return dinamicaActual;
	};

	// Verifica que el folio sea valido a partir de un regex y cambia el valor del contenedor
	const handleFolio = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFolio = e.target.value;
		setfolio(newFolio);
	};

	const handleCheckBox = () => {
		setchecked((current) => !current);
	};

	const handleSubmit = async() => {
		try {
			setloading(true);
			const folioEnviar = folio;
			
			// Manda una peticion para registrar el ticket
			const resultado = await fetch(`${apiUrl}/APPWEB/Dinamicas/${nDinamica}`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify({ cIdTicket: folioEnviar })
			}).then(res => res.json());
			
			if (resultado?.Result?.done && dinamica && resultado?.Result?.redenciones) {
				// Manda retroalimentacion al usuario si la peticion fue exitosa
				showSuccess('¡Ticket registrado con éxito!');
				setloading(false);
				setchecked(false);
				setfolio('');

				const ticketsRedimidos: DinamicaRedencionType[] = resultado.Result.redenciones;
				const nuevaDinamica: DinamicaType = {
					...dinamica,
					ticketsRedimidos
				};
				setdinamica(nuevaDinamica);
				
				// Actualiza el estado de las dinamicas agregando los tickets redimidos para evitar que se hagan fetchs
				setdinamicas((dinamicas) => {
					return dinamicas?.map((dinamicaMap) => {
						if (dinamicaMap.nDinamica === dinamica.nDinamica) {
							return nuevaDinamica;
						}
						return dinamicaMap;
					});
				});
			} else if (resultado?.Result?.error) {
				showError(resultado?.Result?.error || 'No se pudo remimir el ticket');
				setisValidFolio({
					check: false,
					error: true,
					helperText: resultado?.Result?.error || 'No se pudo remimir el ticket'
				});
				setloading(false);
			} else {
				throw new Error();
			}
		} catch (error) {
			showError('Sucedió un error intentelo mas tarde');
			setloading(false);
		}
	};

	const handleGoToBases = () => {
		window.open(getCdnUrl(dinamica?.cUrlBases));
	};

	return {
		folio,
		checked,
		dinamica,
		handleFolio,
		handleCheckBox,
		handleSubmit,
		handleGoToBases,
		loading,
		isOpenCanjeados,
		setIsOpenCanjeados,
		...isValidFolio,
	};
};

export default useDinamica;
