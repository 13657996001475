import React from 'react';
import { Grid, Typography, ChevronRightIconButton, CardMedia } from '~/components';
import { GridStyled } from './styled';
import { HistorialOrdenesCardTypes } from '~/interfaces';
import moment from 'moment';
import { getCdnUrl, getUtcDate, pricePrettify } from '~/utils';
import { IconCircleAlert } from '~/assets/icons';
type Props = {
	item: HistorialOrdenesCardTypes,
	onClick: VoidFunction,
}
const PedidosCard : React.FC<Props> = ({ item, onClick } : Props) => {
	const hoy = moment().format('YYYY-MM-DD');
	const fechaOrden = getUtcDate(item.dFechaEntrega, 'YYYY-MM-DD');
	
	return (
		<GridStyled container direction='column' className='noselect' onClick={onClick} width='100%'>
			<Typography variant='subtitle2' color='grey'>{item.error ? 'Pedido cancelado' :
				moment(fechaOrden, 'YYYY-MM-DD').isBefore(moment(hoy, 'YYYY-MM-DD')) ? 'Pedido completado' : 'Pedido en proceso'} {getUtcDate(item.dFechaEntrega, 'DD/MM/YYYY')}</Typography>
			<Grid margin='12px 0 0 0' container spacing='8px' alignItems='start'>
				<CardMedia style={{border: '1px solid #707070'}} borderRadius='15px' height='40px' maxwidth='40px' src={getCdnUrl(item.cImagenUrl)} alt="Categoria de comida" />
				<Grid item flex container direction='column'>
					<Typography padding='0 0 3px 0' variant='subtitle1bold'>{item.cTipoPedido}</Typography>
					<Typography variant='subtitle1bold'>						{
						item?.nTipoEntrega === 2 ? (`Sucursal ${item?.cSucursal}`) : item?.cColonia ? (`${item?.cCalle || ''} ${item?.cNumeroExterior ? `#${item?.cNumeroExterior}` : ''} ${item?.cColonia ? `Col. ${item?.cColonia}` : ''}.` ): 'Sin información.'
					}
					</Typography>
					<Typography variant='subtitle2' color='black' margin='6px 0 0 0'>Orden</Typography>
						
					{
						item?.carrito?.map((producto, i) => (
							<Typography key={i} variant='subtitle2regular' color='grey112'>
								{producto.nCantidad > 0 ? `${producto.nCantidad}x ` : ''}{producto.cDescripcion}{producto.jExtras.length > 0 ? ` (${producto.jExtras?.map(e => `${(e?.nCantidad || 0) > 1 ? `${e.nCantidad}x ` : ''}${e.cDescripcion}`).join(', ')})` : ''} 
							</Typography>
						))
					}	

					{
						item.error && <Grid padding='3px 0 0 0' alignItems='center' container>
							<IconCircleAlert width='20px' height='20px' color="red" />
							<Grid item flex>
								<Typography padding='0 0 0 5px' variant='caption' color='red'>
									{item.error}
								</Typography>
							</Grid>
						</Grid>
					}
				</Grid>
				<Grid item > <ChevronRightIconButton onClick={onClick} /></Grid>
			</Grid>
			<Grid textAlign='right' margin='12px 0 0 0'>
				<Typography variant='subtitle1bold'>MX {pricePrettify(item.nTotal)}</Typography>
			</Grid>
		</GridStyled>
	);
};
export default (PedidosCard);