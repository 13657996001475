import React from 'react';
import { OutlinedCircleIcon, RadioButtonIcon } from '~/assets/icons';
import IconButton from '../IconButton';
import { CheckboxContainer } from './styled';
type Props = {
	checked?: boolean,
	onClick?: () => void
};
const RadioButton : React.FC<Props> = ({ onClick, checked } : Props) => {
	
	return (
		<CheckboxContainer checked={checked}>

			<IconButton onClick={onClick ? ()=>onClick() : undefined}>
				<div className='icon'>
					<div className='check'>
						{
							checked === undefined ? null : <RadioButtonIcon
								color="black" height='25' width='25'
							></RadioButtonIcon>
						}
						
					</div>
					<div className='uncheck'>
						<OutlinedCircleIcon color="ratGrey" height='25' width='25'></OutlinedCircleIcon>
					</div>
														
				</div>
			</IconButton>
		</CheckboxContainer>
	);
};
export default (RadioButton);