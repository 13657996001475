import styled from 'styled-components';

export const ProfileContainer = styled.div`
    background: ${props => props.theme.colors.white};
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 9px 19px 9px 19px;
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 22px;
    flex: 1;
    min-height: 96px;
    gap: 5px;

`;

export const AvatarContainer = styled.div`
    background-color: ${props => props.theme.colors.yellow};
    display: flex;
    flex: 1;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 22px;
    width: 96px;
    min-height: 96px;
`;

export const ProfileNotVerified = styled.div`
    padding: 2px;
    gap: 2px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.orange_100};

    display : flex;
    align-items: center;
`;