import React, { useEffect } from 'react';
import { ProductosProps } from '~/interfaces';
import MiniProductCard from '../MiniProductCard';
import Grid from '../Grid';
import { ProductCardContainer } from '../MiniProductCard/styled';
import { Container, FloatContainer } from './styled';
import { RightIcon } from '~/assets/icons';
import IconButton from '../IconButton';
import Typography from '~/components/Typography';
import { getIsTouchDevice, handleUrlClick, helperViewItemList } from '~/utils';
import { ScrollContainer } from '../CarrouselCard/styled';
import _ from 'lodash';
import { useFirstVisible } from '~/hooks';
type Props = {
    productos: ProductosProps[],
    handleClick: (value: number) => void,
	goTo: string,
	item_list: string
};
const ListadoVerMasProductos : React.FC<Props> = ({ productos, handleClick, goTo, item_list } : Props) => {
	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen) {
			helperViewItemList(productos, item_list);
		}
	}, [hasBeenSeen]);
	
	const scrollMood = getIsTouchDevice();

	if(scrollMood){
		return <ScrollContainer ref={containerRef}>
			{
				_.take(productos, 5).map((item, i) => <div key={i} className='scrollItem'>
					<MiniProductCard
						handleClickItem={handleClick}
						item={item}
						item_list={item_list}
					></MiniProductCard>
				</div>)
			}
			<Grid container width='173px' className=''>
				<ProductCardContainer onClick={() => handleUrlClick(goTo)}>
					<Grid height='100%' container justifyContent='center' alignItems='center' direction='column'>
						<IconButton size='44px' onClick={() => handleUrlClick(goTo)} >
							<RightIcon color='primary'></RightIcon>
						</IconButton>
						<Typography margin='16px 0 0 0' variant='subtitle1bold'>Ver más</Typography>
					</Grid>
				</ProductCardContainer>
			</Grid>
		</ScrollContainer>;
	} else {
		return (
			<Grid container spacing='17px' margin='0 0 15px 0' ref={containerRef}>
				<Container>
					{
						_.take(productos, 5).map((item, i) => <MiniProductCard
							handleClickItem={handleClick}
							item={item}
							key={i}
							item_list={item_list}
						></MiniProductCard>)
					}
					<ProductCardContainer onClick={() => handleUrlClick(goTo)}>
						<Grid height='100%' container justifyContent='center' alignItems='center' direction='column'>
							<IconButton size='44px' onClick={() => handleUrlClick(goTo)} >
								<RightIcon color='primary'></RightIcon>
							</IconButton>
							<Typography margin='16px 0 0 0' variant='subtitle1bold'>Ver más</Typography>
						</Grid>
					</ProductCardContainer>
					<FloatContainer>
						<ProductCardContainer onClick={() => handleUrlClick(goTo)}>
							<Grid height='100%' container justifyContent='center' alignItems='center' direction='column'>
								<IconButton size='44px' onClick={() => handleUrlClick(goTo)} >
									<RightIcon color='primary'></RightIcon>
								</IconButton>
								<Typography margin='16px 0 0 0' variant='subtitle1bold'>Ver más</Typography>
							</Grid>
						</ProductCardContainer>
					</FloatContainer>
				</Container>
			</Grid>
		);
	}

};
export default (ListadoVerMasProductos);