import React, { ChangeEvent } from 'react';
import { Button, Grid, PanaDivider, RipplesButtons, TextField, TextFieldPhoneNumber, Title, Typography } from '~/components';
import PaswordTextFiled from '~/components/PaswordTextFiled';
import { handleEnter } from '~/utils';
import GoogleIcon from '~/assets/icons/googleIcon.webp';
// import FacebookIcon from '~/assets/icons/facebookIcon.webp';
import { LoginButton } from '../../styled';
import { CountryCode } from '~/components/TextFieldPhoneNumber';

export type CheckProps = {
	error?: boolean,
	check?: boolean,
	helperText?: string,
}

type Props = {
	errorTextField: CheckProps | null,
	handlePassword: (e: ChangeEvent<HTMLInputElement>) => void,
	password: string,
	disabledAuthSubmit: boolean,
	loading: boolean,
	remeberCheck: boolean,
	emailCheck: CheckProps,
	numeroCelularCheck: CheckProps,
	passwordCheck: CheckProps,
	handleAuth: VoidFunction
	handleForgottenPass: VoidFunction
	handleAuthGoogle: VoidFunction
	handleAuthFB: VoidFunction
	showRecuperarContrenia: boolean

	userCredential: string
	handleUserCredential: (value: string) => void
	handleKeyDownNumeroTelefono: React.KeyboardEventHandler<HTMLInputElement>
	handleCountryCode: (newCountryCode: CountryCode) => void
	countryCode: CountryCode
	shouldRenderEmailInput: boolean
};

const AuthForm : React.FC<Props> = ({ 
	errorTextField, 
	handlePassword, 
	password, 
	handleAuth, 
	handleForgottenPass, 
	disabledAuthSubmit,
	loading, 
	remeberCheck, 
	emailCheck, 
	passwordCheck, 
	handleAuthGoogle, 
	showRecuperarContrenia,
	
	userCredential,
	handleUserCredential,
	handleKeyDownNumeroTelefono,
	handleCountryCode,
	shouldRenderEmailInput,
	numeroCelularCheck
}) => {
	const enterProps = {
		onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>)=>handleEnter(e, handleAuth, !disabledAuthSubmit)
	};

	return (
		<>
			<Grid item padding={'0 0 15px 0' } container justifyContent={'center'}>
				<Title variant={'h6'}>¿Cual es tu número de teléfono o correo electrónico?</Title>
			</Grid>
			<form autoComplete={remeberCheck ? undefined :'off'} onSubmit={(e)=>e.preventDefault()}>
				{shouldRenderEmailInput && <TextField 
					autoCompleteOff={!remeberCheck}
					id="correo"
					placeholder="Ingresa tu teléfono o correo electrónico" 
					{...emailCheck}
					{...enterProps} 
					{...errorTextField} 
					onChange={(e) => handleUserCredential(e.target.value)} 
					value={userCredential} 
					fullWidth
					inputId='userCredentialEmail'
					padding="0 0 5px 0" 
				/>}

				{!shouldRenderEmailInput && <TextFieldPhoneNumber 
					fullWidth
					{...numeroCelularCheck} 
					{...enterProps} 
					{...errorTextField} 
					onChange={handleUserCredential} 
					onCountryChange={handleCountryCode}
					value={userCredential} 
					countries={['MX']}
					numberInputProps={{ 
						onKeyDown: handleKeyDownNumeroTelefono,
						autoComplete: 'off',
						type: 'text',
						id: 'userCredentialNumeroTelefono',
					}}
				/>}

				<PaswordTextFiled 
					autoCompleteOff={!remeberCheck} 
					{...{...passwordCheck, helperText: passwordCheck?.helperText === '' ? 'La contraseña debe de tener al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.' : passwordCheck?.helperText}}
					placeholder='Ingresa tu contraseña' 
					{...enterProps} 
					{...errorTextField} 
					onChange={handlePassword} 
					value={password} 
					padding='10px 0 0 0'
				>
				</PaswordTextFiled>
				
				{showRecuperarContrenia && (
					<Button fullWidth margin='16px 0 0 0' color="black" variant="underline-subtitle2" onClick={handleForgottenPass}>Olvidé mi contraseña</Button>
				)}
			</form>

			<Button 
				loading={loading} 
				onClick={handleAuth} 
				disabled={disabledAuthSubmit} 
				variant="contained-round" 
				margin='16px 0 5px 0'
			>
				Continuar
			</Button>
			
			<Grid container justifyContent='center'>
				<Grid margin='1px 0 16px 0' container textAlign='center' justifyContent='center'>
					<Typography color='black1B' variant='caption'>
						Al continuar, aceptas recibir comunicaciones por correo electrónico, llamadas, WhatsApp o SMS de Panamá y sus afiliados al número y correo electrónico proporcionados, incluso a través de sistemas automáticos.
					</Typography>
				</Grid>
			</Grid>

			<PanaDivider margin='0 0 18px 0' />

			<RipplesButtons margin='0 0 8px 0' onClick={()=>handleAuthGoogle()} style={{borderRadius: '22px'}}>
				<LoginButton>
					<img loading="lazy" draggable="false" width='20px' height='20px' alt='Logo Google' src={GoogleIcon} />
					<Typography margin='0 0 0 23px'>
					Continuar con Google
					</Typography>
				</LoginButton>
			</RipplesButtons>

			{/* <RipplesButtons margin='0 0 25px 0' onClick={handleAuthFB} style={{borderRadius: '22px'}}>
				<LoginButton>
					<img draggable="false" width='20px' height='20px' alt='Logo Facebook' src={FacebookIcon} />
					<Typography margin='0 0 0 23px'>
						Continuar con Facebook
					</Typography>

				</LoginButton>
			</RipplesButtons> */}
		</>
	);
};

export default (AuthForm);