import React, { useEffect } from 'react';
import { ProductosProps } from '~/interfaces';
import CarrouselCard from '../CarrouselCard';
import MiniProductCard from '../MiniProductCard';
import { useFirstVisible } from '~/hooks';
import { helperViewItemList } from '~/utils';
type Props = {
    productos: ProductosProps[],
    handleClick: (value: number) => void
	item_list: string
};
const ListadoHorizontalProductos : React.FC<Props> = ({ productos, handleClick, item_list } : Props) => {
	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen) {
			helperViewItemList(productos, item_list);
		}
	}, [hasBeenSeen]);

	return (
		<div ref={containerRef}>
			{
				<CarrouselCard gap={'15px'} margin='0 0 10px 0'>
					{
						productos?.map((item, i) => <MiniProductCard
							handleClickItem={handleClick}
							item={item}
							item_list={item_list}
							key={i}
						></MiniProductCard>)
					}
				</CarrouselCard>
			}
		</div>
	);
};
export default (ListadoHorizontalProductos);