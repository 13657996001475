import { ArrowBackIcon, ArrowFowardIcon } from '~/assets/icons';
import Grid from '~/components/Grid';
import IconButton from '~/components/IconButton';
import Typography from '~/components/Typography';
import { ButtonDay, RowList } from './styled';
import { createCol, createRow, getIsBefore, getWeekDays } from './helpers';
import moment from 'moment';
import 'moment/locale/es';
import { FechasDisponiblesTypes } from '~/interfaces';
import { useMemo } from 'react';

interface props {
	handleCLick: (value: string) => void
	handleBackMonth: () => void
	handleNextMonth: () => void
	currentMonth: string
	selectedYear: string
	nRangoLibreFechasDisponibles: boolean | undefined
	dFechasAPartirDe: string | undefined
	dFechasDisponibles: FechasDisponiblesTypes[] | undefined
	handleBackStepper: () => void
	validateDate?: ((val: string) => boolean) | ((dateValue: string, nRangoLibreFechasDisponibles?: boolean, dFechasAPartirDe?: string, dFechasDisponibles?: FechasDisponiblesTypes[]) => boolean);
	value: string | null
}

export const MonthPicker = ({
	handleBackMonth,
	handleNextMonth,
	selectedYear,
	currentMonth,
	handleCLick,
	nRangoLibreFechasDisponibles, 
	dFechasAPartirDe, 
	dFechasDisponibles, 
	handleBackStepper,
	validateDate = getIsBefore,
	value
}: props) => {
	const WeekDays = getWeekDays();

	const selectedMonth = useMemo(() => {
		const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

		return currentMonth ? meses[parseInt(currentMonth) - 1] : '';
	}, [currentMonth]);

	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid container alignItems="center" onClick={(e) => {
					e.stopPropagation();
					handleBackStepper();
				}} cursor='pointer'>
					<Typography variant="subtitle1bold" transform="capitalize">
						<strong>{`${selectedMonth} ${parseInt(selectedYear)}`}</strong>
					</Typography>
					<ArrowFowardIcon style={{ rotate: '90deg', scale: '0.7' }} />
				</Grid>

				<Grid container>
					<IconButton onClick={() => handleBackMonth()}>
						<ArrowBackIcon />
					</IconButton>

					<IconButton onClick={() => handleNextMonth()}>
						<ArrowFowardIcon />
					</IconButton>
				</Grid>
			</Grid>

			<Grid className="short_animated zoomIn" container direction="column">
				<RowList justifyContent="space-between" spacing='5px' columns={7} margin='15px 0px 5px 0px'>
					{WeekDays.map((day) => (
						<Grid item key={`día${day}`} flex justifyContent="center" container>
							<Typography transform="uppercase" color="grey" variant="subtitle2">
								{day}
							</Typography>
						</Grid>
					))}
				</RowList>

				{createRow(currentMonth, selectedYear).map((item) => (
					<RowList justifyContent="space-between" spacing='5px' columns={7} key={`RowList${item}`}>
						{createCol(item, currentMonth, selectedYear).map((itemCol) => {
							const itemDate = moment(`${selectedYear}-${currentMonth}-${itemCol < 10 ? `0${itemCol}` : itemCol}`).format('YYYY-MM-DD');
							const isBefore = validateDate(itemDate, nRangoLibreFechasDisponibles, dFechasAPartirDe, dFechasDisponibles);
							const isSelected = `${selectedYear}-${currentMonth}-${itemCol.toString().padStart(2,'0')}` === value;

							return (
								itemCol < 0 ? (
									<ButtonDay key={`Button${itemCol}${Math.random()}`} disabled />
								) : (
									<ButtonDay 
										key={`Button${itemCol}${Math.random()}`}
										disabled={isBefore}
										onClick={() => {
											handleCLick(itemDate);
										}}
										isSelected={isSelected}
									>
										{itemCol}
									</ButtonDay>
								)
							);
						})}
					</RowList>
				))}
			</Grid>
		</>
	);
};
