import React from 'react';
import { MenuTexto, ShoppingIcon } from '~/assets/icons';
import { useDialog } from '~/hooks';
import Grid from '../Grid';
import { AppbarContainer, CardMedia, CardMediaContainer, ContadorCarrito } from './styled';
import Logo from '../../assets/images/pana_panama.webp';

import IconButton from '../IconButton';
import { DataCarritoTypes, UserInfoType } from '~/interfaces';
import { NavigateFunction } from 'react-router-dom';
import { PATHS } from '~/constants/paths';
import TextFieldSearchMain from '../TextFieldSearchMain';
import Button from '../Button';
import BackButton from '../BackButton';
import OpcionesEntregaModal from './containers/OpcionesEntrega';
import Title from '../Title';
import { Modal } from '../TextFieldSearchMain/styled';
import Typography from '../Typography';

type Props = {
	userInfo?: UserInfoType | null,
	navigate: NavigateFunction,
	handleOpenSideBar: VoidFunction,
	dataCarrito: undefined | DataCarritoTypes[],
	handleOpenCarrito: () => void,
	handleCloseSideBar: VoidFunction,
	showBack: string | null,
	backButtonContent: string,
	currentCarrito: DataCarritoTypes | undefined,
	handleGoBack: VoidFunction,
	title: string,
	showSideBarIcon: boolean,
	xs: boolean,
	xsCarrito: boolean,
	responsiveHeight: string | null | boolean,
	nivelesTiendaId: number,
	onlyTextfield: boolean,
	setMainValue: React.Dispatch<React.SetStateAction<string>>
};

const TopBar : React.FC<Props> = ({ 
	xs,
	xsCarrito, 
	navigate, 
	handleOpenSideBar, 
	dataCarrito, 
	handleOpenCarrito, 
	handleCloseSideBar, 
	showBack, 
	backButtonContent, 
	handleGoBack, 
	currentCarrito, 
	title, 
	showSideBarIcon, 
	responsiveHeight, 
	nivelesTiendaId, 
	onlyTextfield, 
	setMainValue, 
}) => {
	const {
		open: openSearch,
		handleShow: handleShowSearch,
		handleClose: handleCloseSearch
	} = useDialog();

	const searchComponent = <Grid padding={!onlyTextfield ? '0 12px 0 24px' : '0 12px 0 5px'} zindex={2} item flex >
		<TextFieldSearchMain nivelesTiendaId={nivelesTiendaId} onlyTextfield={onlyTextfield} xs={xs} handleCloseSearch={handleCloseSearch} open={openSearch} handleShow={handleShowSearch}></TextFieldSearchMain>
	</Grid>;

	const cantidadCarrito = currentCarrito?.nTotalCantidad ? currentCarrito.nTotalCantidad : dataCarrito?.length === 1 ? dataCarrito[0].nTotalCantidad : dataCarrito?.length || 0;

	return (
		<nav>
			<Modal onClick={handleCloseSearch} open={openSearch} />
			<AppbarContainer openSearch={openSearch} responsiveHeight={responsiveHeight} showBack={showBack} showSideBarIcon={showSideBarIcon}>
				<Grid flexWrap="nowrap" width='100%' height={xs || showBack ? '100%' : '68px'} container alignItems={xs || showBack ? 'center' : 'flex-end'} className='top'>
					{
						showBack ? (
							<>
								<Grid direction='column' width={xs ? '240px' : '52px'} item container>
									{
										showSideBarIcon && xs && <Grid item padding={xs ? '12px 0 12px 3rem' : '12px 0 12px 10px'}>
											<IconButton onClick={()=> {
												handleOpenSideBar();
												handleCloseSearch();
											}}>
												<MenuTexto />
											</IconButton>
										</Grid>
									}

									<BackButton xs={xs} onClick={() => handleGoBack()} title={backButtonContent}></BackButton>
								</Grid>

								<Grid height='100%' item container flex justifyContent="center" textAlign='center' padding='0 0 2px 0' alignItems={xs || showBack ? 'center' : 'flex-end'} >
									<Title variant={xs ? 'h8' : 'h7'}>{title}</Title>
								</Grid>

								<Grid width={xs ? '240px' : '52px'} item />
							</>
						) : (
							<>
								{
									!onlyTextfield && <Grid item padding={xsCarrito ? '0 0 0 20px' : '0 5px 0 9px'}>
										<IconButton  onClick={()=> {
											handleOpenSideBar();
											handleCloseSearch();
										}}>
											<MenuTexto />
										</IconButton>
									</Grid>
								}

								{
									!onlyTextfield && xsCarrito && (
										<CardMediaContainer 
											onClick={()=>{
												navigate(PATHS.main);
												handleCloseSearch();
												handleCloseSideBar();
											}}
										>
											<CardMedia 
												width={'110px'}
												src={Logo}
												height="50.385"
												alt = "Logo Panamá"
											/>
										</CardMediaContainer>
									)
								}

								<OpcionesEntregaModal 
									nivelesTiendaId={nivelesTiendaId} 
									hide={!(!openSearch || !xs) || onlyTextfield} 
									xs={xs} 
									handleCloseSearch={handleCloseSearch} 
								/>

								{
									xs && searchComponent
								}
								
								{
									xsCarrito ?  (
										<Grid minwidth='139px' spacing='5px' justifyContent='flex-end'container item>
											<Button 
												onClick={()=>{
													handleOpenCarrito();
													handleCloseSearch();
												}} 
												leftIcon={<ShoppingIcon color='primary' />}
											>
												{`Carrito${dataCarrito && dataCarrito?.length > 1 ? 's' : ''} (${cantidadCarrito})`}
											</Button>
										</Grid>
									) : onlyTextfield ? null : (
										<Grid className='noselect' position="relative" item width='33px'>
											<IconButton onClick={()=>{
												handleOpenCarrito();
												handleCloseSearch();
											}}>
												<ShoppingIcon color='primary' />
											</IconButton>

											<ContadorCarrito>
												<Typography className='noselect' variant='mini'>
													{cantidadCarrito}
												</Typography>
											</ContadorCarrito>
										</Grid>
									)
								}
							</>
						)
					}
				</Grid>

				{
					!xs && !showBack && <>
						<Grid container justifyContent='center' item xs padding='12px 0 8px 0'>
							{
								onlyTextfield && <BackButton onlyTextfield={onlyTextfield} xs={xs} onClick={()=>{navigate('/'); setMainValue('');}} title={backButtonContent}></BackButton>
							}

							{
								onlyTextfield ? (
									<Grid container flex item>
										{searchComponent}
									</Grid>
								) : (
									<div className='searchContainer'>
										{searchComponent}
									</div>
								)
							}
						</Grid>
					</> 
				}
			</AppbarContainer>
		</nav>
		
	);
};
export default (TopBar);