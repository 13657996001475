import React, { useEffect } from 'react';
import { ProductosProps } from '~/interfaces';
import Empty from '../Empty';
import ProductCard from '../ProductCard';
import { ListadoContainer } from './styled';
import { useFirstVisible } from '~/hooks';
import { helperViewItemList } from '~/utils';
type Props = {
    productos: ProductosProps[],
    handleClick: (id: number) => void,
	hide?: boolean,
	handleLike: (value: number, handleDone?: VoidFunction) => void,
	bAnticipacion?: boolean,
	handleRemove?: (value: number) => void
	item_list: string
};
const ListadoProductos : React.FC<Props> = ({ 
	productos, 
	handleClick,
	hide,
	handleLike,
	bAnticipacion,
	handleRemove,
	item_list
} : Props) => {
	const { containerRef, hasBeenSeen } = useFirstVisible();

	useEffect(() => {
		if (hasBeenSeen) {
			helperViewItemList(productos, item_list);
		}
	}, [hasBeenSeen]);

	return (
		<>
			<ListadoContainer ref={containerRef}>
				{
					!productos || productos?.length === 0 ? (
						<Empty emptyTitle='Productos no encontrados.'></Empty>
					) : productos.map((item, i) => {
						return <ProductCard
							handleClickItem={()=>handleClick(item.nVProducto)}
							handleLike={handleLike}
							handleRemove={handleRemove}
							hide={hide}
							item={item}
							item_list={item_list}
							bAnticipacion={bAnticipacion || !!(item.bAnticipacion)}
							key={i}
						></ProductCard>;
					})
				}
			</ListadoContainer>
			
		</>
	);
};
export default (ListadoProductos);