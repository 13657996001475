import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { HistorialOrdenesCardTypes } from '~/interfaces';

const useHistorialOrdenes = () => {
	const navigate = useNavigate();
	// obtienes el listado de órdenes

	const {
		handleShowBack,
	} = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Historial de pedidos', true, true);
	}, []);
	
	const {macAddress, token, headersApi, handleDoneInitialLoading} = useContext(CookiesContext);
	const apiUrl = import.meta.env.VITE_API_URL;

	const [loading, setLoading] = useState(false);

	// La data se guarda en ordenesHistorial
	const [ordenesHistorial, setOrdenesHistorial] = useState<undefined | HistorialOrdenesCardTypes[]>(undefined);

	// Te lleva al detalle de la orden
	const handleOrdenHistorial = (ordenId: number) => {
		navigate(`/historial-de-pedidos/${ordenId}`);
	};

	const [nPagina, setNpagina] = useState(1);
	const [nTotal, setNtotal] = useState(0);
	

	const handleFetchData = (next?: boolean) => {
		setLoading(true);
		const newPage = nPagina + (next ? 1 : 0);
		const body = {
			nPagina: newPage,
		};

		if(macAddress){
			fetch(`${apiUrl}/APPWEB/ObtenerHistorial`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
					'macAddress': macAddress,
				}
			}).then((res) => res.json()).then((response) => {
				if (response?.Result) {
					const helper = response.Result.dataHistorial
                    ;
					handleDoneInitialLoading();
					if(next){
						setNpagina(newPage);
						setOrdenesHistorial(prevState => [...(prevState || []), ...helper]);
						return;
					} else {
						setOrdenesHistorial(helper);
						setNtotal(response?.Result?.nTotal || 0);
					}
				}
				
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	};
	// invoca la data
	useEffect(() => {
		handleFetchData();
	}, [headersApi.Authorization]);
    

	return {
		ordenesHistorial,
		loading,
		nTotal,
		handleFetchData,
		handleOrdenHistorial
	};
};

export default useHistorialOrdenes;