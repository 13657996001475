import styled from 'styled-components';

type props = {
	columns?: number
}

const CookiesContainer = styled.div<props>`
	display: grid;
	gap: 37px;
	padding: 1rem;
	grid-template-columns: repeat(${props => props.columns || 3}, 1fr);
	border: 1px solid ${props => props.theme.colors.borderGrey};
	border-radius: 22px;
`;

export default CookiesContainer;