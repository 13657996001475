import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { useTitle } from './hooks';
import { GeneralLayout } from 'components';

import CookiesContext from './context/cookiesContext';
import AlertToastContext from './context/alertToastContext';
import ScrollProvider from './context/scrollContext';
import GeneralLayoutContext from './context/generalLayoutContext';
import CheckoutProvider from './context/checkoutContext';
import Main from './views/main';
import Auth from './views/auth';
import ConfirmaCorreo from './views/confirmacorreo';
import TiendaCategorias from './views/tiendaCategorias';
import Tienda from './views/tienda';
import Checkout from './views/checkout';
import MercadoPagoCheckout from './views/mercadoPagoCheckout';
import MisDirecciones from './views/misDirecciones';
import AjustesDeCuenta from './views/ajustesDeCuenta';
import Sucursales from './views/sucursales';
import Favoritos from './views/favoritos';
import HistorialDePedidos from './views/historialDePedidos';
import DetallePedido from './views/detallePedido';
import Ayuda from './views/ayuda';
import TemasDeAyuda from './views/temasDeAyuda';
import TemasDeAyudaSubtema from './views/temasDeAyudaSubtema';
import CuponesPromociones from './views/cuponesPromociones';
import Buscador from './views/buscador';
import Trabajo from './views/trabajo';
import DinamicasProvider from './context/dinamicasContext';
import Dinamicas from './views/dinamicas';
import Dinamica from './views/dinamica';
import Catalogos from './views/catalogos';
import CentroPrivacidad from './views/privacidad';
import PageNotFound from './components/PageNotFound';

const RouterMain: React.FC = () => {
	const tipoCheckout = import.meta.env.VITE_TIPO_CHECKOUT;

	useTitle();

	return (
		<Router>
			<ScrollProvider>
				<CookiesContext>
					<CheckoutProvider>
						<AlertToastContext>
							<GeneralLayoutContext>
								<GeneralLayout>
									<Routes>
										<Route path="/" element={<Main />} />

										{/* Autenticacion */}
										<Route path="/crear-cuenta" element={<Navigate to="/autenticate" replace={true} />} />
										<Route path="/inicia-sesion" element={<Navigate to="/autenticate" replace={true} />} />
										<Route path="/recuperar-contrasenia" element={<Navigate to="/autenticate" replace={true} />} />
										<Route path="/reestablecer-contrasenia/:token" element={<Navigate to="/autenticate" replace={true} />} />
										<Route path="/autenticate/:redirect?" element={<Auth />} />
										<Route path='/confirmacorreo' element={<ConfirmaCorreo />} />

										{/* tienda */}											
										<Route path="/tienda/:nNivelTienda" element={<TiendaCategorias />} />
										<Route path="/tienda/:nNivelTienda/categoria/:categoriaId" element={<Tienda />} />
										<Route path="/tienda/:nNivelTienda/categoria/:categoriaId/:nVProducto" element={<Tienda />} />

										{/* checkout */}
										<Route path="/checkout" element={<Navigate to="/" />} />
										<Route path="/checkout/:nNivelTienda" element={tipoCheckout === '1' ? <Checkout /> : <MercadoPagoCheckout />} />

										{/* Perfil */}
										<Route path="/mis-direcciones" element={<MisDirecciones />} />

										<Route path="/mi-usuario" element={<Navigate to="/perfil" replace={true} />} />
										<Route path="/perfil" element={<AjustesDeCuenta />} />

										{/* Side bar */}
										<Route path="/sucursales" element={<Sucursales />} />
										<Route path="/sucursales/:tipoMunicipio" element={<Sucursales />} />

										{/* <Route path="/metodos-de-pago" element={<Cartera/>}/> */}

										<Route path="/favoritos" element={<Favoritos />} />

										{/* Pedidos */}
										<Route path="/historial-de-pedidos" element={<HistorialDePedidos />} />
										<Route path="/historial-de-pedidos/:nFolio" element={<DetallePedido />} />
										<Route path="/ver-detalles-pedido/:nFolio" element={<DetallePedido main />} />
										<Route path="/ver-detalles-pedido" element={<Navigate to="/" />} />

										{/* Ayuda */}
										<Route path="/ayuda" element={<Ayuda />} />
										<Route path="/temas-de-ayuda/:nTema" element={<TemasDeAyuda />} />
										<Route path="/temas-de-ayuda/:nTema/:nSubtema" element={<TemasDeAyudaSubtema />} />

										<Route path="/cupones-promociones" element={<CuponesPromociones />} />
										<Route path="/buscador/:value" element={<Buscador />} />
										<Route path="/buscador/:value/:nNivelTienda" element={<Buscador />} />
										<Route path="/buscador" element={<Navigate to="/" />} />
										<Route path="/trabajo" element={<Trabajo />} />

										<Route path="/dinamicas" element={<DinamicasProvider />}>
											<Route index element={<Dinamicas />} />
											<Route path=":nDinamica" element={<Dinamica />} />
										</Route>

										<Route path="/Catalogos/:id" element={<Catalogos />} />

										{/* Centro de Privacidad */}
										<Route path='/Privacidad' element={<CentroPrivacidad />} />

										<Route path="*" element={<PageNotFound />} />
									</Routes>
								</GeneralLayout>
							</GeneralLayoutContext>
						</AlertToastContext>
					</CheckoutProvider>
				</CookiesContext>
			</ScrollProvider>
		</Router>
	);
};

export default RouterMain;