import React, { useContext, useEffect } from 'react';
import { AddCardIcon, CuponIcon } from '~/assets/icons';
import { Container, Cupon, CuponesModal, Grid, IconButton, Loading, Typography } from '~/components';
import { DirectionCardContainer } from '~/components/DireccionCard/styled';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useTitle, useValidUserRedirect } from '~/hooks';
import useCupones from '~/hooks/useCupones';
import { PAGINAS } from '~/interfaces';

const CuponesPromociones : React.FC = () => {
	const { handleShowBack, xsCarrito } = useContext(GeneralLayoutContext);
	useEffect(() => {
		handleShowBack('/', 'Volver a tienda', 'Cupones y promociones', true, true);
	}, []);
	useValidUserRedirect(undefined, true);

	const {
		loadingConsultaCupones,
		handleOpenCuponesModal,
		dataCupones,
		loadingCupon,
		handleCanjearCupon,
		activePositionCupon,
		handleBackCupon,
		handleNextCupon,
		handleCurrentCupon,
		currentCupon,
		openCuponesModal,
		handleCloseCuponesModal,
		cupon,
		handleCupon,
		addCupon,
		handleAddCupon,
		handleCancelAddCupon,
		cuponError,
	} = useCupones(true);

	useTitle(PAGINAS.CUPONES);

	return (
		<Container center padding={'32px 0 40px 0'}>
			<div className='responsiveContainer'>
				<DirectionCardContainer marginBottom="22px" key={0} onClick={handleOpenCuponesModal}>
					<Grid padding='0 10px 0 0'>
						<Typography variant='body1'>Canjear cupón</Typography>
					</Grid>
					<Grid item >
						<IconButton  onClick={handleOpenCuponesModal}><AddCardIcon /></IconButton>
					</Grid>
				</DirectionCardContainer>
				

				{
					(loadingConsultaCupones || !dataCupones) ? <Loading></Loading> : <> 

						{
							dataCupones.length === 0 ? <Grid container direction='column' justifyContent='center' textAlign='center' alignItems='center' padding='40px 0 20px 0'>
									
								<CuponIcon />
								<Typography variant='subtitle3' padding='8px 0 0 0'>
								Actualmente no <br/> cuentas con cupones
								</Typography>
							</Grid> : <>
								{
									dataCupones.map((itemCupon, key) => <Cupon cuponType={itemCupon.bPromocion ? 1 : 2} color="white" itemCupon={itemCupon} key={key} />) 
								}
							</>
						}
					</>
				}
			</div>
			<CuponesModal
				loadingCupon={loadingCupon}
				handleCanjearCupon={handleCanjearCupon}
				activePosition={activePositionCupon}
				handleBack={handleBackCupon}
				handleNext={handleNextCupon}
				handleCurrentCupon={handleCurrentCupon}
				currentCupon={currentCupon}
				xs={xsCarrito}
				open={openCuponesModal} handleClose={handleCloseCuponesModal} 
				cupon={cupon}
				handleCupon={handleCupon}
				addCupon={addCupon}
				handleAddCupon={handleAddCupon}
				main
				handleCancelAddCupon={handleCancelAddCupon}
				loadingConsultaCupones={loadingConsultaCupones}
				dataCupones={dataCupones}
				cuponError={cuponError}
			/>
		</Container>
	);
};
export default (CuponesPromociones);