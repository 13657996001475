import { useEffect, useState } from 'react';
import useElementOnScreen from './useElementOnScreen';

const useFirstVisible = () => {
	const [hasBeenSeen, setHasBeenSeen] = useState(false);

	const { containerRef, isVisible } = useElementOnScreen();

	useEffect(() => {
		!hasBeenSeen && setHasBeenSeen(isVisible);
	}, [isVisible]);

	return { containerRef, hasBeenSeen };
};

export default useFirstVisible;
