import React from 'react';
import Typography from '../Typography';
import { ProfileCardsContainer } from './styled';
type Props = {
	children: JSX.Element | JSX.Element[],
	title?: string,
	titleVariant?: string,
	alignItems?: string,
	onClick: VoidFunction,
};
const ProfileCards : React.FC<Props> = ({ title, children, onClick, alignItems, titleVariant = 'captionBold' } : Props) => {
	return (
		<ProfileCardsContainer onClick={onClick} alignItems={alignItems} className='noselect'>
			{children}

			<Typography variant={titleVariant}>{title}</Typography>
		</ProfileCardsContainer>
	);
};
export default (ProfileCards);