import React from 'react';
import Grid from '../Grid';
import Empty from '../Empty';
import { useTitle } from '~/hooks';
import { PAGINAS } from '~/interfaces';

const PageNotFound : React.FC = () => {
	useTitle(PAGINAS.PAGE_NOT_FOUND);

	return (
		<Grid container direction='column' alignItems='center'>
			<Empty emptyTitle='Página no encontrada.'/>
		</Grid>
	);
};
export default (PageNotFound);