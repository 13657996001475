import React from 'react';
import { CardMedia, Container, Empty, Loading } from '~/components';
import { useDinamicas, useTitle } from '~/hooks';
import { DinamicasContainer } from './styled';
import { PAGINAS } from '~/interfaces';
import { EMPTY_TITLES } from '~/constants/contents';
import { getCdnUrl } from '../../utils/index';

const Dinamicas: React.FC = () => {
	const { 
		dinamicas,
		dinamicasFetched,
		navigate 
	} = useDinamicas();

	useTitle(PAGINAS.DINAMICAS);

	return Array.isArray(dinamicas) && dinamicasFetched && dinamicas.length > 0 
		? (
			<Container padding="4rem 0 0 0">
				<DinamicasContainer>
					{
						dinamicas.map(({ nDinamica, cUrlImagen, cDescripcion }) => (
							<CardMedia 
								key={nDinamica} 
								pointer 
								alt={cDescripcion} 
								onClick={() => navigate(`/dinamicas/${nDinamica}`)} 
								borderRadius='30px' 
								minHeight='200px' 
								height='100%' 
								src={getCdnUrl(cUrlImagen)}
							/>
						))
					}
				</DinamicasContainer>
			</Container> 
		)
		: (!dinamicas || dinamicas.length === 0) && dinamicasFetched 
			? <Empty buttonLabel='Volver a tienda' emptySubtitle={EMPTY_TITLES.subtitle4} /> 
			: <Loading />;
};

export default Dinamicas;
