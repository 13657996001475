import styled from 'styled-components';

export const DinamicasContainer = styled.div`
	flex: 1;
	margin: 0 auto;
	width: 95%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(341px, 1fr));
	@media (max-width: 1152px) {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}
	@media (max-width: 1010px) {
		grid-template-columns: repeat(auto-fill, minmax(341px, 1fr));
	}
	grid-gap: 10px;

	@media (max-width: 764px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
