import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TIPO_LOGIN_FB, TIPO_LOGIN_GOOGLE, TIPO_USUARIO_INCIAL } from '~/constants';
import errors from '~/constants/errors';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { UserInfoType } from '~/interfaces';
import { errorPasswordRegex, handleHelperValidRegex, handleValidEmail, handleValidPhone } from '~/utils/validations';
import { CheckProps } from '~/views/auth/containers/AuthForm';
import useRegistro from './useRegistro';
import { PATHS } from '~/constants/paths';
import { CountryCode } from '~/components/TextFieldPhoneNumber';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import useOTP from './useOTP';
import { useTerminosCondiciones } from './useTerminosCondiciones';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { ESTATUS_USUARIO } from '~/views/auth';
import useRestaurarContrasenia from './useRestaurarContrasenia';

interface props {
	handleNext: () => void
	onFinishOTP: () => void
	onFinishTerminosCondiciones: () => void
	onLogout: () => void
	onLoginTerminosCondiciones: () => void
	onRestablecerContrasenia: (newToken: string) => void
}

const useAuth = ({ 
	handleNext, 
	onFinishOTP, 
	onLogout, 
	onLoginTerminosCondiciones, 
	onFinishTerminosCondiciones,
	onRestablecerContrasenia,
} : props) => {
	const {
		nombres,
		handleNombres,
		apellidoPaterno,
		loading: loadingUserData,
		handleApellidoPaterno,
		apellidoMaterno,
		handleApellidoMaterno,
		fechaDeNacimiento,
		handleFechaDeNacimiento,
		
		numeroCelular,
		handleNumeroCelular,
		errorNumeroCelular,
		validandoCelular,
		modificandoTelefono,
		handleModificarNumero,

		genero,
		handleGenero,
		dataGeneros,
		nombresCheck,
		apellidoPaternoCheck,
		apellidoMaternoCheck,
		fechaDeNacimientoCheck,
		generoCheck,
		numeroCelularCheck,

		email,
		handleEmail,
		emailCheck: emailRegistroCheck,

		handleModificarInfoUsuario,
		disabledRegistro,
		notificacionesCheck,
		handleNotificacionesCheck,
		setNombres,
		setApellidoPaterno,
	} = useRegistro(false, handleNext);

	const {
		handleReenviarOTP,
		handleValidarOTP,
		reenviandoOTP,
		validandoOTP,
		progress,
		setProgress,
	} = useOTP(onFinishOTP);

	const {
		aceptandoTerminos,
		handleAceptarTerminos,
		handleTerminosCheck,
		terminosCheck,
	} = useTerminosCondiciones(onFinishTerminosCondiciones);

	const navigate = useNavigate();

	const handleGoLogin = () => {
		navigate('/autenticate');
	};
	const { handleCloseAlert, statusDialogProps, showError, showSuccess } = useContext(AlertToastContext);
	const { handleUserInfo, macAddress, token, handleLogout, handleDoneInitialLoading, userInfo, headersApi } = useContext(CookiesContext);
	const { handleBackHandler, resetBackHandler } = useContext(GeneralLayoutContext);

	const [loading, setLoading] = useState(false);
	const [showRecuperarContrenia, setShowRecuperarContrenia] = useState(false);
	const [doneUser, setDoneUser] = useState(false);
	const [errorTextField, setErrorTextField] = useState<CheckProps | null>(null);

	// Maneja los datos del formulario de inicio de sesión
	const [userCredential, setUserCredential] = useState('');
	// const [nTipoLogin, setnTipoLogin] = useState(null);
	const [shouldRenderEmailInput, setShouldRenderEmailInput] = useState(true);

	const [countryCode, setCountryCode] = useState<CountryCode>('MX');
	const handleCountryCode = (newCountryCode: CountryCode) => {
		setCountryCode(newCountryCode);
	};

	const [password, setPassword] = useState('');

	const handleUserCredential = (value: string) => {
		if (handleValidEmail(value).check) {
			setShouldRenderEmailInput(true);
			setUserCredential(value);
			setErrorTextField(null);
			return;
		}

		if (shouldRenderEmailInput && /^\d{2,}$/.test(value)) {
			setShouldRenderEmailInput(false);

			setUserCredential(`+${getCountryCallingCode(countryCode)}${value}`);
			setErrorTextField(null);
			return;
		}

		if (!shouldRenderEmailInput) {
			const countryCallingCode = getCountryCallingCode(countryCode);

			if (!value || countryCallingCode.length + 3 > value.length) {
				setUserCredential(value?.slice(countryCallingCode.length + 1) ?? '');
				setShouldRenderEmailInput(true);
			} else {
				setUserCredential(value);
				setShouldRenderEmailInput(false);
			}
			setErrorTextField(null);
			return;
		}

		setShouldRenderEmailInput(true);
		setUserCredential(value);
		setErrorTextField(null);
	};

	const handleKeyDownNumeroTelefono: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
		const { key, ctrlKey, metaKey } = event;
		const allowedKeys = [
			'Enter',
			'Tab',
			'Shift',
			'Control',
			'Alt',
			'AltGraph',
			'Backspace',
			'Pause',
			'CapsLock',
			'Escape',
			'PageUp',
			'PageDown',
			'End',
			'Home',
			'ArrowLeft',
			'ArrowUp',
			'ArrowRight',
			'ArrowDown',
			'PrintScreen',
			'Insert',
			'Delete',
			'Meta',
			'ContextMenu',
			'F1',
			'F2',
			'F3',
			'F4',
			'F5',
			'F6',
			'F7',
			'F8',
			'F9',
			'F10',
			'F11',
			'F12',
		];

		if (allowedKeys.includes(key) || ctrlKey || metaKey) {
			return;
		}

		if (!/^\d$/.test(key)) {
			event.preventDefault();

			setShouldRenderEmailInput(true);
			const countryCallingCode = getCountryCallingCode(countryCode as CountryCode);
			setUserCredential(userCredential?.slice(countryCallingCode.length + 1) + key);
			setErrorTextField(null);
		}
	};

	const {
		loading: loadingRestablecerContrasenia,
		handlenewPassword,
		newPassword,
		handleRestablecerContrasenia,
		disabled,
		errorTextField: errorTextFieldRestablecerContrasenia,
		passwordConfirm,
		handlePasswordConfirm,
		passwordCheck: contraseniaRestablecidaCheck,
		passwordConfirmCheck: contraseniaRestablecidaConfirmadaCheck,
	} = useRestaurarContrasenia(onRestablecerContrasenia);
	
	const [searchParams] = useSearchParams();
	const tokenRecuperarContrasenia = searchParams.get('cToken');
	const [correoRecuperacionEnviado, setCorreoRecuperacionEnviado] = useState(false);

	const handleOlvidarContrasenia = () => {
		if (!macAddress || !token) {
			return;
		}

		setLoading(true);

		const countryCallingCode = getCountryCallingCode(countryCode);

		const body = {
			cCorreo: shouldRenderEmailInput ? userCredential : userCredential?.slice(countryCallingCode.length + 1),
		};

		fetch(`${apiUrl}/APPWEB/ReqCambioContrasenia`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((res) => res.json()).then((res) => {
			setLoading(false);

			if(res?.Result?.done === false){
				showError(res?.Result?.cError ?? 'Algo ha salido mal, por favor verifique sus datos e intente de nuevo');
			} else {
				handleUserInfo(token, macAddress, {
					cCorreo: shouldRenderEmailInput ? userCredential : res?.Result?.cCorreo,
					cNumero_Celular: !shouldRenderEmailInput ? userCredential : '', 
					cToken: res?.Result?.cToken,
					cNumero_Celular_Recuperacion: res?.Result?.bCorreo ? '' : res?.Result?.cNumero_Celular
				});

				setCorreoRecuperacionEnviado(!!res?.Result?.bCorreo);

				handleNext?.();
			}
		}).catch((error) => {
			console.error(error, 'error');
			setLoading(false);
			showError(errors.errorForm);
		});
	};

	const handleClickCambiarNumero = () => {
		if (!macAddress || !token) {
			return;
		}

		handleUserInfo(token, macAddress, {
			bClickCambiarNumero: true,
		});
		
		handleNext();
	};

	const handleClickServicioCliente = () => {
		if (!userInfo?.cToken || !macAddress || !token) {
			return;
		}

		setLoading(true);

		const body = {
			cToken: userInfo?.cToken,
		};

		fetch(`${apiUrl}/APPWEB/SolicitarAyudaCelular`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((res) => res.json()).then((res) => {
			setLoading(false);

			if(res?.Result?.done === false){
				showError(res?.Result?.error ?? errors.errorForm);
			} else {
				handleUserInfo(token, macAddress, {
					bClickSolicitudCreada: true,
				});

				handleNext?.();
			}
		}).catch((error) => {
			console.error(error, 'error');
			setLoading(false);
			showError(errors.errorForm);
		});
	};

	const handleRegresarTienda = () => {
		cleanUserInfo();

		navigate(PATHS.main);
	};

	const handleValidarOTP_TokenContrasenia = (cOTP: string) => {
		setLoading(true);

		if (token && macAddress) {
			fetch(`${apiUrl}/APPWEB/ValidarOTPTokenContrasenia`, {
				headers: headersApi,
				method: 'POST',
				body: JSON.stringify({
					cOTP,
					cToken: userInfo?.cToken,
				}),
			})
				.then((res) => res.json())
				.then(async (response) => {
					if (response.Result?.done) {
						// handleNext();

						handleUserInfo(token, macAddress, { 
							cNumero_Celular_Recuperacion: undefined, 
						});
					} else {
						showError(response?.Result?.error || errors.errorForm);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handleReenviarOTP_TokenContrasenia = () => {
		setLoading(true);

		if (token && macAddress) {
			fetch(`${apiUrl}/APPWEB/ReenviarOTPCambioContrasenia`, {
				headers: headersApi,
				method: 'POST',
				body: JSON.stringify({
					cToken: userInfo?.cToken,
				}),
			})
				.then((res) => res.json())
				.then(async (response) => {
					if (response.Result?.done) {
						showSuccess('¡Se ha enviado un nuevo código de verificación!');
					} else {
						showError(response?.Result?.error || errors.errorForm);
						setProgress(response?.Result?.TiempoRestante);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const cleanUserInfo = () => {
		if (!token || !macAddress) {
			return;
		}

		handleUserInfo(token, macAddress, { 
			bGoogleDetectado: undefined, 
			cNumero_Celular_Recuperacion: undefined, 
			cToken: undefined,
			bLoginTerminosCondiciones: undefined,
			nTiempoRestante: undefined,
			bClickCambiarNumero: undefined,
			bClickSolicitudCreada: undefined,
		});
	};

	useEffect(() => {
		if (shouldRenderEmailInput) {
			document.getElementById('userCredentialEmail')?.focus();
		} else {
			document.getElementById('userCredentialNumeroTelefono')?.focus();
		}
	}, [shouldRenderEmailInput]);

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		setErrorTextField(null);
	};

	useEffect(() => {
		handleDoneInitialLoading();
	}, []);

	useEffect(() => {
		if (userInfo?.nEstatus === TIPO_USUARIO_INCIAL && !userInfo?.invitado) {
			setUserCredential(userInfo.cCorreo || '');
		}
	}, []);

	// Valida si el formulario está listo para ser enviado
	const emailCheck = handleValidEmail(userCredential);
	const numeroCheck = handleValidPhone(userCredential);
	const passwordCheck = handleHelperValidRegex(password, errorPasswordRegex, errors.passwordError);

	const disabledAuthSubmit = userCredential === '' || password === '' || (emailCheck.error && numeroCheck.error) || passwordCheck.error;

	const apiUrl = import.meta.env.VITE_API_URL;

	const { redirect } = useParams();

	const redirectAfterLogIn = () => sessionStorage.getItem('redirectAfterLogIn');
	useEffect(() => {
		if (redirect && redirect in PATHS && PATHS[redirect as keyof typeof PATHS]) {
			sessionStorage.setItem('redirectAfterLogIn', PATHS[redirect as keyof typeof PATHS]);
		}
	}, [redirect]);

	const handleRedirect = () => {
		const redirectPath = redirectAfterLogIn();

		redirectPath ? navigate(redirectPath) : navigate('/');
		sessionStorage.removeItem('redirectAfterLogIn');
	};

	// Función para autenticarse
	const handleAuth = (nTipoInicioSesion: number, cRSToken?: string) => {
		setLoading(true);
		/* 
			cCorreo: '',
			cContrasenia: '',
			nTipoDispositivo: 2,
			nTipoInicioSesion: 2,
		*/
		if (macAddress && token) {
			if (userInfo?.nut === 2) {
				googleLogout();
			}
			if (userInfo?.nut === 3) {
				window.FB.logout();
			}

			const countryCallingCode = getCountryCallingCode(countryCode as CountryCode);

			const body = {
				cCorreo: cRSToken ? '' : isValidPhoneNumber(userCredential) ? userCredential?.slice(countryCallingCode.length + 1) : userCredential,
				cContrasenia: cRSToken ? '' : password,
				nTipoDispositivo: 3,
				nTipoInicioSesion: nTipoInicioSesion,
				cMac_Address: macAddress,
				cRSToken: cRSToken,
			};

			fetch(`${apiUrl}/APPWEB/Login`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: headersApi,
			})
				.then((res) => res.json())
				.then((responseLogin) => {
					const nTipoLogin = responseLogin?.Result?.nTipoLogin;

					setShowRecuperarContrenia(nTipoLogin === 4);

					if (responseLogin.Result?.done && !responseLogin.Result?.error) {
						const estatus = responseLogin.Result.nEstatus;
						setNombres(responseLogin.Result.cNombre);
						setApellidoPaterno(responseLogin.Result.cApellido_Paterno);

						const newUserInfo: UserInfoType = {
							cNombre: responseLogin.Result.cNombre,
							cApellido_Paterno: responseLogin.Result.cApellido_Paterno,
							cApellido_Materno: responseLogin.Result.cApellido_Materno,
							nGenero: responseLogin.Result.nGenero,
							cCorreo: responseLogin.Result.cCorreo,
							cNumero_Celular: responseLogin.Result.cNumero_Celular,
							nEstatus: estatus,
							dFecha_Nacimiento: responseLogin.Result.dFecha_Nacimiento,
							cImg_Perfil: responseLogin.Result.cImg_Perfil,
							bLoginTerminosCondiciones: estatus === ESTATUS_USUARIO.NecesitaAceptarTerminos && nTipoInicioSesion === 1,
							invitado: nTipoInicioSesion === 2,
						};

						handleUserInfo(responseLogin.Result.token, macAddress, newUserInfo);

						if (nTipoInicioSesion === 1 && estatus !== ESTATUS_USUARIO.FinalizarRegistro) {
							showSuccess(`¡Bienvenid${responseLogin.Result.nGenero === 1 ? 'a' : 'o'} ${responseLogin.Result.cNombre} ${responseLogin.Result.cApellido_Paterno}!`);
						}

						setLoading(false);
						setDoneUser(true);

						switch (estatus) {
						case ESTATUS_USUARIO.Activo:
							handleRedirect();
							break;

						case ESTATUS_USUARIO.NecesitaAceptarTerminos:
							onLoginTerminosCondiciones();
							break;

						default:
							handleNext();
							break;
						}
					} else if (nTipoLogin === 2 || nTipoLogin === 3) {
						handleRegistro();
					} else if (nTipoLogin === 1) {
						setLoading(false);
						handleUserInfo(token, macAddress, { bGoogleDetectado: true });
						handleNext();
					} else {
						setErrorTextField({
							helperText: '',
							check: false,
							error: true,
						});
						showError(responseLogin?.Result?.error ? `${responseLogin?.Result?.error} ${responseLogin?.Result?.error_sub ? `- ${responseLogin?.Result?.error_sub}` || '' : ''}` : errors.errorForm);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.error(error, 'error');
					setLoading(false);
					showError(errors.errorForm);
				});
		}
	};

	const handleCerrarSesion = () => {
		setLoading(true);
		setDoneUser(false);

		if (macAddress) {
			cerrarSesion()
				.then(() => {
					setLoading(false);
					setDoneUser(true);
					handleLogout();
					showSuccess('¡Hasta pronto!');
				})
				.catch((error) => {
					console.error(error, 'error');
					setLoading(false);
					showError(errors.errorForm);
				});
		}
	};

	const cerrarSesion = async () => {
		if (macAddress) {
			const response = await fetch(`${apiUrl}/APPWEB/Logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					macAddress: macAddress,
				},
			});

			return response.json();
		}
	};

	const handleGoToRecuperarContrasenia = () => {
		navigate('/recuperar-contrasenia');
	};

	//Recordar cuenta

	const [remeberCheck, setRemeberCheck] = useState(!!localStorage.getItem('remeberCheck'));

	const handleRememberCheck = () => {
		const rememberCheckValue = localStorage.getItem('remeberCheck');
		setRemeberCheck(!rememberCheckValue);
		if (rememberCheckValue) {
			localStorage.removeItem('remeberCheck');
		} else {
			localStorage.setItem('remeberCheck', 'true');
		}
	};

	// Función para registrar usuarios
	const handleRegistro = (passwordForm?: string) => {
		setLoading(true);

		if (macAddress) {
			const countryCallingCode = getCountryCallingCode(countryCode as CountryCode);

			const body = {
				cCorreo: isValidPhoneNumber(userCredential) ? userCredential?.slice(countryCallingCode.length + 1) : userCredential,
				cNombre: '',
				cApellido_Paterno: '',
				cContrasenia: password || passwordForm,
				cMac_Address: macAddress,
				nTipoDispositivo: 3,
			};

			fetch(`${apiUrl}/APPWEB/RegistroUsuario`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((res) => res.json())
				.then((responseRegistro) => {
					if (responseRegistro.Result?.done && !responseRegistro.Result?.error) {
						// Actualiza datos de usuario
						const newUserInfo = {
							cNombre: responseRegistro.Result.cNombre,
							cApellido_Paterno: responseRegistro.Result.cApellido_Paterno,
							cApellido_Materno: responseRegistro.Result.cApellido_Materno,
							nGenero: responseRegistro.Result.nGenero,
							cCorreo: responseRegistro.Result.cCorreo,
							cNumero_Celular: responseRegistro.Result.cNumero_Celular,
							nEstatus: responseRegistro.Result.nEstatus,
							dFecha_Nacimiento: responseRegistro.Result.dFecha_Nacimiento,
							cImg_Perfil: responseRegistro.Result.cImg_Perfil,
							invitado: false,
						};
						
						handleUserInfo(responseRegistro.Result.token, macAddress, newUserInfo);

						if (handleNext) {
							handleNext();
						}

						setLoading(false);
					} else {
						showError(responseRegistro?.Result?.error || responseRegistro?.error);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.error(error, 'error');
					showError(errors.errorForm);
					setLoading(false);
				});
		}
	};

	const handleResetLogin = () => {
		setUserCredential('');
		setPassword('');
		setErrorTextField(null);
	};

	const handleAuthGoogle = useGoogleLogin({
		onSuccess: async (respose) => {
			try {
				setLoading(true);
				handleAuth(TIPO_LOGIN_GOOGLE, respose?.access_token);
			} catch (err) {
				showError(errors.errorForm);
				setLoading(false);
			}
		},
	});

	const handleAuthFB = () => {
		if (window?.FB) {
			setLoading(true);

			window.FB.getLoginStatus((res) => {
				if (res.status === 'connected') {
					if (res?.authResponse?.accessToken) {
						handleAuth(TIPO_LOGIN_FB, res?.authResponse?.accessToken);
					} else {
						showError(errors.errorForm);
						setLoading(false);
					}
				} else {
					window.FB.login(
						(e) => {
							if (e?.authResponse?.accessToken) {
								handleAuth(TIPO_LOGIN_FB, e?.authResponse?.accessToken);
							} else {
								showError(errors.errorForm);
								setLoading(false);
							}
						},
						{ scope: 'public_profile,email' }
					);
				}
			});
		}
	};

	useEffect(() => {
		if (userInfo?.nEstatus !== 2) {
			handleBackHandler(() => async () => {
				handleLogout(() => {
					onLogout();
	
					showSuccess('¡Hasta pronto!');
				});
			});
		} else if ((userInfo.bGoogleDetectado || userInfo.cNumero_Celular_Recuperacion || userInfo.cToken || userInfo.nTiempoRestante || userInfo.bLoginTerminosCondiciones || userInfo.bClickCambiarNumero || userInfo.bClickSolicitudCreada)) {
			handleBackHandler(() => async () => {
				cleanUserInfo();

				onLogout();

				showSuccess('¡Hasta pronto!');
			});
		}

		return () => {
			resetBackHandler();
		};
	}, [userInfo]);

	return {
		handleCerrarSesion,
		handleResetLogin,
		password,
		handlePassword,
		disabledAuthSubmit,
		loading,
		errorTextField,
		statusDialogProps,
		handleCloseAlert,
		handleGoLogin,
		handleRedirect,
		setLoading,
		doneUser,
		setDoneUser,
		handleGoToRecuperarContrasenia,
		remeberCheck,
		handleRegistro,
		handleRememberCheck,
		emailCheck,
		passwordCheck,
		redirectAfterLogIn,

		// auth
		handleAuthGoogle,
		handleAuthFB,
		handleAuth,

		// registro
		nombres,
		handleNombres,
		apellidoPaterno,
		loadingUserData,
		handleApellidoPaterno,
		apellidoMaterno,
		handleApellidoMaterno,
		fechaDeNacimiento,
		handleFechaDeNacimiento,

		numeroCelular,
		handleNumeroCelular,
		errorNumeroCelular,
		validandoCelular,
		modificandoTelefono,
		handleModificarNumero,

		genero,
		handleGenero,
		dataGeneros,
		nombresCheck,
		apellidoPaternoCheck,
		apellidoMaternoCheck,
		fechaDeNacimientoCheck,
		generoCheck,
		numeroCelularCheck,
		handleModificarInfoUsuario,
		disabledRegistro,
		notificacionesCheck,
		handleNotificacionesCheck,
		handleTerminosCheck,
		terminosCheck,
		aceptandoTerminos,
		handleAceptarTerminos,

		handleCountryCode,
		countryCode,

		handleKeyDownNumeroTelefono,
		handleUserCredential,
		userCredential,
		showRecuperarContrenia,
		shouldRenderEmailInput,
		numeroCheck,

		email,
		handleEmail,
		emailRegistroCheck,

		correoRecuperacionEnviado,
		handleOlvidarContrasenia,
		tokenRecuperarContrasenia,

		contraseniaRestablecidaConfirmadaCheck,
		contraseniaRestablecidaCheck,
		handlePasswordConfirm,
		passwordConfirm,
		errorTextFieldRestablecerContrasenia,
		disabled,
		handleRestablecerContrasenia,
		newPassword,
		handlenewPassword,
		loadingRestablecerContrasenia,
		handleValidarOTP_TokenContrasenia,
		handleClickCambiarNumero,
		handleClickServicioCliente,
		handleRegresarTienda,
		handleReenviarOTP_TokenContrasenia,

		handleReenviarOTP,
		handleValidarOTP,
		reenviandoOTP,
		validandoOTP,
		progress,
	};
};

export default useAuth;
