import {
	useEffect,
	useReducer,
	useState,
} from 'react';
import {getIsTouchDevice} from 'utils';
import { useSwipeable } from 'react-swipeable';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

const useHooks = (steps: Array<any>) => {
	// Maneja el estado del carrousel de imánenes
	const isTouchDevice = getIsTouchDevice();
	const isTouchDeviceString = isTouchDevice.toString();
	const [activeStep, setActiveStep] = useState(0);

	const numItems: number = steps.length;
	const initialState = {
		pos: 0,
		sliding: false,
		dir: '',
		ini: true
	};

	function reducer(stateReducer: {
    sliding: boolean,
    dir: string,
    pos: number,
    
  }, { type }: {type: string}) {
		switch (type) {
		case 'reset':
			return initialState;
		case PREV:
			return {
				...stateReducer,
				dir: PREV,
				sliding: true,
				pos: stateReducer.pos === 0 ? numItems - 1 : stateReducer.pos - 1
			};
		case NEXT:
			return {
				...stateReducer,
				dir: NEXT,
				sliding: true,
				pos: stateReducer.pos === numItems - 1 ? 0 : stateReducer.pos + 1
			};
		case 'stopSliding':
			return {
				...stateReducer, sliding: false
			};
		case 'pos':
			return {
				...stateReducer, 
				sliding: true,
				dir: stateReducer.pos < activeStep ? NEXT : PREV,
				pos: activeStep

			};
		default:
			return stateReducer;
		}
	}


	const maxSteps = steps.length;
	const finalStep = activeStep === maxSteps - 1;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		slide(NEXT);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		slide(PREV);
	};

	const stopSliding = () => {
		setTimeout(() => {
			dispatch({
				type: 'stopSliding'
			});
		}, 50);
	};

	const handlePosition = (step: number) => {
		setActiveStep(step);
		dispatch({
			type: 'pos',
		});
		stopSliding();
	};


	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const slide = (dir: string) => {
		dispatch({
			type: dir,
		});
		stopSliding();
	};
	const handlers = useSwipeable({
		onSwipedLeft: () => slide(NEXT),
		onSwipedRight: () => slide(PREV),
		// preventDefaultTouchmoveEvent: true,
		trackMouse: true
	});


	useEffect(() => {
		const interval = setInterval(() => {
			slide(NEXT);
		}, 100000);
		return () => clearInterval(interval);
	}, []);



	return {
		activeStep,
		setActiveStep,
		maxSteps,
		handleNext,
		handleBack,
		handleStepChange,
		state,
		handlers,
		numItems,
		slide,
		finalStep,
		isTouchDeviceString,
		isTouchDevice,
		handlePosition,
	};
};
export default (useHooks);