import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TIPO_ACCION_CARRITO_EDITAR, TIPO_PLATAFORMA_WEB } from '~/constants';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { ConfiguracionProducto, DataCarritoTypes, DetalleEnvioType, ExtrasTypes } from '~/interfaces';
import { getBodyModificaCarrito, getUtcDate, handleGetnPedido, helperCartEvents } from '~/utils';
import _ from 'lodash';
import { CheckoutContext } from '~/context/checkoutContext';
import errors from '~/constants/errors';

const useCarrito = (isCheckout?: boolean, isMain?:boolean, nCupon?: number, isCarrito?: boolean) => {
	const params = useParams();

	const {showBlackAlert, showError} = useContext(AlertToastContext);
	const location = useLocation();
	const path = location.pathname;
	const isCheckout2 = path.includes('/checkout/');

	const {
		nivelCheckoutId,
	} = useContext(CheckoutContext);

	const [carritoLoading, setCarritoLoading] = useState(false);
	
	const {
		headersApi, 
		setDataCarrito,
		dataCarrito,
		isUserActive,
		token,
		menuId,
		handleDoneInitialLoading,
		detalleEnvio,
		userInfo,
	} = useContext(CookiesContext);
	const apiUrl = import.meta.env.VITE_API_URL;

	/* 
	Hay dos maneras de obtener el TipoPedidoId
	por menuId que lo sacas desde el CookiesContext
	o directamente de los parámetros del router tipoPedidoId
	con el navigator.
	*/

	// manejar el nivel para cuando no hay un nivel en el main.
	const [nivelMainId, setNivelMainId] = useState<null | number>(null);
	const handleNivelMainId = (value: null | number) => {
		setNivelMainId(value);
	};

	// Tipo pedido (O nivel de tienda)
	const nNivelTienda = (isCheckout || isCheckout2) && nivelCheckoutId ? (nivelCheckoutId) : (params.nNivelTienda ? parseInt(params.nNivelTienda) : menuId);

	const [loadingCheckoutCarrito, setLoadingCheckoutCarrito] = useState(true);
	// Obtienes los items del carrito
	const handleDataCarrito = (nCupon?: number) => {
		if (!isUserActive) {
			setDataCarrito(undefined);
			return;
		}
		
		const body = {
			nNivelTienda: (isCheckout || isCheckout2) ? nNivelTienda : 0,
			nCupon: nCupon,
			nTipoEntrega: detalleEnvio?.nTipoEntrega,
			nColonia: detalleEnvio?.nColonia,
			nRegion: detalleEnvio?.nMunicipio,
			dFechaEntrega: detalleEnvio?.dFechaAgendada || null,
		};

		fetch(`${apiUrl}/APPWEB/ObtenerCarrito`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {

			if (response?.Result?.dataCarrito) {
				const helper = response.Result.dataCarrito;
				setDataCarrito(helper);
				setLoadingVaciarCarrito(false);
				
				if(loadingCheckoutCarrito){
					setLoadingCheckoutCarrito(detalleEnvio?.nivelesTiendaId !== nNivelTienda);
				}

				if(isCheckout) {
					handleDoneInitialLoading();
				}
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	//Modifica la cantidad del carrito, elimina un item o añades producto
	// 1- SUMA 2- ACTUALIZA 3-Modifica producto
	const handleModificaCarrito = (
		nTipoAccion: number,
		nVProducto: number, 
		nProductoSIP: number | null, 
		cantidad: number, 
		dataProducto?: ConfiguracionProducto, 
		nPedido?: number, 
		carrito?: ExtrasTypes[], 
		nValor?: number,
		handleDone?: VoidFunction, 
		hideAlert?: boolean,
		cComentarios?: string, 
		nombreDelFestejado?: string, 
		edad?: number | string, 
		nombreCumpleaniero?: string, 
		panaBoxDestinatario ?: string, 
		panaBoxRemitente ?: string
	) => {
		setCarritoLoading(true);
		const bodyCarrito = getBodyModificaCarrito(dataProducto, carrito);

		// nCategoriaComplemento
		// cImagenUrlCategoriaComplemento

		const nPedidoHelper = nPedido || handleGetnPedido(nNivelTienda, nVProducto, nProductoSIP, dataCarrito, bodyCarrito);
		const jComplementos: {nVProducto: number
			nProductoSIP: number
			nCantidad?: number}[] = [];

		_.filter((dataProducto?.dataComplementos || []), o=>(o.nCantidad || 0) > 0).forEach((element) => {
			jComplementos.push(
				{
					nVProducto: element.nVProducto,
					nProductoSIP: element.nProductoSIP,
					nCantidad: element.nCantidad
				}
			);
		});
		const nivelTienda = nivelMainId || nValor || nNivelTienda || 0;
		const body = {
			'nNivelTienda': nivelTienda,
			'nTipoAccion': nTipoAccion,
			'jExtras': bodyCarrito,
			'nPedido': nPedidoHelper || 0,
			'nVProducto': nVProducto,
			'nProductoSIP': nProductoSIP,
			'nCantidad': cantidad,
			'nCupon': nCupon,
			'cComentarios': cComentarios,
			'cFelicidades': nombreDelFestejado,
			'nEdadCumpleanios': edad,
			'cNombreCumpleanios': nombreCumpleaniero,
			'jComplementos': jComplementos,
			'cDePanaBox': panaBoxRemitente,
			'cParaPanaBox': panaBoxDestinatario
		};


		fetch(`${apiUrl}/APPWEB/ModificaCarrito`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {

			if (response?.Result?.dataCarrito) {
				const helper: DataCarritoTypes[] = response.Result.dataCarrito;
				const carritoItems = isCheckout2 ? _.filter(helper, o=>o.nValor === nivelTienda) : helper;
				
				setDataCarrito(carritoItems);

				// GTM Events
				if (nTipoAccion === 1) {
					const nivelTiendaNombre = carritoItems?.[0]?.cValor;
					const itemAdded = carritoItems?.[0]?.carrito.filter((item) => item.nVProducto === nVProducto);

					helperCartEvents(itemAdded, nivelTiendaNombre ? `Carrito de ${nivelTiendaNombre}` : 'Indefinido', 'add_to_cart');
				} else if (nTipoAccion === 2 || nTipoAccion === 3) {
					const nivelTiendaNombre = carritoItems?.[0]?.cValor;
					const itemBefore = dataCarrito?.[0]?.carrito.filter((item) => item.nVProducto === nVProducto);
					const itemModified = carritoItems?.[0]?.carrito.filter((item) => item.nVProducto === nVProducto);

					const addedToCart = ((itemBefore?.[0]?.nCantidad || 0) - itemModified?.[0]?.nCantidad) > 0;
					
					helperCartEvents(itemModified, nivelTiendaNombre ? `Carrito de ${nivelTiendaNombre}` : 'Indefinido', addedToCart ? 'add_to_cart' : 'remove_from_cart');
				}

				if(handleDone){
					handleDone();
					if(!hideAlert){
						showBlackAlert(nTipoAccion === TIPO_ACCION_CARRITO_EDITAR ? 'Producto modificado' : 'Producto añadido al carrito');
					}
					setCarritoLoading(false);
				}
			} else {
				setCarritoLoading(false);
				showError(errors.errorForm);
			}
		}).catch((error) => {
			console.error(error, 'error');
			showError(errors.errorForm);
			setCarritoLoading(false);

		});
	};


	// Si cambia tipo pedido, con este UseEffect te
	// refresca el carrito
	const [checkoutLoaded, setCheckoutLoaded] = useState(false);
	useEffect(() => {
		if(!isCarrito){
			if(isCheckout2 && !checkoutLoaded){
				setDataCarrito(undefined);
				setCheckoutLoaded(true);
			}
			if((dataCarrito?.length === 0 && isMain) || !isMain){
				handleDataCarrito(nCupon);
			}
		}
	}, [nNivelTienda, token, isUserActive, (isCheckout ? detalleEnvio : null)]);
    
	//Manipula el modal del carrito
	const [openCarrito, setOpenCarrito] = useState(false);

	const handleCloseCarrito = () => {
		setOpenCarrito(false);
	};

	const handleOpenCarrito = () => {
		setOpenCarrito(true);
	};
	const navigate = useNavigate();

	// Te lleva al checkout
	const handleCheckout = (nNivelTienda: number) => {
		userInfo?.invitado && sessionStorage.setItem('redirectAfterLogIn', `/checkout/${nNivelTienda}`);
		navigate(`/checkout/${nNivelTienda}`);
		handleCloseCarrito();
	};

	// Función para ordenar
	const handleOrdenar = (carrito: DataCarritoTypes, detalleEnvio: DetalleEnvioType, nombres: string, numeroCelular: string, nCupon?: number, handleDone?: VoidFunction ) => {
		const body = {
			nNivelTienda: nNivelTienda,
			nTipoPlataforma: TIPO_PLATAFORMA_WEB,
			nRegion: detalleEnvio.nMunicipio,
			nSucursal: detalleEnvio.nSucursal,
			dFechaEntrega: detalleEnvio.dFechaAgendada ? (`${getUtcDate(detalleEnvio.dFechaAgendada, 'YYYY-MM-DD')} ${detalleEnvio.cHoraAgendada}:00`) : (moment().format('YYYY-MM-DD hh:mm:ss')),
			nTipoEntrega: detalleEnvio.nTipoEntrega,
			nEstatus: 1,
			nTipoTarifaDomicilio: 1,
			nCostoDomicilio: carrito.nPrecioEnvio,
			nCupon: nCupon, 
			nTotal: carrito.nTotal,
			nColonia: detalleEnvio?.nColonia,
			cCalle: detalleEnvio?.cCalle,
			cNumeroExterior: detalleEnvio?.cNumeroExterior,
			cNumeroInterior: detalleEnvio?.cNumeroInterior,
			cEntreCalle: detalleEnvio?.cEntreCalle,
			nTipoDomicilio: detalleEnvio?.nTipoDomicilio,
			cReferencias: detalleEnvio?.cReferencias,
			nNumeroCelular: numeroCelular,
			cNombreRecibe: nombres,
		};
        

		fetch(`${apiUrl}/APPWEB/RegistroOrdenes`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {

			if (response?.Result) {
				if(handleDone){
					handleDone();
				}
				handleDataCarrito();

				// navigate(`/tienda/${nNivelTienda}`);
			}
		}).catch((error) => {
			console.error(error, 'error');
		}); 
	};

	// Te lleva al menu principal
	const handleGoToMain = () => {
		navigate('/');
	};

	// Vaciar carrito
	const [loadingVaciarCarrito, setLoadingVaciarCarrito] = useState(false);
	const handleVaciarCarrito = (nNivel?: number) => {
		setLoadingVaciarCarrito(true);
		const body = {
			nNivelTienda: nNivel,
		};
	
		fetch(`${apiUrl}/APPWEB/EliminaCarrito`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
	
			if (response?.Result) {
				handleDataCarrito();
			}
		}).catch((error) => {
			console.error(error, 'error');
		}); 
	};
	

	return {
		loadingVaciarCarrito,
		handleVaciarCarrito,
		openCarrito,
		setOpenCarrito,
		handleDataCarrito,
		handleCloseCarrito,
		handleOpenCarrito,
		handleModificaCarrito,
		dataCarrito,
		apiUrl,
		handleCheckout,
		handleOrdenar,
		carritoLoading,
		handleGoToMain,
		nivelMainId,
		handleNivelMainId,
		detalleEnvio,
		loadingCheckoutCarrito,

	};
};

export default useCarrito;