// responsive

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CategoriasAppBar, Container, DetalleProductoModal, Empty, ListadoProductos, Loading, Title } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useDetalleProductoModal, useTienda, useTitle } from '~/hooks';

const Tienda : React.FC = () => {
	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleLike,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
	} = useDetalleProductoModal();

	const {
		activeTab,
		handleActiveTab,
		nNivelTienda,
		loading,
		nivelTiendaDetalles,
		bAnticipacion,
	} = useTienda({
		handleClick
	});


	const {
		xs,
		handleShowBack,
	} = useContext(GeneralLayoutContext);

	useEffect(() => {
		handleShowBack('', undefined, undefined, undefined, true);
	}, []);

	useTitle(nivelTiendaDetalles?.dataProductosCategoria?.[0]?.cCategoria || 'Sin resultados');

	const [categoriaSelected, setCategoriaSelected] = useState('');

	if(!nivelTiendaDetalles || !nNivelTienda){
		return <Loading></Loading>;
	}

	return (
		<>
			<CategoriasAppBar xs={xs} handleActiveTab={handleActiveTab} activeTab={activeTab} nNivelTienda={nNivelTienda} categorias={nivelTiendaDetalles?.dataSubCategorias?.subCategorias || []} />

			<Container margin="70px 0 0 0">
				{
					loading ? <Loading></Loading> : <>
						{
							nivelTiendaDetalles?.dataProductosCategoria?.length > 0 ? <>
								{
									nivelTiendaDetalles.dataProductosCategoria.map((seccionInteres, i) => (
										<Fragment key={i}>
											<Title padding={xs ? '20px 0 15px 0' : '17px 0 15px 0'} variant={xs ? 'h8' : 'h5'}>
												{seccionInteres.cCategoria}
											</Title>
											<ListadoProductos
												bAnticipacion={bAnticipacion}
												handleLike={handleLike} 
												key={i}
												handleClick={(id: number, nivelId?: number) => {
													setCategoriaSelected(seccionInteres.cCategoria);
													return handleClick(id, nivelId);
												}}
												productos={seccionInteres.VProductos} 
												item_list={seccionInteres.cCategoria}
											></ListadoProductos>
										</Fragment>
									))
								}
                        
							</>: <Empty></Empty>
						}
						<DetalleProductoModal 
							openDetalleProducto={openDetalleProducto}
							productoId={productoId}
							handleOpenDetalleProductoModal={handleOpenDetalleProductoModal}
							handleProductoId={handleProductoId}
							handleClick={handleClick}
							handleDataCarrito={handleDataCarrito}
							handleModificaCarrito={handleModificaCarrito}
							carritoLoading={carritoLoading}
							nivelMainId={nivelMainId}
							handleNivelMainId={handleNivelMainId}
							handleOrdenar={handleOrdenar}
							detalleEnvio={detalleEnvio}
							loadingCheckoutCarrito={loadingCheckoutCarrito}
							itemList={categoriaSelected}
						/>  
					</>
				}
			</Container>
		</>
	);
};
export default (Tienda);